import { IConditionalNode } from '../../../../helpers/conditional-nodes.helpers';
import { required } from '../../../../helpers/function_helpers';
import {
  HeaterProducts,
  HeatSourceOptions,
  HeatingType,
  HeatingPropertyName,
  EnergyProductsIds,
  energyProductsIdToNameMap,
} from '../heating.model';

/**
 * Tree to select heat producer product depending on heating type
 */
export const conditionalHeaterProductId: IConditionalNode<
  HeaterProducts,
  HeatSourceOptions | HeatingType | HeatingPropertyName
>[] = [
  {
    property: HeatingPropertyName.Type,
    map: {
      // Heating product for heat pumps depending on heat source
      [HeatingType.HeatPump]: [
        {
          property: HeatingPropertyName.HeatSource,
          map: {
            [HeatSourceOptions.Air]: HeaterProducts.HeatPumpAirToWater,
            [HeatSourceOptions.Water]: HeaterProducts.HeatPumpWater,
            [HeatSourceOptions.ExhaustAir]: HeaterProducts.HeatPumpAirToWater,
            [HeatSourceOptions.Geothermal]: HeaterProducts.HeatPumpGeothermal,
          },
        },
      ],

      // Heating product for boiles depending on fuel type
      [HeatingType.Boiler]: {
        property: HeatingPropertyName.FuelType,
        map: {
          [required(energyProductsIdToNameMap[EnergyProductsIds.FuelOil1])]:
            HeaterProducts.FuelBoiler,
          [required(energyProductsIdToNameMap[EnergyProductsIds.FuelOil2])]:
            HeaterProducts.FuelBoiler,
          [required(energyProductsIdToNameMap[EnergyProductsIds.WoodPellets])]:
            HeaterProducts.PelletsBoiler,
          [required(energyProductsIdToNameMap[EnergyProductsIds.Biogas])]:
            HeaterProducts.BiogasBoiler,
        },
      },
      [HeatingType.DistrictHeating]: HeaterProducts.DistrictHeating,
      [HeatingType.DirectElectricHeating]: HeaterProducts.DirectElectricHeating,
    },
  },
];
