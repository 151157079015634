import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, OutlinedTextFieldProps } from '@mui/material';
import { useSelectedVersion } from '../../../../store/ui/ui.hook';
import { Row } from '../../Row';
import { RowCell } from '../../RowCell';
import {
  ROOT_CELL_WIDTH,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
  ELEMENT_LIST_ITEM_HEIGHT,
  ELEMENT_CO2_BAR_HEIGHT,
  ELEMENT_COST_BAR_HEIGHT,
  ROOT_CELL_WIDTH_RESPONSIVE,
} from '../../list.constants';
import { useBooleanState } from '../../../../hooks/hooks';
import {
  useIsMenuOpen,
  useTriggerContextMenu,
} from '../../../../hooks/menu.hooks';
import { LIST_BORDER, useListRowStyles } from '../../list.style';
import { useMouseEventCallback } from '../../../../hooks/events.hook';
import InlineTextField from '../../../InlineTextField';
import { IProposal } from '../../../../../../shared/models/proposals.interface';
import {
  useHiddenProposal,
  useProposalColor,
  useProposalElementScale,
  useProposals,
  useProposalsBarChartValues,
  useSelectProposal,
  useUpdateProposals,
} from '../../../../hooks/proposals.hook';
import { required } from '../../../../../../shared/helpers/function_helpers';
import ProposalKebabMenu from '../../../menus/KebabMenu/KebabMenus/ProposalKebabMenu';
import { formatValue } from '../../../../../../shared/helpers/math_helpers';
import MultiLineTooltip from '../../../MultiLineTooltip';
import { useIsReadonly } from '../../../../hooks/user.hook';
import ResultsBarCharts from '../../../charts/ResultsBarCharts';
import { findFreeName } from '../../../../../../shared/helpers/string_helpers';
import {
  useProjectCostIsDisabled,
  useProjectId,
} from '../../../../store/project';
import { ResultsTooltip } from '../../../ResultsTooltip';
import ProposalVisibilityToggle from './ProposalVisibilityToggle';
import TargetIndicator from '../../../charts/TargetIndicator';

interface IProposalListItemInput {
  proposal: IProposal;
  autoFocus?: boolean;
}

const ProposalListItem: React.FC<IProposalListItemInput> = ({
  proposal,
  autoFocus,
}) => {
  const { classes: listClasses } = useListRowStyles();
  const { id, active } = proposal;

  const hidden = useHiddenProposal(id);
  const disableCost = useProjectCostIsDisabled();

  const readonly = useIsReadonly();
  const triggerContextMenu = useTriggerContextMenu(id);
  const selectProposal = useSelectProposal();
  const updateProposals = useUpdateProposals();

  const proposals = useProposals();
  const proposalColor = useProposalColor(proposal);
  const version = required(useSelectedVersion());

  const projectId = useProjectId();
  const {
    co2ePerGFAResults,
    costPerGFAResults,
    co2ePerGFA,
    costPerGFA,
    co2ePerGfaMax,
    costPerGfaMax,
    resultsPerGFA,
  } = useProposalsBarChartValues(id);

  const co2Scale = useProposalElementScale('co2e');
  const costScale = useProposalElementScale('sek');

  const isKebabMenuOpen = useIsMenuOpen(id);
  const isFirstProposalInList = proposal === proposals[0];
  const isLastProposalInList = proposal === proposals[proposals.length - 1];

  const [hover, startHover, endHover] = useBooleanState();

  const [isEditingName, startEditingName, stopEditingName] =
    useBooleanState(false);

  const [kebabMenuAnchor, setKebabMenuAnchor] = useState<Element>();

  const handleRowClick = useMouseEventCallback(
    () => {
      if (!readonly) {
        selectProposal(id);
      }
    },
    { ignoreInputEvents: true },
  );

  const nameInputProps: Partial<OutlinedTextFieldProps> = useMemo(
    () => ({
      autoFocus,
      onFocus: (e) => {
        if (autoFocus) {
          e.currentTarget.select();
        }
      },
    }),
    [autoFocus],
  );

  const saveName = useCallback(
    async (name: string) => {
      const availableName = findFreeName(
        proposals.map((p) => p.name),
        name,
      );
      stopEditingName();

      try {
        await updateProposals(version, {
          id: proposal.id,
          name: availableName,
        });
      } catch {
        setTimeout(() => {
          startEditingName();
        }, 250);
      }
    },
    [
      proposal.id,
      startEditingName,
      stopEditingName,
      updateProposals,
      version,
      proposals,
    ],
  );

  const closeKebabMenu = useCallback(() => {
    endHover();
    setKebabMenuAnchor(undefined);
  }, [endHover]);

  const editName = useCallback(() => {
    startEditingName();
    closeKebabMenu();
  }, [closeKebabMenu, startEditingName]);

  // Start editing the name if autoFocus is set
  useEffect(() => {
    autoFocus && startEditingName();
  }, [autoFocus, startEditingName]);

  return (
    <Box
      width="100%"
      component="div"
      onMouseLeave={endHover}
      onMouseOver={readonly ? undefined : startHover}
      onClick={handleRowClick}
    >
      <Row
        classes={listClasses}
        height={ELEMENT_LIST_ITEM_HEIGHT}
        onContextMenu={triggerContextMenu}
        hover={hover}
        padding={true}
        spacing={LIST_SPACING.DEFAULT}
        borderTop={isFirstProposalInList ? LIST_BORDER : undefined}
        borderBottom={isLastProposalInList ? LIST_BORDER : undefined}
      >
        {/* CONTENT. Group content in a shared cell to make sure we can align bar charts as one */}
        <RowCell
          width={ROOT_CELL_WIDTH.CONTENT}
          borderRight={LIST_BORDER}
          paddingRight={LIST_SPACING}
        >
          <Row height={ELEMENT_LIST_ITEM_HEIGHT} spacing={LIST_SPACING.DEFAULT}>
            <RowCell width={ROOT_CELL_WIDTH.ICON}>
              {(hover || hidden) && (
                <ProposalVisibilityToggle proposal={proposal} />
              )}
            </RowCell>

            {/* Name input */}
            <RowCell
              width={CONTENT_CELL_WIDTH.NAME}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
              paddingLeft={30}
            >
              <InlineTextField
                value={proposal.name}
                variant="subtitle1"
                editing={isEditingName}
                readonly={!isEditingName}
                inactive={!active && !isEditingName}
                onSave={saveName}
                onCancel={stopEditingName}
                autoWidth={true}
                textFieldProps={nameInputProps}
              />
            </RowCell>

            {/* Kebab menu */}
            <RowCell width={CONTENT_CELL_WIDTH.ICON} hideOnPrint align="center">
              {!readonly && (hover || kebabMenuAnchor || isKebabMenuOpen) && (
                <ProposalKebabMenu
                  proposal={proposal}
                  anchor={kebabMenuAnchor}
                  onOpen={setKebabMenuAnchor}
                  onClose={closeKebabMenu}
                  onEdit={editName}
                />
              )}
            </RowCell>
          </Row>
        </RowCell>

        {/* Bar chart */}
        <RowCell
          width={ROOT_CELL_WIDTH.BAR}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        >
          <TargetIndicator maxValue={co2ePerGfaMax} projectId={projectId} />
          <ResultsTooltip results={resultsPerGFA} perUnit={'GFA'}>
            <ResultsBarCharts
              co2e={co2ePerGFAResults}
              co2eMax={co2ePerGfaMax}
              cost={costPerGFAResults}
              costMax={costPerGfaMax}
              color={!hidden ? proposalColor : undefined}
              co2eHeight={ELEMENT_CO2_BAR_HEIGHT * co2Scale}
              costHeight={ELEMENT_COST_BAR_HEIGHT * costScale}
              disableCost={disableCost}
            />
          </ResultsTooltip>
        </RowCell>

        {/* CO2 Label */}
        <RowCell
          width={CONTENT_CELL_WIDTH.LABEL}
          align="center"
          fontSize={12}
          fontWeight={700}
        >
          <MultiLineTooltip
            rows={['Emissions per GFA', 'kgCO2e / m2']}
            disableInteractive
          >
            {formatValue(co2ePerGFA, 'co2e_A1-A3', { showUnit: false })}
          </MultiLineTooltip>
        </RowCell>

        {/* Cost Label */}
        <RowCell
          width={CONTENT_CELL_WIDTH.LABEL}
          align="center"
          fontSize={10}
          fontWeight={400}
        >
          {!disableCost && (
            <MultiLineTooltip
              rows={['Cost per GFA', 'kSEK / m2']}
              disableInteractive
            >
              {formatValue(costPerGFA, 'kSEK', { showUnit: false })}
            </MultiLineTooltip>
          )}
        </RowCell>
      </Row>
    </Box>
  );
};

export default memo(ProposalListItem);
