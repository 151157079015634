import { isObject, omit } from 'lodash';
import {
  calculatedUnits,
  ConversionFactors,
  Results,
  QuantityUnit,
  EmissionCostLabel,
  ConversionFactorUnit,
} from '../models/unit.interface';
import { mathJS } from './mathjs';
import { multiplyProperties } from './math_helpers';
import { getCommonKeys, isEmptyObject } from './object_helpers';
import { isCO2eUnit, isConversionFactorUnit, isCostUnit } from './unit_helpers';
import { isOneOf } from './array_helpers';
import {
  compressConversionFactors,
  getConversionFactorValue,
  hasConversionFactor,
  SupportedConversionUnits,
} from './conversion-factors.helpers';
import {
  OptionallyRequired,
  OptionallyRequiredOptions,
} from '../models/type_helpers.interface';
import { required } from './function_helpers';

const unitRecord = {
  length: [
    'mm',
    'cm',
    'dm',
    'm',
    'km',
    'inch',
    'ft',
    'yd',
    'mi',
    'nmi',
  ] as const,
  area: [
    'cm2',
    'cm²',
    'dm2',
    'm²',
    'km',
    'km2',
    'km²',
    'm2',
    'm²',
    'mm2',
    'mm²',
  ] as const,
  mass: [
    'kg',
    'tonne', // metric ton
    't', // metric ton
    'g',
    'hg',
    'mg',
    'lb', // pound
    'N',
    'kN',
  ] as const,
  volume: ['cm3', 'cm³', 'dm3', 'dm³', 'm3', 'm³', 'mm3', 'mm³', 'l'] as const,
  time: ['s', 'h', 'hr', 'day', 'year', 'hour', 'min', 'day'] as const,
  energy: ['J', 'kJ', 'MJ', 'kWh'] as const,
  arealDensity: ['kg/m²', 'g/cm²', 'kg/m2', 'g/cm2'] as const,
  density: ['kg/m3', 'g/cm3', 'kg/m³', 'g/cm³'] as const,
  flow: ['l/s', 'm3/s', 'm³/s'] as const,
  arealFlow: ['l/m2s', 'l/m²s', 'm3/m2s', 'm³/m²s'] as const,
  count: ['pcs', 'unit', '%', 'percent', 'ppm', 'kpl'] as const, // kpl is pcs in finnish used for co2data.fi
  temperature: ['°C', '°F', 'K', 'celsius', 'fahrenheit'] as const,
  effect: ['W', 'kW'] as const,
};

const conversionUnits = [
  ...unitRecord.length,
  ...unitRecord.area,
  ...unitRecord.volume,
  ...unitRecord.temperature,
  ...unitRecord.mass,
  ...unitRecord.time,
  ...unitRecord.energy,
  ...unitRecord.arealDensity,
  ...unitRecord.density,
  ...unitRecord.flow,
  ...unitRecord.arealFlow,
  ...unitRecord.count,
  ...unitRecord.effect,
] as const;

/**
 * Units that can be converted between.
 */
export type ConversionUnit = (typeof conversionUnits)[number];

export const isConversionUnit = (unit: string): unit is ConversionUnit => {
  return conversionUnits.includes(unit as ConversionUnit);
};

export const stripCalculatedConversionFactors = (
  conversionFactors: ConversionFactors,
): ConversionFactors => {
  return omit(conversionFactors, calculatedUnits);
};

/**
 * Convert the count to another unit maintaining the same ratio.
 * Note that ConversionFactors now has been migrated to expected format (2022-08-30)
 * Sample: {kg: 1, m³: 0.00142857}
 */
export const convertCount = (
  count: number,
  conversionFactors: ConversionFactors,
  from: ConversionFactorUnit,
  to: SupportedConversionUnits,
): number => {
  if (from === to) {
    return count;
  }
  const fromFactor = getConversionFactorValue(conversionFactors, from);
  const toFactor = getConversionFactorValue(conversionFactors, to);

  if (
    typeof fromFactor !== 'number' ||
    typeof toFactor !== 'number' ||
    toFactor === 0 ||
    fromFactor === 0
  ) {
    return 0;
  }
  return (count * toFactor) / fromFactor;
};

/**
 * Make conversionFactors to relate to the given unit.
 * Provide a count to scale the conversionFactors, if not provided it will be relative to 1 unit of the conversionFactors.
 * If we can't convert to a unit all values will be 0
 * @param factors
 * @param convertTo QuantityUnit to convert to. If passing conversion_factors, it will find a common unit to convert to.
 * @param count
 * @returns
 */
export const convertConversionFactors = <T extends Results | ConversionFactors>(
  factors: T,
  convertTo?: SupportedConversionUnits | T,
  count = 1,
): T => {
  if (convertTo === undefined || isEmptyObject(convertTo)) {
    return factors;
  }

  // If passing a conversion factor object we will find a common unit to convert to
  if (typeof convertTo !== 'string') {
    if (!isObject(convertTo)) {
      throw new Error('convertTo must be a string or an object');
    }

    // Common unit to used to scale the conversion factors
    const unit = getConvertToUnit(factors, convertTo);

    // Run this method again with a selected unit if found else return empty conversion factors
    return isConversionFactorUnit(unit)
      ? convertConversionFactors(factors, unit, convertTo[unit])
      : ({} as T);
  }

  const to = getConversionFactorValue(factors, convertTo);
  const factor = to ? count / to : 0;

  return multiplyConversionFactors(factors, factor);
};

/**
 * Get units that we can convert between.
 * I.E the non-CO2e and non-cost units (like kg, m³, kWh, m²)
 * @param factors
 * @returns
 */
export const getConversionUnits = (
  ...factors: ConversionFactors[]
): ConversionFactorUnit[] =>
  getCommonKeys(...factors).filter(
    (key) => !isCO2eUnit(key) && !isCostUnit(key),
  );

const PREFERRED_ORDER: ConversionFactorUnit[] = ['kg', 'm³', 'kWh', 'm²'];

/**
 * Get the preferred conversion unit.
 * An available unit in the conversion factor that is most likely to be used.
 * @param factors
 * @returns
 */
export const getPreferredConversionUnit = (
  ...factors: ConversionFactors[]
): ConversionFactorUnit | undefined => {
  const conversionUnits = getConversionUnits(...factors);
  for (const unit of PREFERRED_ORDER) {
    if (conversionUnits.includes(unit)) {
      return unit;
    }
  }
  return conversionUnits[0];
};

/**
 * Get a unit that is common to both conversion factors. Can be used to scale/align conversion factors.
 * @param from
 * @param to
 * @returns
 */
const getConvertToUnit = (
  from: ConversionFactors,
  to: ConversionFactors,
): QuantityUnit | undefined => {
  // All non-CO2e units in both factors
  const commonKeys = getConversionUnits(from, to);

  return (
    commonKeys.find((key) => to[key] === 1) ?? // Prefer units that are 1 in the convertTo object) ??
    commonKeys.find((key) => from[key] === to[key]) ?? // Prefer units that are the same in both objects
    commonKeys[0] // Default to the first common key
  );
};

/**
 * Test if a conversion factor can be converted to a unit.
 * @param conversionFactors
 * @param unit
 * @returns
 */
export const canConvertConversionFactors = (
  conversionFactors: ConversionFactors,
  unit: ConversionFactorUnit | ConversionFactors,
): boolean => {
  if (typeof unit === 'string') {
    if (!isConversionFactorUnit(unit)) {
      throw new Error(`Unit ${String(unit)} is not a valid quantity unit`);
    }
    return (
      unit in conversionFactors &&
      typeof conversionFactors[unit] === 'number' &&
      isFinite(conversionFactors[unit])
    );
  }
  return getConvertToUnit(conversionFactors, unit) !== undefined;
};

export const multiplyConversionFactors = <
  T extends Results | ConversionFactors,
>(
  conversionFactors: T | undefined,
  factor: number,
): T => {
  return compressConversionFactors(
    multiplyProperties(conversionFactors ?? {}, factor) as T,
  );
};

/**
 * Math.js doesn't support some units like % or m³, so we need to convert them to a supported unit.
 * @param unit
 * @returns
 */
const toMathJsUnit = (unit: ConversionUnit | QuantityUnit): ConversionUnit => {
  if (unit === '%') {
    return 'percent';
  }
  if (unit === '°C') {
    return 'celsius';
  }
  if (unit === '°F') {
    return 'fahrenheit';
  }
  return unit.replace(/²/g, '2').replace(/³/g, '3') as ConversionUnit;
};

/**
 * Get a unit that can be converted from the conversionFactors object.
 * @param conversionFactors
 * @param to
 * @returns
 */
export const getConvertableUnitFromConversionFactors = (
  conversionFactors: ConversionFactors,
  to: ConversionFactorUnit | QuantityUnit | ConversionUnit,
): ConversionFactorUnit | undefined => {
  // If the unit is already in the conversionFactors object, return it
  if (hasConversionFactor(conversionFactors, to)) {
    return to as ConversionFactorUnit;
  }

  // Search for the unit in the unitRecord
  for (const units of Object.values(unitRecord)) {
    // If the unit is a part of a group of units (like mm is part of length units)
    if (isOneOf(units, to)) {
      // Find the unit that is in the conversionFactors object that relates to this unit (if mm we want to find m)
      return units
        .filter(isConversionFactorUnit)
        .find((unit) =>
          hasConversionFactor(conversionFactors, unit),
        ) as ConversionFactorUnit;
    }
  }
};

/**
 * Convert a value from one unit to another
 * @param value
 * @param from
 * @param to
 * @returns
 */
export const convert = (
  value: number,
  from: QuantityUnit | ConversionUnit,
  to: QuantityUnit | ConversionUnit,
): number => {
  // Can't convert from or to none
  if (from === 'none' || to === 'none' || !from || !to) {
    return 0;
  }

  from = toMathJsUnit(toConversionUnit(from));
  to = toMathJsUnit(toConversionUnit(to));

  if (from === to) {
    return value;
  }

  const availableUnits = Object.values(unitRecord).find((units) =>
    isOneOf(units, from),
  );

  // Can't convert to a unit that is not available
  if (!isOneOf(availableUnits, to)) {
    throw new Error(`Conversion from ${from} to ${String(to)} is not allowed`);
  }

  return mathJS.unit(value, from).toNumber(to);
};

/**
 * Convert some odd units (from imports) to units that can be used for conversion.
 * @param unit
 * @returns Unit that can be used for conversion or undefined if it can't be converted
 */
export const toConversionUnit = <T extends OptionallyRequiredOptions = true>(
  unit: string,
  requireUnit: T = true as T,
): OptionallyRequired<ConversionUnit, T> => {
  const lowerCaseUnit = unit.toLowerCase();
  if (isConversionUnit(unit)) {
    return unit;
  }
  if (isConversionUnit(lowerCaseUnit)) {
    return lowerCaseUnit;
  }

  switch (lowerCaseUnit) {
    case 'm3':
    case 'm^3':
      return 'm³';
    case 'm2':
    case 'm^2':
    case 'sqm':
    case 'qm':
    case 'kvm':
      return 'm²';
    case 'omg': // seen as 'Kopiering', might mean 'Omgångar' ???
    case 'st':
    case 'stk':
    case 'pcs.':
      return 'pcs';
    case 'ton':
      return 't';
    case 'mj':
      return 'MJ';
    case 'kwh':
      return 'kWh';
  }

  if (requireUnit) {
    throw new Error(`Cannot find matching unit for ${unit}`);
  }

  return undefined as OptionallyRequired<ConversionUnit, T>;
};

/**
 * Get densite as kg/m³
 * @param factors
 * @returns
 */
export const getDensity = (factors: ConversionFactors): number | undefined => {
  const { kg, 'm³': m3 } = factors;

  if (typeof kg === 'number' && typeof m3 === 'number') {
    return !m3 ? 0 : kg / m3;
  }
};

/**
 * Determine if cost should be in thousands SEK or not
 * NOTE: formatValue handles this automatically
 */
export const convertToThousandSEK = (
  value: number,
  unitLabel: EmissionCostLabel = 'kSEK',
): number => (unitLabel === 'kSEK' ? value / 1000 : value);

/**
 * Get the unit to save in the conversion factors from another unit.
 * Providing mm will return mm, m3 will return m³, l will return m³ etc
 * @param from Unit to convert from
 * @returns Unit to save in the conversion factors
 */
export const getCorrespondingConversionFactorUnit = <
  T extends OptionallyRequiredOptions = true,
>(
  unitInput: string,
  requireUnit: T = true as T,
): OptionallyRequired<ConversionFactorUnit, T> => {
  const unit = toConversionUnit(unitInput, requireUnit);
  const error = requireUnit
    ? 'Cannot find matching unit for ' + unitInput
    : false;

  // Search for the unit in the unitRecord
  for (const units of Object.values(unitRecord)) {
    // If the unit is a part of a group of units (like mm is part of length units)
    if (isOneOf(units, unit)) {
      return required(
        units.find((unit) => isConversionFactorUnit(unit)),
        error,
      );
    }
  }
  return required(undefined, error);
};

/**
 * List of units that is stored in another unit but need
 * conversion to be shown in the correct unit.
 * 100% is stored as 1
 * MJ is stored in kWh
 */
const AUTO_CONVERTED_UNITS: QuantityUnit[] = ['%', 'MJ'];

/**
 * Get the value in the selected unit provided that the value is stored in a ConversionFactorUnit (VERY IMPORTANT)
 * @param value
 * @param unit
 */
export const getValueInSelectedUnit = <T extends number | undefined>(
  value: T,
  unit?: QuantityUnit | 'none',
): T => {
  if (!value) {
    return value;
  }

  // Do not modify these (for now)
  if (!unit || !AUTO_CONVERTED_UNITS.includes(unit)) {
    return value;
  }

  const originalUnit = getCorrespondingConversionFactorUnit(unit, false);

  return originalUnit ? (convert(value, originalUnit, unit) as T) : value;
};
