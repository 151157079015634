import React, { useMemo } from 'react';
import { Box, SxProps, Tooltip } from '@mui/material';
import { NodonTheme } from '../../style';
import { useProjectTarget } from '../../store/project';
import { formatValue } from '../../../../shared/helpers/math_helpers';
import { getWidthInPercent } from './bar-chart.helpers';

interface TargetIndicatorProps {
  maxValue: number;
  projectId: number | string;
}

const TargetIndicator: React.FC<TargetIndicatorProps> = ({
  maxValue,
  projectId,
}) => {
  const target = useProjectTarget(projectId);

  const targetValue = Math.min(target ?? 0, maxValue); // if no target, use 0 + never surpass maxValue
  const offset = getWidthInPercent(targetValue, maxValue);
  const hoverAreaStyles = useMemo<SxProps>(
    () => ({
      zIndex: 0,
      position: 'absolute',
      height: '100%',
      transition: 'left 0.5s ease',
      padding: `0 ${HOVER_AREA_WIDTH}px`,
      left: `calc(${offset}% - ${HOVER_AREA_WIDTH}px)`,
    }),
    [offset],
  );
  const indicatorStyles = useMemo<SxProps>(
    () => ({
      height: '100%',
      borderLeft: `1px solid ${NodonTheme.palette.neutral.light}`,
    }),
    [],
  );

  return (
    !!target && (
      <Tooltip
        disableInteractive
        title={`Target: ${formatValue(target, 'kg/m²')}`}
      >
        <Box sx={hoverAreaStyles}>
          <Box sx={indicatorStyles} />
        </Box>
      </Tooltip>
    )
  );
};

const HOVER_AREA_WIDTH = 8;

export default TargetIndicator;
