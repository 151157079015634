import { ICoordinate } from '../../models/project.interface';
import { getCoordinateDistance } from '../coordinates.helpers';
import { required } from '../function_helpers';
import { cityClimateDataList } from './data/city-climate.data';
import { degreeHourTable } from './data/degree-hour.data';
import { DEFAULT_LIMIT_TEMP, ICityClimateData } from './energy.models';

/**
 * Get an "average" form values in cityClimateList based on coordinate distance.
 * @param target - The coordinate to get the average for
 * @param valueFn - The function to get the value from the cityClimateList
 * @returns The average value
 */
const getDistanceWeightedAverage = (
  target: ICoordinate,
  valueFn: (item: ICityClimateData) => number,
): number => {
  let tempValue = 0;
  let weights = 0;
  for (const city of cityClimateDataList) {
    const value = valueFn(city);
    const distance = getCoordinateDistance(target, city) / 1000; // distance in km
    const weight = 1 / Math.pow(distance, 2); // viktar med 1/avstånd^2, dvs punkter som ligger nära får mkt större betydelse
    tempValue += value * weight;
    weights += weight;
  }
  return tempValue / weights;
};

/**
 * Get the degree hours for a given coordinate.
 * Degree-hours are measured in the unit °Ch.
 * This unit represents the product of the temperature difference (in degrees Celsius) and the time (in hours) during which heating is needed.
 * https://www.perplexity.ai/search/kan-du-forklara-gradtimmar-syKl5qUoQya1g8lITItxyg
 * @param target - The coordinate to get the degree hours for
 * @param limitTemp - The temp at which the building need heating to maintain a comfortable indoor temperature.
 */
export const getDegreeHoursFromCoordinate = (
  target: ICoordinate,
  limitTemp = DEFAULT_LIMIT_TEMP,
) => {
  // normalårstemperatur:
  const normalYearTemp = getNormalYearTemp(target);
  // gradtimmar för gränstemperaturen på orten:
  return getDegreeHours(normalYearTemp, limitTemp);
};

/**
 * Get the normal year temperature (median temperature) for a given coordinate.
 * Is based on the cities in the table (only works decently for Sweden).
 * @param lat
 * @param lng
 * @returns
 */
export const getNormalYearTemp = (target: ICoordinate): number =>
  getDistanceWeightedAverage(target, (city) => city.temp);

/**
 * Get the sun hours for a given coordinate.
 * Is based on the cities in the table (only works decently for Sweden).
 * @param target - The coordinate to get the sun hours for
 * @returns The sun hours
 */
export const getSunHours = (target: ICoordinate): number =>
  getDistanceWeightedAverage(target, (city) => city.sunHours);

/**
 * Get the design outdoor temperature (DUT). In swedish "Dimensionerande utetemperatur (DUT)" or "Dimensionerande vinterutetemperatur (DVUT)".
 * Basically the lowest temperature that the heating system needs to be able to handle (currently for 3 days in a row).
 * Is based on the cities in the table (only works decently for Sweden).
 * @param target - The coordinate to get the DUT for
 * @returns The DUT in °C
 */
export const getDesignOutdoorTemp = (target: ICoordinate): number =>
  getDistanceWeightedAverage(target, (city) => city.dut);

/**
 * Calculate the gränstemperaturen, dvs den temperatur som byggnaden behöver värmas till (resterande grader värms av freeHeatingEffect)
 * @param tempIn
 * @param Ftot
 * @param freeHeatingEffect
 * @returns
 */
export const getLimitTemperature = (
  tempIn: number,
  Ftot: number,
  freeHeatingEffect: number,
) => {
  return tempIn - freeHeatingEffect / Ftot;
};

/**
 * Calculate the free heating effect (W). Which is the heating provided by people, machines, sunlight etc.
 * @param activityMix
 * @param sunHours
 * @returns
 */
export const getFreeHeatingEffect = () =>
  //activityMix: object, sunHours: number
  {
    return 700;
    // TODO: använd getPersonCountEstimateInApartment(apartmentMix);
    // TODO: använd getPersonCountEstimateInOffice(Atemp);
    // behöver en funktion som tar hänsyn till
    // 1) activities för att beräkna värmebidrag fr förväntat antal människor, maskiner etc
    // 2) antalet soltimmar på orten
    // 3) fönsterarea. mer fönster -> mer uppvärmning fr solljus
    // 4) ?? hur fasaderna vetter och ifall det finns direkt solljus mot dom eller ifall de är skymda etc. överkurs?
  };

/**
 * Get the degree hours (°Ch) for a given coordinate.
 * This unit represents the product of the temperature difference (in degrees Celsius) and the time (in hours) during which heating is needed.
 * @param limitTemp  (Gränstemperatur °C). The temperature at which the building need heating to maintain a comfortable indoor temperature.
 * @param normalYearTemp (Normalårstemperatur °C)
 * @returns
 */
export const getDegreeHours = (
  normalYearTemp: number,
  limitTemp: number = DEFAULT_LIMIT_TEMP,
): number => {
  const limitTempIndex = Math.round(limitTemp) as keyof typeof degreeHourTable;
  const normalYearTempIndex = Math.round(
    normalYearTemp,
  ) as keyof (typeof degreeHourTable)[typeof limitTempIndex];

  return required(
    degreeHourTable[limitTempIndex]?.[normalYearTempIndex],
    `Could not find degree hours for limit temperature: ${limitTemp} and normal year temperature: ${normalYearTemp}`,
  );
};
