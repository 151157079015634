import React, {
  forwardRef,
  useCallback,
  useState,
  useMemo,
  ElementType,
  ChangeEventHandler,
} from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import {
  StandardTextFieldProps,
  useTheme,
  Typography,
  InputBaseComponentProps,
  TextFieldProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import NodonTextField from './NodonTextField';
import { Variant } from '@mui/material/styles/createTypography';
import {
  formatThousands,
  isNumeric,
} from '../../../shared/helpers/math_helpers';

const REGEX_WHITESPACE = /\s/g;

interface ReactNumberFormatProps {
  inputRef: (instance: typeof NumericFormat | null) => void;
  onChange: (v: NumberFormatValues) => void;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onFocus: () => void;
  value: string;
  placeholder: string;
  max?: number;
  min?: number;
}

const ReactNumberFormatInput: React.FC<ReactNumberFormatProps> = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props, ref) => {
    //NOTE: The ...other is here because MUI is too lazy to specify
    //what props they actually supply.
    const {
      inputRef,
      onChange,
      onBlur,
      onFocus,
      value,
      placeholder,
      max,
      min,
      ...other
    } = props;

    const [shouldFormat, setShouldFormat] = useState(true);

    const formattedValue = useMemo(
      () =>
        value === ''
          ? ''
          : formatThousands(Number(value)).replace(REGEX_WHITESPACE, ''),
      [value],
    );

    const formattedPlaceholder = useMemo(() => {
      if (!placeholder) {
        return '';
      }
      const noSpaces = placeholder.replace(REGEX_WHITESPACE, '');

      const formatted = formatThousands(
        isNumeric(noSpaces) ? Number(noSpaces) : 0,
      );
      return placeholder === '' ? '' : formatted;
    }, [placeholder]);

    const handleFocus = useCallback(() => {
      onFocus();
      setShouldFormat(false);
    }, [onFocus]);

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        onBlur(e);
        setShouldFormat(true);
      },
      [onBlur],
    );

    const validateValue = useCallback(
      (values: any): boolean => {
        const { floatValue } = values;

        if (floatValue) {
          if (min !== undefined && max === undefined) return floatValue >= min;
          if (max !== undefined && min === undefined) return floatValue <= max;
          if (max !== undefined && min !== undefined)
            return floatValue >= min && floatValue <= max;
        }
        return true;
      },
      [max, min],
    );

    return (
      <NumericFormat
        {...other}
        value={shouldFormat ? formattedValue : value}
        placeholder={formattedPlaceholder}
        getInputRef={inputRef}
        onValueChange={onChange}
        thousandSeparator={' '}
        decimalSeparator={'.'}
        onFocus={handleFocus}
        onBlur={handleBlur}
        valueIsNumericString={true}
        isAllowed={validateValue}
      />
    );
  },
);

interface NumberInputProps
  extends Omit<StandardTextFieldProps, 'onChange' | 'value' | 'variant'> {
  value?: number | string;
  onChange: (value?: number | string) => void;
  onBlur: React.FocusEventHandler<HTMLInputElement> | undefined;
  variant?: Variant;
}

const NumberInput: React.FC<NumberInputProps> = ({
  value = '',
  onChange,
  variant,
  ...other
}) => {
  const { classes } = useStyles();
  const { typography } = useTheme();

  const variantStyle: Partial<typeof Typography> = useMemo(() => {
    if (!variant) {
      return {};
    }

    const { fontFamily, fontWeight, fontSize, lineHeight, letterSpacing } =
      typography[variant];
    return { fontFamily, fontWeight, fontSize, lineHeight, letterSpacing };
  }, [typography, variant]);

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (value) => {
      const newValues = value as unknown as NumberFormatValues;
      onChange(newValues.value || undefined);
    },
    [onChange],
  );

  const inputProps: TextFieldProps['InputProps'] = useMemo(
    () => ({
      inputComponent:
        ReactNumberFormatInput as unknown as ElementType<InputBaseComponentProps>,
      classes,
      ...other.InputProps,
      style: { ...variantStyle, ...other.InputProps?.style },
    }),
    [classes, other.InputProps, variantStyle],
  );

  return (
    <NodonTextField
      {...other}
      value={value}
      onChange={handleChange}
      InputProps={inputProps}
    />
  );
};

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
  input: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default NumberInput;
