import {
  DEFAULT_QUANTITY_EXPRESSION,
  DEFAULT_QUANTITY_EXPRESSIONS,
  ElementQuantityRecord,
  ElementQuantityExpressionRecord,
  IElementQuantityExpressionProperty,
  ElementQuantityName,
  ElementQuantityExpressionName,
  dimensionQuantityNames,
  areaQuantityNames,
  DimensionQuantityName,
} from '../models/element_quantities.interface';
import {
  ExpressionValue,
  IElement,
  IElementID,
  OneOfParentElements,
} from '../models/project.interface';
import shallowEqual, {
  cloneRemoveItems,
  includesAll,
  isDefined,
} from './array_helpers';
import {
  getElementProperties,
  getCount,
  getElementPropertyResolvedCount,
  hasCount,
  hasFallbackCount,
} from './element_property_helpers';
import {
  createElementQuantityRecord,
  getDefaultFallbackExpressionByName,
  getElementQuantityExpressionRecord,
  getElementQuantityRecord,
  isElementQuantityExpressionProperty,
  isElementQuantityName,
} from './element_quantity_helpers';
import { applyChanges, getKeys } from './object_helpers';
import { ElementPropertyType } from '../models/element_property.interface';
import { createExpression } from './expression_factory_helpers';
import { getElementCategory } from './element_category_helpers';
import {
  ExpressionVariables,
  ExpressionVariablesRecord,
  defaultVariables,
  hasCircularDependencies,
} from './expression_variables_helpers';
import { countOccurrences } from './string_helpers';
import { getVariablesInExpression } from './mathjs';
import { errorHandledSolve } from './expression_solving_helpers';
import { isNumeric } from './math_helpers';
import { cacheFactory } from './function_helpers';

/**
 * These properties can be used in fallback even though they are not set by user
 */
const ALLOW_FALLBACK_REFERENCES: ElementQuantityName[] = [
  'fill',
  'density',
  'density_areal_side',
  'density_areal_top',
];

/**
 * These properties can't inherit properties from parent in it's fallback
 */
const FORBIDDEN_INHERITANCE: ElementQuantityName[] = [
  'fill',
  'density',
  'density_areal_side',
  'density_areal_top',
];

const GET_PARENT_VALUE_FN_NAME = 'getParentValue';
const INHERITABLE_QUANTITY_NAMES: (keyof ElementQuantityExpressionRecord)[] = [
  // 'repeating_item_spacing',
  // 'repeating_item_thickness',
  // 'repeating_item_count',
  'length',
  'width',
  'height',
  'area_side',
  'area_top',
  'area_section',
  'energy',
];

const operators = [
  '+',
  '-',
  '*',
  '/',
  '(',
  ')',
  ',',
  '100',
  '1',
  'sqrt(',
  'firstNonZero(',
] as const;
type Operator = (typeof operators)[number];
type FormulaPart = keyof ElementQuantityExpressionRecord | Operator;
type Formula = FormulaPart[];
type Replacements = Record<keyof ElementQuantityExpressionRecord, Formula>;

export interface IAutoDependency {
  name: keyof ElementQuantityExpressionRecord;
  formula: Formula;
}

/**
 * The different auto dependencies for volume
 */
const volumeAutoDependencies = (
  replacements: Replacements,
): IAutoDependency[] => [
  {
    name: 'volume',
    formula: ['length', '*', 'width', '*', 'height'],
  },
  {
    name: 'volume',
    formula: ['area_side', '*', 'width'],
  },
  {
    name: 'volume',
    formula: ['area_side', '*', 'area_top', '/', 'length'],
  },
  {
    name: 'volume',
    formula: ['area_side', '*', 'area_section', '/', 'height'],
  },
  {
    name: 'volume',
    formula: ['area_top', '*', 'height'],
  },
  {
    name: 'volume',
    formula: ['area_top', '*', 'area_section', '/', 'width'],
  },
  {
    name: 'volume',
    formula: ['area_section', '*', 'length'],
  },
  {
    name: 'volume',
    formula: ['(', ...replacements.mass, '/', 'density', ')', '/', 'fill'],
  },
  {
    name: 'volume',
    formula: ['sqrt(', 'area_side', '*', 'area_top', '*', 'area_section', ')'],
  },
];

/**
 * Get all possible dependendies for a property in relation to volume
 * (almost everything can be related to volume)
 * @param name Which property that will be related to volume
 * @param baseFormula Formula to prefix
 * @param prefix If true the volume formula will prefix the given baseFormula
 * @returns
 */
const getVolumeDependencies = (
  replacements: Replacements,
  name: keyof ElementQuantityExpressionRecord,
  baseFormula: Formula,
  prefix = true,
): IAutoDependency[] => {
  const volumeDep: IAutoDependency = {
    name,
    formula: prefix ? ['volume', ...baseFormula] : [...baseFormula, 'volume'],
  };

  const deps: IAutoDependency[] = volumeAutoDependencies(replacements).map(
    (dependency) => ({
      ...dependency,
      formula: prefix
        ? ['(', ...dependency.formula, ')', ...baseFormula]
        : [...baseFormula, '(', ...dependency.formula, ')'],
      name,
    }),
  );

  return [volumeDep, ...deps]
    .filter((dependency) => !dependency.formula.includes(name))
    .map((dependency) => ({
      ...dependency,
    }));
};

const areaSideAutoDependencies = (
  replacements: Replacements,
): IAutoDependency[] => [
  {
    name: 'area_side',
    formula: ['length', '*', 'height'],
  },
  {
    name: 'area_side',
    formula: ['height', '*', 'area_top', '/', 'width'],
  },
  {
    name: 'area_side',
    formula: [
      '(',
      'area_top',
      '/',
      'width',
      ')',
      '*',
      '(',
      'area_section',
      '/',
      'width',
      ')',
    ],
  },
  ...getVolumeDependencies(replacements, 'area_side', ['/', 'width']),
  ...getVolumeDependencies(replacements, 'area_side', [
    '/',
    '(',
    'area_top',
    '/',
    'length',
    ')',
  ]),
  ...getVolumeDependencies(replacements, 'area_side', [
    '/',
    '(',
    'area_section',
    '/',
    'height',
    ')',
  ]),
  ...getVolumeDependencies(replacements, 'area_side', [
    '*',
    'volume',
    '/',
    'area_top',
    '/',
    'area_section',
  ]),
  {
    name: 'area_side',
    formula: [...replacements.mass, '/', 'density_areal_side', '/', 'fill'],
  },
  {
    name: 'area_top',
    formula: [...replacements.mass, '/', 'density_areal_top', '/', 'fill'],
  },
];

const areaTopAutoDependencies = (
  replacements: Replacements,
): IAutoDependency[] => [
  {
    name: 'area_top',
    formula: ['length', '*', 'width'],
  },
  {
    name: 'area_top',
    formula: ['width', '*', 'area_side', '/', 'height'],
  },
  {
    name: 'area_top',
    formula: [
      '(',
      'area_side',
      '/',
      'height',
      ')',
      '*',
      '(',
      'area_section',
      '/',
      'height',
      ')',
    ],
  },
  ...getVolumeDependencies(replacements, 'area_top', ['/', 'height']),
  ...getVolumeDependencies(replacements, 'area_top', [
    '/',
    '(',
    'area_side',
    '/',
    'length',
    ')',
  ]),
  ...getVolumeDependencies(replacements, 'area_top', [
    '/',
    '(',
    'area_section',
    '/',
    'width',
    ')',
  ]),
  ...getVolumeDependencies(replacements, 'area_top', [
    '*',
    'volume',
    '/',
    'area_side',
    '/',
    'area_section',
  ]),
];

const areaSectionAutoDependencies = (
  replacements: Replacements,
): IAutoDependency[] => [
  {
    name: 'area_section',
    formula: ['width', '*', 'height'],
  },
  {
    name: 'area_section',
    formula: ['height', '*', 'area_top', '/', 'length'],
  },
  {
    name: 'area_section',
    formula: [
      '(',
      'area_top',
      '/',
      'length',
      ')',
      '*',
      '(',
      'area_side',
      '/',
      'length',
      ')',
    ],
  },
  ...getVolumeDependencies(replacements, 'area_section', ['/', 'length']),
  ...getVolumeDependencies(replacements, 'area_section', [
    '/',
    '(',
    'area_top',
    '/',
    'width',
    ')',
  ]),
  ...getVolumeDependencies(replacements, 'area_section', [
    '/',
    '(',
    'area_side',
    '/',
    'height',
    ')',
  ]),
  ...getVolumeDependencies(replacements, 'area_section', [
    '*',
    'volume',
    '/',
    'area_top',
    '/',
    'area_side',
  ]),
];

const massAutoDependencies = (
  quantity: ElementQuantityRecord,
  replacements: Replacements,
): IAutoDependency[] => {
  const repeatingFormula: Formula | undefined =
    getMassRepeatingItemsReplacementCondition(quantity)
      ? ['repeating_item_thickness', '/', 'repeating_item_spacing', '*']
      : [];

  const formulas: IAutoDependency[] = [
    ...getVolumeDependencies(replacements, 'mass', [
      '*',
      'density',
      '*',
      'fill',
    ]),
    ...getVolumeDependencies(replacements, 'mass', [
      '/',
      'width',
      '*',
      'density_areal_side',
      '*',
      'fill',
    ]),
    ...getVolumeDependencies(replacements, 'mass', [
      '/',
      'height',
      '*',
      'density_areal_top',
      '*',
      'fill',
    ]),
    {
      name: 'mass',
      formula: ['density_areal_side', '*', 'area_side', '*', 'fill'],
    },
    {
      name: 'mass',
      formula: ['density_areal_side', '*', 'length', '*', 'height'],
    },
    {
      name: 'mass',
      formula: ['density_areal_top', '*', 'area_top', '*', 'fill'],
    },
    {
      name: 'mass',
      formula: ['density_areal_top', '*', 'length', '*', 'width'],
    },
  ];

  return repeatingFormula
    ? formulas.map(({ name, formula }) => ({
        name,
        formula: [...repeatingFormula, ...formula],
      }))
    : formulas;
};

const lengthAutoDependencies = (
  replacements: Replacements,
): IAutoDependency[] => [
  {
    name: 'length',
    formula: ['area_top', '/', 'width'],
  },
  {
    name: 'length',
    formula: ['area_side', '/', 'height'],
  },
  ...getVolumeDependencies(replacements, 'length', [
    '/',
    '(',
    'width',
    '*',
    'height',
    ')',
  ]),
  ...getVolumeDependencies(replacements, 'length', [
    '/',
    'volume',
    '/',
    '(',
    'volume',
    '/',
    '(',
    'area_side',
    '*',
    'area_top',
    ')',
    ')',
  ]),
  ...getVolumeDependencies(replacements, 'length', ['/', 'area_section']),
];

const widthAutoDependencies = (
  quantity: ElementQuantityRecord,
  replacements: Replacements,
): IAutoDependency[] => {
  const repeatingFormulaResolved =
    (quantity.repeating_item_spacing?.count?.resolved ??
      quantity.repeating_item_spacing?.fallbackCount?.resolved ??
      0) *
      ((quantity.repeating_item_count?.count?.resolved ??
        quantity.repeating_item_count?.fallbackCount?.resolved ??
        0) -
        1) +
    (quantity.repeating_item_thickness?.count?.resolved ??
      quantity.repeating_item_thickness?.fallbackCount?.resolved ??
      0);
  return [
    {
      name: 'width',
      formula: ['area_section', '/', 'height'],
    },
    {
      name: 'width',
      formula: ['area_top', '/', 'length'],
    },
    ...getVolumeDependencies(replacements, 'width', [
      '/',
      '(',
      'length',
      '*',
      'height',
      ')',
    ]),
    ...getVolumeDependencies(replacements, 'width', [
      '/',
      'volume',
      '/',
      '(',
      'volume',
      '/',
      '(',
      'area_top',
      '*',
      'area_section',
      ')',
      ')',
    ]),
    ...getVolumeDependencies(replacements, 'width', ['/', 'area_side']),
    ...(quantity.repeating_items?.count &&
    quantity.repeating_direction?.count === 'horizontal' &&
    repeatingFormulaResolved > 0
      ? [
          {
            name: 'width',
            formula: [
              'repeating_item_spacing',
              '*',
              '(',
              'repeating_item_count',
              '-',
              '1',
              ')',
              '+',
              'repeating_item_thickness',
            ],
          } as IAutoDependency,
        ]
      : []),
  ];
};

const heightAutoDependencies = (
  quantity: ElementQuantityRecord,
  replacements: Replacements,
): IAutoDependency[] => {
  const repeatingFormulaResolved =
    (quantity.repeating_item_spacing?.count?.resolved ??
      quantity.repeating_item_spacing?.fallbackCount?.resolved ??
      0) *
      ((quantity.repeating_item_count?.count?.resolved ??
        quantity.repeating_item_count?.fallbackCount?.resolved ??
        0) -
        1) +
    (quantity.repeating_item_thickness?.count?.resolved ??
      quantity.repeating_item_thickness?.fallbackCount?.resolved ??
      0);

  return [
    {
      name: 'height',
      formula: ['area_side', '/', 'length'],
    },
    {
      name: 'height',
      formula: ['area_section', '/', 'width'],
    },
    ...getVolumeDependencies(replacements, 'height', [
      '/',
      '(',
      'length',
      '*',
      'width',
      ')',
    ]),
    ...getVolumeDependencies(replacements, 'height', [
      '/',
      'volume',
      '/',
      '(',
      'volume',
      '/',
      '(',
      'area_side',
      '*',
      'area_section',
      ')',
      ')',
    ]),
    ...getVolumeDependencies(replacements, 'height', ['/', 'area_top']),
    ...(quantity.repeating_items?.count &&
    quantity.repeating_direction?.count === 'vertical' &&
    repeatingFormulaResolved > 0
      ? [
          {
            name: 'height',
            formula: [
              'repeating_item_spacing',
              '*',
              '(',
              'repeating_item_count',
              '-',
              '1',
              ')',
              '+',
              'repeating_item_thickness',
            ],
          } as IAutoDependency,
        ]
      : []),
  ];
};

const densityAutoDependencies = (
  replacements: Replacements,
): IAutoDependency[] => [
  ...getVolumeDependencies(
    replacements,
    'density',
    [...replacements.mass, '/', 'fill', '/'],
    false,
  ),
];

const densityArealSideAutoDependencies = (
  replacements: Replacements,
): IAutoDependency[] => [
  {
    name: 'density_areal_side',
    formula: [...replacements.mass, '/', 'area_side'],
  },
  {
    name: 'density_areal_side',
    formula: [...replacements.mass, '/', '(', 'length', '*', 'height', ')'],
  },
];

const densityArealTopAutoDependencies = (
  replacements: Replacements,
): IAutoDependency[] => [
  {
    name: 'density_areal_top',
    formula: [...replacements.mass, '/', 'area_top'],
  },
  {
    name: 'density_areal_top',
    formula: [...replacements.mass, '/', '(', 'length', '*', 'width', ')'],
  },
];

const fillAutoDependencies = (
  replacements: Replacements,
): IAutoDependency[] => [
  ...getVolumeDependencies(
    replacements,
    'fill',
    ['(', ...replacements.mass, '/', 'density', ')', '/'],
    false,
  ),
];

// NOSONAR
// const repeatingItemSpacingAutoDependencies = (
//   quantity: ElementQuantityRecord,
// ): IAutoDependency[] =>
//   !quantity.repeating_items?.count
//     ? []
//     : [
//         {
//           name: 'repeating_item_spacing',
//           formula: [
//             'firstNonZero(',
//             '(',
//             quantity.repeating_direction?.count === 'horizontal'
//               ? 'width'
//               : 'height',
//             '-',
//             'repeating_item_thickness',
//             ')',
//             '/',
//             '(',
//             'repeating_item_count',
//             '-',
//             '1',
//             ')',
//             ',',
//             'repeating_item_thickness',
//             ')',
//           ],
//         },
//       ];

// NOSONAR
// const repeatingItemThicknessAutoDependencies = (
//   quantity: ElementQuantityRecord,
// ): IAutoDependency[] =>
//   !quantity.repeating_items?.count
//     ? []
//     : [
//         {
//           name: 'repeating_item_thickness',
//           formula: [
//             'firstNonZero(',
//             quantity.repeating_direction?.count === 'horizontal'
//               ? 'width'
//               : 'height',
//             '-',
//             'repeating_item_spacing',
//             '*',
//             '(',
//             'repeating_item_count',
//             '-',
//             '1',
//             ')',
//             ',',
//             'repeating_item_spacing',
//             ')',
//           ],
//         },
//       ];

// NOSONAR
// const repeatingItemCountAutoDependencies = (
//   quantity: ElementQuantityRecord,
// ): IAutoDependency[] =>
//   !quantity.repeating_items?.count
//     ? []
//     : [
//         {
//           name: 'repeating_item_count',
//           formula: [
//             '(',
//             quantity.repeating_direction?.count === 'horizontal'
//               ? 'width'
//               : 'height',
//             '-',
//             'repeating_item_thickness',
//             ')',
//             '/',
//             'repeating_item_spacing',
//             '+',
//             '1',
//           ],
//         },
//       ];

const inheritableAutoDependencies: IAutoDependency[] =
  INHERITABLE_QUANTITY_NAMES.map((name) => ({
    name,
    formula: [name],
  }));

const getMassRepeatingItemsReplacementCondition = (
  quantity: ElementQuantityRecord,
) =>
  quantity.repeating_items?.count &&
  ((hasCount(quantity.repeating_item_thickness) &&
    quantity.repeating_item_thickness.count.resolved !== 0) ||
    (hasFallbackCount(quantity.repeating_item_thickness) &&
      quantity.repeating_item_thickness.fallbackCount.resolved !== 0)) &&
  ((hasCount(quantity.repeating_item_spacing) &&
    quantity.repeating_item_spacing.count.resolved !== 0) ||
    (hasFallbackCount(quantity.repeating_item_spacing) &&
      quantity.repeating_item_spacing.fallbackCount.resolved !== 0));

const getReplacements = (quantity: ElementQuantityRecord): Replacements => ({
  length: [],
  width: [],
  height: [],
  area_side: [],
  area_top: [],
  area_section: [],
  volume: [],
  mass: getMassRepeatingItemsReplacementCondition(quantity)
    ? [
        '(',
        'mass',
        '/',
        '(',
        'repeating_item_thickness',
        '/',
        'repeating_item_spacing',
        ')',
        ')',
      ]
    : ['mass'],
  density: [],
  density_areal_side: [],
  density_areal_top: [],
  fill: [],
  repeating_item_spacing: [],
  repeating_item_thickness: [],
  repeating_item_count: [],
  time: [],
  energy: [],
});

export const autoDependencies = (
  quantity: ElementQuantityRecord,
): Readonly<IAutoDependency>[] => {
  const replacements = getReplacements(quantity);

  return [
    ...volumeAutoDependencies(replacements),
    ...lengthAutoDependencies(replacements),
    ...widthAutoDependencies(quantity, replacements),
    ...heightAutoDependencies(quantity, replacements),
    ...areaSideAutoDependencies(replacements),
    ...areaTopAutoDependencies(replacements),
    ...areaSectionAutoDependencies(replacements),
    ...massAutoDependencies(quantity, replacements),
    ...densityAutoDependencies(replacements),
    ...densityArealSideAutoDependencies(replacements),
    ...densityArealTopAutoDependencies(replacements),
    ...fillAutoDependencies(replacements),
    // ...repeatingItemSpacingAutoDependencies(quantity),
    // ...repeatingItemThicknessAutoDependencies(quantity),
    // ...repeatingItemCountAutoDependencies(quantity),
    ...inheritableAutoDependencies,
  ];
};

/**
 * Get all dependencies for a formula. I.E. filter out operators
 * @param formula
 * @returns
 */
const getDependencies = (
  formula: IAutoDependency['formula'],
): (keyof ElementQuantityExpressionRecord)[] =>
  formula.filter((part) => isElementQuantityName(part));

/**
 * Util to sort formulas by priority (highest prio first).
 * Currently it should prioritize length width and height.
 * This should adress the inheritance issue below:
 * @example
 * parent.length = 3
 * parent.width = 4
 * parent.area_top = 12
 *
 * width = 8
 * height = 10
 * volume = parent.area_top * height = 12 * 10 = 120 // Wrong
 * volume = parent.length * width * height = 3 * 8 * 10 = 240 // Correct
 * @param formula
 * @returns
 */
const getFormulaPrio = (formula: Formula): number => {
  const lowestPrio: FormulaPart[] = ['area_side', 'area_top', 'area_section'];
  const firstPart = formula[0];

  // Direct inheritable properties (length inheriting parent.length) have prio 1 unless they are lowest prio properties
  if (
    formula.length === 1 &&
    INHERITABLE_QUANTITY_NAMES.includes(
      formula[0] as ElementQuantityExpressionName,
    ) &&
    firstPart &&
    !lowestPrio.includes(firstPart) &&
    ALLOW_FALLBACK_REFERENCES.includes(formula[0] as ElementQuantityName)
  ) {
    return 1;
  }

  return formula.reduce((acc, part) => {
    if (ALLOW_FALLBACK_REFERENCES.includes(part as ElementQuantityName)) {
      return acc - 3;
    }

    if (lowestPrio.includes(part)) {
      return acc - 1;
    }
    return acc;
  }, 0);
};

/**
 * Get all formulas that are relevant for a certain quantity property.
 * Also make sure the formula won't reference itself unless it's an inheritable quantity.
 * @param name
 * @returns
 */
const getRelevantAutoFormulas = (
  quantity: ElementQuantityRecord,
  name: keyof ElementQuantityRecord,
  inheritable: (keyof ElementQuantityRecord)[] = [],
) =>
  autoDependencies(quantity)
    .filter((dep) => {
      return (
        dep.name === name &&
        (!dep.formula.includes(name) || inheritable.includes(name))
      );
    })
    .sort((a, b) => getFormulaPrio(b.formula) - getFormulaPrio(a.formula))
    .map((dep) => dep.formula);

export const getParentPropertyExpression = (
  name: keyof ElementQuantityRecord,
): string => `${GET_PARENT_VALUE_FN_NAME}("${name}")`;

export const autoFormulaToExpression = (formula: string[] = []): string => {
  return (
    formula
      .join(' ')
      .replaceAll('( ', '(')
      .replaceAll(' )', ')')
      .replaceAll(' ,', ',') || DEFAULT_QUANTITY_EXPRESSION
  );
};

const isClearAllowedOnCombination = (
  names: ElementQuantityName[],
  record: ElementQuantityRecord,
  variables: ExpressionVariables,
): boolean => {
  // All related properties must exist for something to be obsolete
  return names.every((name) => {
    const property = record[name];
    if (hasCount(property)) {
      return true;
    }
    // If fill > 100% we should count that as a set value (for something to be cleared)
    else if (name === 'fill' && hasFallbackCount(property)) {
      const count = property.fallbackCount;

      return (
        count.expression !== DEFAULT_QUANTITY_EXPRESSIONS.fill &&
        getNextResolvedValue(property, variables)
      );
    }
    return false;
  });
};

const findObsoleteQuantityCombination = (
  quantity: ElementQuantityRecord,
  variables: ExpressionVariables,
): ElementQuantityName[] | undefined =>
  getKeys(quantity)
    .map(
      (name) =>
        getRelevantAutoFormulas(quantity, name)
          .map((formula) => {
            const names = [name, ...getDependencies(formula)];

            if (isClearAllowedOnCombination(names, quantity, variables)) {
              return names.filter((n) => hasCount(quantity[n])); // Don't include fallbackCounts in clear list
            }
          })
          .filter(isDefined)[0],
    )
    .filter(isDefined)[0];

const findForbiddenQuantityCombination = (
  quantity: ElementQuantityRecord,
): ElementQuantityName[] => {
  const spacing = quantity.repeating_item_spacing;
  const thickness = quantity.repeating_item_thickness;
  if (
    hasCount(spacing) &&
    hasCount(thickness) &&
    spacing.count.resolved < thickness.count.resolved
  ) {
    return ['repeating_item_spacing', 'repeating_item_thickness'];
  }
  return [];
};

/**
 * Will clear obsolete quantities.
 * Sample: if user inputs length, width and height,
 * then volume is obsolete and can use auto/fallback value instead
 * @param quantity
 * @returns
 */
export const autoClearObsoleteQuantities = (
  quantity: ElementQuantityRecord,
  variables: ExpressionVariables = defaultVariables,
): ElementQuantityRecord => {
  const obsoleteCombo = findObsoleteQuantityCombination(quantity, variables);
  const forbiddenCombo = findForbiddenQuantityCombination(quantity);

  if (!obsoleteCombo?.length && !forbiddenCombo?.length) {
    return quantity;
  }

  const properties = sortQuantityPropertiesByUpdatedAt(quantity);
  const oldestProperty = properties.find(
    ({ name }) =>
      forbiddenCombo.includes(name) || obsoleteCombo?.includes(name),
  );

  if (!oldestProperty) {
    throw new Error('Could not find oldest property');
  }

  // Remove count from oldest property
  const updatedQuantity = applyChanges(quantity, {
    [oldestProperty.name]: {
      count: undefined,
      fallbackCount: createExpression(
        getDefaultFallbackExpression(oldestProperty),
      ),
    },
  });

  // Make it recursive to clear all obsolete properties
  return autoClearObsoleteQuantities(updatedQuantity, variables);
};

/**
 * If a quantity record has circular dependencies we need to reset any fallback counts
 * @param quantity
 * @returns
 */
export const clearCircularFallbackCounts = (
  quantity: ElementQuantityExpressionRecord,
): ElementQuantityExpressionRecord => {
  if (hasCircularDependencies(Object.values(quantity))) {
    return Object.values(quantity).reduce(
      (acc, prop) => ({
        ...acc,
        [prop.name]: {
          ...prop,
          fallbackCount: createExpression(getDefaultFallbackExpression(prop)),
        },
      }),
      {} as ElementQuantityExpressionRecord,
    );
  }
  return quantity;
};

const sortQuantityPropertiesByUpdatedAt = (
  quantities: ElementQuantityExpressionRecord,
): IElementQuantityExpressionProperty[] => {
  const oldestDate = '2022-05-22T10:33:14.183Z';
  return Object.values(quantities).sort((a, b) => {
    const dateA = a.updated_at ?? oldestDate;
    const dateB = b.updated_at ?? oldestDate;

    if (dateA === dateB) {
      return 0;
    }

    return dateA < dateB ? -1 : 1;
  });
};

/**
 * Get a valid formula part to use in an expression. (by looping all FormulaParts)
 * @param propertyName Name of the property we currently are trying to fallback
 * @param part Part of a formula (like length, width, height, +, -, *, /)
 * @param quantityRecord
 * @param inheritableQuantityNames Which properties that can be inherited from parents
 * @returns string formula part or undefined if it is not valid
 */
const getFormulaPart = (
  propertyName: keyof ElementQuantityExpressionRecord,
  part: FormulaPart,
  quantityRecord: ElementQuantityRecord,
  inheritableQuantityNames: ElementQuantityName[],
  variables: ExpressionVariables,
): string | undefined => {
  // Checks if it is an operator (like '*') or quantity prop, return if an operator
  if (!isElementQuantityName(part)) {
    return part;
  }

  const partQuantity = quantityRecord[part];

  // Count refer to that property directly. Also make sure to avoid 0 divisions
  if (
    hasCount(partQuantity) &&
    partQuantity.count.expression !== DEFAULT_QUANTITY_EXPRESSION
  ) {
    return part;
  }

  // Use parent value if possible (return something like `getParentValue("length")` )
  if (inheritableQuantityNames.includes(part)) {
    return getParentPropertyExpression(part);
  }

  // For fill and density we can use the fallback count if it is not the default
  if (
    isAllowedFallbackReference(propertyName, quantityRecord, part, variables)
  ) {
    return part;
  }

  // If it doesn't return anything this formula is not valid and will be ignored
};

/**
 * Check if the forumla or string contains a reference to a parent property
 * @param parts
 * @returns
 */
const hasParentReference = (...parts: string[]): boolean => {
  return parts.some((part) => getParentReferenceCount(part) > 0);
};

/**
 * Get how many parent references that are used in a formula
 * @param parts
 * @returns
 */
const getParentReferenceCount = (expression: string): number =>
  countOccurrences(expression, GET_PARENT_VALUE_FN_NAME);

export const getNonParentReferenceCount = (expression: string): number =>
  getVariablesInExpression(expression).filter(isElementQuantityName).length;

/**
 * Get a value between 0 and 1 that represents how much a formula is using parent reference.
 * (0 = no parent reference, 1 = only parent references)
 * @param expression
 * @returns
 */
export const getParentReferenceRatio = (expression: string): number => {
  const parentReferenceCount = getParentReferenceCount(expression);
  const nonParentReferenceCount = getNonParentReferenceCount(expression);

  if (parentReferenceCount === 0) {
    return 0;
  }

  return (
    parentReferenceCount / (parentReferenceCount + nonParentReferenceCount)
  );
};

/**
 * Get a calculated fallbackCount based on inheritance
 * and available count values (user input)
 * @param prop
 * @param quantity
 * @param inheritable
 * @returns
 */
const getAutoFallbackCount = (
  prop: IElementQuantityExpressionProperty,
  quantity: ElementQuantityRecord,
  inheritable: ElementQuantityName[],
  variables: ExpressionVariables,
): ExpressionValue | undefined => {
  if (!isAllowedToUseInheritedQuantities(prop.name)) {
    inheritable = [];
  }

  // Keep resolved values
  const createFallbackCountIfModified = (
    expression: string,
  ): ExpressionValue => {
    const fallbackCount = prop.fallbackCount;
    return fallbackCount?.expression === expression
      ? fallbackCount
      : createExpression(expression);
  };

  // Non quantity properties can not have auto fallback count
  if (!isElementQuantityExpressionProperty(prop)) {
    return undefined;
  }

  // Count is set, do not use fallbackCount
  if (hasCount(prop)) {
    return createFallbackCountIfModified(getDefaultFallbackExpression(prop));
  }

  // All formulas that are relevant for this quantity property
  const relevantFormulas = getRelevantAutoFormulas(
    quantity,
    prop.name,
    inheritable,
  );

  // Property can be directly inherited from parents
  const matchedExpressions: string[] = [];

  for (const formula of relevantFormulas) {
    const formulaParts = formula.map((formulaPart) =>
      getFormulaPart(prop.name, formulaPart, quantity, inheritable, variables),
    );

    // All variables in formula can be referenced
    if (formulaParts.every(isDefined)) {
      const expression = autoFormulaToExpression(formulaParts);

      // Save computing by return the first "perfect match"
      if (
        !hasParentReference(expression) &&
        isValidFallbackCountExpression(quantity, prop.name, expression)
      ) {
        return createFallbackCountIfModified(expression);
      }
      matchedExpressions.push(expression);
    }
  }

  // Only matches with parent references so we need to use them anyway
  if (matchedExpressions.length) {
    // Get first expression that does not cause circular dependencies
    const match = matchedExpressions
      .sort((a, b) => getParentReferenceRatio(a) - getParentReferenceRatio(b))
      .find((expression) =>
        isValidFallbackCountExpression(quantity, prop.name, expression),
      );

    return createFallbackCountIfModified(
      match ?? getDefaultFallbackExpression(prop),
    );
  }

  // Property can be directly inherited from parents (width = parent.width)
  if (inheritable.includes(prop.name)) {
    return createFallbackCountIfModified(
      getParentPropertyExpression(prop.name),
    );
  }

  // Return default fallback expression as auto value. For fill that would be '1'
  return createFallbackCountIfModified(getDefaultFallbackExpression(prop));
};

/**
 * Apply auto fallback counts to all quantity properties and return a new quantity record.
 * Will also clear redundant quantity properties.
 * @param quantity
 * @param inheritableQuantityNames List of all inheritable quantity names
 * @returns
 */
export const autoUpdateQuantityRecord = (
  clearedQuantity: ElementQuantityExpressionRecord,
  inheritableQuantityNames: ElementQuantityName[] = [],
  variables: ExpressionVariables = defaultVariables,
): ElementQuantityRecord => {
  const sortedProperties = Object.values(clearedQuantity).sort(
    (a, b) => getAutoResolvePriority(b) - getAutoResolvePriority(a),
  );

  const updates = sortedProperties.reduce(
    (acc, prop) => ({
      ...acc,
      [prop.name]: {
        ...prop,
        fallbackCount: getAutoFallbackCount(
          prop,
          { ...clearedQuantity, ...acc },
          inheritableQuantityNames,
          variables,
        ),
      },
    }),
    {} as ElementQuantityExpressionRecord,
  );

  return applyChanges(clearedQuantity, updates);
};

/**
 * Check if a property can use inherited values from parent
 * @param name
 * @returns
 */
const isAllowedToUseInheritedQuantities = (
  name: ElementQuantityExpressionName,
): boolean => !FORBIDDEN_INHERITANCE.includes(name);

/**
 * Get a list of all available inheritable quantity names from parents
 * @param path List of parent elements (from root to nearest parent)
 * @returns
 */
export const getInheritableQuantities = (
  element: IElement,
  path: OneOfParentElements[] = [],
): (keyof ElementQuantityExpressionRecord)[] => {
  const inheritableFromParents = path.reduce(
    (acc, element) => {
      const quantity = getElementQuantityExpressionRecord(element);
      const quantityNames = getKeys(quantity).filter(
        (name) => !acc.includes(name),
      );
      const inheritableQuantityNames = quantityNames.filter(
        (name) =>
          INHERITABLE_QUANTITY_NAMES.includes(name) &&
          (!(
            quantity[name]?.fallbackCount.expression === '0' &&
            isDefaultFallbackCount(quantity[name])
          ) ||
            hasCount(quantity[name])),
      );
      return [...acc, ...inheritableQuantityNames];
    },
    [] as (keyof ElementQuantityExpressionRecord)[],
  );

  return removeForbiddenInheritableQuantities(element, inheritableFromParents);
};
/**
 * Remove quantitities
 * @param element
 * @param inheritable
 * @returns
 */
const removeForbiddenInheritableQuantities = (
  element: IElement,
  inheritable: (keyof ElementQuantityExpressionRecord)[],
): (keyof ElementQuantityExpressionRecord)[] => {
  const quantity = getElementQuantityExpressionRecord(element);

  // Make copy with the inheritable quantities that are not set by the user
  const filteredInheritable = inheritable.filter(
    (name) => !hasCount(quantity[name]),
  );

  // If all dimensions can be inherited from parents and volume or mass
  // (mass can be used to calculate volume) is set we need clear inheritable to not create paradoxes
  if (
    (hasCount(quantity['volume']) || hasCount(quantity['mass'])) &&
    includesAll(inheritable, ...dimensionQuantityNames)
  ) {
    const first = filteredInheritable.find((name) =>
      dimensionQuantityNames.includes(name as DimensionQuantityName),
    );

    return first
      ? cloneRemoveItems(filteredInheritable, first, ...areaQuantityNames)
      : cloneRemoveItems(filteredInheritable, ...areaQuantityNames);
  }

  return filteredInheritable;
};

/**
 * Update quantity properties for an element including all of its children.
 * This will clear redundant quantity properties and add auto fallbackCounts where applicable.
 * @param element
 * @param path Search path to element, used to calculate inheritance
 * @returns
 */
export const autoUpdateElementQuantity = (
  element: IElement,
  path: OneOfParentElements[] = [],
  variablesRecord: ExpressionVariablesRecord = {},
): IElement => {
  const quantity = getElementQuantityExpressionRecord(element);
  if (!Object.keys(quantity).length) {
    return element;
  }
  const inheritableQuantityNames = getInheritableQuantities(element, path);

  // Remove any obsolete property.counts (like volume if length, width and height is set most recently).
  // Also clear all fallbacks on circular error
  const clearedQuantity = clearCircularFallbackCounts(
    autoClearObsoleteQuantities(quantity, variablesRecord[element.id]),
  );

  const elementVariables = variablesRecord[element.id];

  if (
    elementVariables &&
    !shouldAutoUpdateQuantity(
      { ...element, quantity: clearedQuantity },
      inheritableQuantityNames,
      elementVariables,
    )
  ) {
    return element;
  }

  return applyChanges(element, {
    quantity: autoUpdateQuantityRecord(
      clearedQuantity,
      inheritableQuantityNames,
      variablesRecord[element.id],
    ),
  });
};

const shouldAutoUpdateCacheRecord: Record<
  IElementID,
  {
    inheritable: ElementQuantityName[];
    definedProperties: string[];
  }
> = {};

/**
 * Check if we should do the very expensive auto-update or not
 * @param element
 * @param inheritable
 * @returns
 */
const shouldAutoUpdateQuantity = (
  element: IElement,
  inheritable: ElementQuantityName[],
  variables: ExpressionVariables,
): boolean => {
  const prev = shouldAutoUpdateCacheRecord[element.id] || {
    inheritable: [],
    definedProperties: [],
  };
  const record = getElementQuantityRecord(element);

  const definedProperties = [
    ...getDefinedQuantityNamesWithResolvedCount(record),
    ...getElementProperties(element).map((prop) => {
      return prop.name + ':' + getElementPropertyResolvedCount(prop);
    }),
  ];

  updateDensity(record, variables, definedProperties, 'density');
  updateDensity(record, variables, definedProperties, 'density_areal_side');
  updateDensity(record, variables, definedProperties, 'density_areal_top');
  shouldAutoUpdateCacheRecord[element.id] = { inheritable, definedProperties };

  return (
    !shallowEqual(prev.inheritable, inheritable) ||
    !shallowEqual(prev.definedProperties, definedProperties)
  );
};

const updateDensity = (
  record: ElementQuantityRecord,
  variables: ExpressionVariables,
  definedProperties: string[],
  propertyName: 'density' | 'density_areal_side' | 'density_areal_top',
) => {
  const density = record[propertyName];
  const densityValue =
    density && !hasCount(density)
      ? getNextResolvedValue(density, variables)
      : 0;

  // If products are changed we need to update and in this case densityValue would go from 0 to a larger value
  if (densityValue !== 0) {
    definedProperties.unshift(propertyName);
  }
};

/**
 * If auto fallback is defined for a quantity property
 * @param quantity
 * @returns
 */
const isDefaultFallbackCount = (
  quantity?: IElementQuantityExpressionProperty,
): ReturnType<typeof hasFallbackCount> =>
  hasFallbackCount(quantity) &&
  quantity.fallbackCount.expression === getDefaultFallbackExpression(quantity);

/**
 * Get the default fallback expression for a certain quantity property.
 * Note that different categories might have different default fallback expressions
 * for the same property (like insulation => density)
 * @param prop
 * @returns
 */
export const getDefaultFallbackExpression = (
  prop: IElementQuantityExpressionProperty,
): string => {
  const category_id = String(prop.category_id);
  const defaultByName = getDefaultFallbackExpressionByName(prop.name);

  if (!category_id) {
    return prop.fallbackCount?.expression ?? defaultByName;
  }

  const category = getElementCategory(prop);
  const quantity = createElementQuantityRecord(
    category?.getQuantityProperties?.(),
  );

  // Some categories might have a custom fallback expression for certain quantity properties
  return quantity?.[prop.name]?.fallbackCount?.expression ?? defaultByName;
};

/**
 * Check if a fallback reference is allowed for a certain quantity property
 * @param propertyName
 * @param quantity
 * @param part
 * @returns
 */
const isAllowedFallbackReference = (
  propertyName: ElementQuantityName,
  quantity: ElementQuantityRecord,
  part: ElementQuantityName,
  variables: ExpressionVariables,
): boolean => {
  const quantityPart = quantity[part];
  const fallbackCount = quantityPart?.fallbackCount;
  const expression = (fallbackCount as ExpressionValue)?.expression;
  if (
    ALLOW_FALLBACK_REFERENCES.includes(part) &&
    hasFallbackCount(quantityPart) &&
    expression &&
    expression !== DEFAULT_QUANTITY_EXPRESSION
  ) {
    const expressionVars = getVariablesInExpression(expression);

    // Allow fallback reference if it is not referencing itself
    if (expressionVars.includes(propertyName)) {
      return false;
    }

    // Do not refer to 0 values since that would break divisions
    const value = getNextResolvedValue(quantityPart, variables);

    return value !== 0;
  }
  return false;
};

/**
 * Get the NEXT resolved value for a quantity property.
 * When calculating auto fallback counts we have the previous resolved values
 * since they have not yet been updated
 * @param prop
 * @param variables
 * @returns
 */
const getNextResolvedValue = (
  prop: IElementQuantityExpressionProperty,
  variables: ExpressionVariables,
): number => {
  const count = getCount(prop);
  const expression = count.expression;

  if (isNumeric(expression)) {
    return Number(expression);
  }

  // Cache this since it's heavy and might run many times
  return cacheFactory(
    () => errorHandledSolve(count, variables).resolved,
    `getNextResolvedValue[${String(prop.id ?? '')}]`,
    [expression, variables],
  );
};

/**
 * Test if fallback expression is valid for a certain quantity property. Currently only test for circular dependencies
 * @param record
 * @param name
 * @param expression
 * @returns
 */
const isValidFallbackCountExpression = (
  record: ElementQuantityExpressionRecord,
  name: keyof ElementQuantityExpressionRecord,
  expression: string,
): boolean =>
  !hasCircularDependencies({
    ...record,
    [name]: {
      ...record[name],
      fallbackCount: createExpression(expression),
    },
  });

/**
 * Get all quantity names that are defined (has count) along with their count value.
 * GetKeys guarantees that the order is alphabetical
 * @param quantity
 * @returns
 */
const getDefinedQuantityNamesWithResolvedCount = (
  quantity: ElementQuantityRecord,
): string[] =>
  getKeys(quantity)
    .filter((name) => hasCount(quantity[name]))
    .map((name) => {
      const formatted = (quantity[name] as IElementQuantityExpressionProperty)
        ?.count?.formatted;
      return quantity[name]?.type === ElementPropertyType.Expression
        ? `${name}:${formatted ?? ''}`
        : `${name}:${String(quantity[name]?.count ?? '')}`;
    });

/**
 * Return priority for auto resolve (highest prio first)
 * @param prop
 * @returns
 */
const getAutoResolvePriority = (
  prop: IElementQuantityExpressionProperty,
): number => {
  if (hasCount(prop)) {
    return 1;
  }
  if (
    hasFallbackCount(prop) &&
    prop.fallbackCount.expression !== getDefaultFallbackExpression(prop)
  ) {
    return 2;
  }
  return 0;
};
