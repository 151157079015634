import { Box, Typography, BoxProps } from '@mui/material';
import React from 'react';
import { useUIState } from '../store/ui';

export interface EmptyStateProps extends BoxProps {
  message: string;
  fullHeight?: boolean;
  heightOffset?: number;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  message,
  heightOffset = DEFAULT_HEIGHT_OFFSET,
  fullHeight = false,
  ...props
}) => {
  const { appHeaderOffset } = useUIState('appHeaderOffset');
  const height = fullHeight
    ? `calc(100vh - ${appHeaderOffset + heightOffset}px)`
    : 'auto';

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={height}
      {...props}
    >
      <Typography variant="h6" fontWeight={fullHeight ? 600 : 400}>
        {message}
      </Typography>
    </Box>
  );
};

/**
 * Default height offset is height of MainCategoriesOverview + ListHeader + Proposals + ProjectViewHeader
 */
const DEFAULT_HEIGHT_OFFSET = 200 + 3 * 50;

export default EmptyState;
