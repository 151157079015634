import { HEAT_PUMP_EFFECT_RATIO } from '../../../helpers/energy/energy.models';
import { required } from '../../../helpers/function_helpers';
import { toPercent } from '../../../helpers/string_helpers';
import { IElementCategory } from '../../../models/element_categories.interface';
import { ElementPropertyType } from '../../../models/element_property.interface';
import { ArrayItem } from '../../../models/type_helpers.interface';
import {
  boilerFuelProductsNameToIdMap,
  HEAT_PUMP_COP_DEFAULT_NAME,
  HeatingPropertyName,
  HeatingType,
  HeatSourceOptions,
  PEAK_EFFECT_EXPRESSION,
} from './heating.model';

/**
 * Properties specific to heat pumps
 */
const heatPumpProperties: ArrayItem<IElementCategory['properties']> = {
  conditions: {
    [HeatingPropertyName.Type]: HeatingType.HeatPump,
  },
  nodes: [
    {
      name: HeatingPropertyName.HeatSource,
      type: ElementPropertyType.Select,
      options: Object.values(HeatSourceOptions),
    },
    {
      name: HeatingPropertyName.HeatPumpEffectPercent,
      type: ElementPropertyType.Expression,
      unit: '%',
      min: 0,
      max: 1,
      fallbackCount: `${toPercent(HEAT_PUMP_EFFECT_RATIO.effect)}`,
      description:
        'How much of the peak heating demand should be covered by the heat pump. The rest will be covered by auxiliary heating. Increasing this value would typically decrease COP.',
    },
    {
      name: HeatingPropertyName.COP,
      type: ElementPropertyType.Expression,
      unit: 'none',
      fallbackCount: `${HEAT_PUMP_COP_DEFAULT_NAME}[${HeatingPropertyName.HeatSource}]`, // Typically between 2 and 5
      description: 'Coefficient of Performance',
    },
  ],
};

const boilerFuelNames = Object.keys(boilerFuelProductsNameToIdMap);

/**
 * Properties specific to boilers
 */
const boilerProperties: ArrayItem<IElementCategory['properties']> = {
  conditions: {
    [HeatingPropertyName.Type]: HeatingType.Boiler,
  },
  nodes: [
    {
      name: HeatingPropertyName.FuelType,
      type: ElementPropertyType.Select,
      count: required(boilerFuelNames[0]),
      options: boilerFuelNames,
    },
  ],
};

export const heatingProperties: IElementCategory['properties'] = [
  {
    name: HeatingPropertyName.Type,
    type: ElementPropertyType.Select,
    options: Object.values(HeatingType),
  },
  {
    name: HeatingPropertyName.PeakEffect,
    type: ElementPropertyType.Expression,
    unit: 'kW',
    fallbackCount: PEAK_EFFECT_EXPRESSION,
    description: `The peak effect is the required heat effect of the heating system to cover the peak heating demand during the coldest days of the year. 
        This is automatically calculated based on the energy demand and the building location.`,
  },
  heatPumpProperties,
  boilerProperties,

  // {
  //   name: HeatingPropertyName.DistributionSystem,
  //   type: ElementPropertyType.Select,
  //   options: Object.values(DistributionSystemOptions),
  // },
];
