import React from 'react';
import { Results } from '../../../../../../shared/models/unit.interface';
import {
  useProjectListMaxResults,
  useProjectListGFAScale,
  useProjectCostIsDisabled,
  useProjectsMaxTarget,
} from '../../../../store/project';
import ResultsBarCharts from '../../../charts/ResultsBarCharts';
import { PROJECT_CO2_BAR_HEIGHT } from '../../list.constants';
import {
  useCo2eChartData,
  useCostChartData,
  useFilteredResultsBySelectedLifecycles,
  useResultsPerGFA,
} from '../../../../hooks/results.hook';
import { getConversionFactorValue } from '../../../../../../shared/helpers/conversion-factors.helpers';
import { ResultsTooltip } from '../../../ResultsTooltip';
import TargetIndicator from '../../../charts/TargetIndicator';

interface ProjectListItemBarChartsProps {
  projectId: string;
  gfa: number;
  results?: Results;
}

const ProjectListItemBarCharts: React.FC<ProjectListItemBarChartsProps> = ({
  projectId,
  gfa,
  results,
}) => {
  const filteredResults = useFilteredResultsBySelectedLifecycles(results);
  const maxResults = useProjectListMaxResults();
  const maxTarget = useProjectsMaxTarget();
  const gfaScale = useProjectListGFAScale(gfa);
  const disableCost = useProjectCostIsDisabled();
  const resultsPerGFA = useResultsPerGFA(filteredResults, gfa);
  const co2ePerGfaMax = Math.max(
    getConversionFactorValue(maxResults, 'co2e'),
    maxTarget,
  );

  const costPerGfaMax = getConversionFactorValue(maxResults, 'sek');

  const co2ePerGFAData = useCo2eChartData(resultsPerGFA);
  const costPerGFAData = useCostChartData(resultsPerGFA);

  return (
    <>
      <TargetIndicator maxValue={co2ePerGfaMax} projectId={projectId} />
      <ResultsTooltip results={resultsPerGFA} perUnit="GFA">
        <ResultsBarCharts
          disableCost={disableCost}
          co2e={co2ePerGFAData}
          co2eMax={co2ePerGfaMax}
          cost={costPerGFAData}
          costMax={costPerGfaMax}
          co2eHeight={gfaScale * PROJECT_CO2_BAR_HEIGHT}
        />
      </ResultsTooltip>
    </>
  );
};

export default ProjectListItemBarCharts;
