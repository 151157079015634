import React from 'react';
import { Box } from '@mui/material';
import { IElement } from '../../../../../shared/models/project.interface';
import ElementChildren from './ElementChildren/ElementChildren';
import ElementQuantities from './ElementQuantities';
import ElementComments from './ElementComments';
import ResultPanel from './ElementChildren/ResultPanel';
import ElementCategorySettings from './ElementCategory/ElementCategorySettings';
import { useProjectCostIsDisabled } from '../../../store/project';
import RecipePanel from './Recipe/RecipePanel';
import ElementPropertyPanel from './ElementPropertyPanel';
import {
  isProductCategoryElement,
  isServiceCategoryElement,
} from '../../../../../shared/helpers/element_category_helpers';
import { isGenerated } from '../../../../../shared/helpers/element_helpers';

interface ElementProps {
  selectedElement: IElement;
}

const Element: React.FC<ElementProps> = ({ selectedElement }) => {
  const disableCost = useProjectCostIsDisabled();
  const disabled = isGenerated(selectedElement);

  const showRecipePanel =
    !isProductCategoryElement(selectedElement) &&
    !isServiceCategoryElement(selectedElement);

  if (!selectedElement) {
    return null;
  }

  return (
    <Box p={2} mb={25} sx={disabled ? styles.disabled : undefined}>
      <ElementCategorySettings element={selectedElement} pb={2} />

      <ElementQuantities element={selectedElement} />

      {showRecipePanel && (
        <>
          <RecipePanel element={selectedElement} />
          <ElementPropertyPanel element={selectedElement} />
        </>
      )}

      <ElementChildren element={selectedElement} />

      <ResultPanel factor="co2e" element={selectedElement} />
      {!disableCost && <ResultPanel factor="sek" element={selectedElement} />}

      <ElementComments elementId={selectedElement.id} />
    </Box>
  );
};

const styles = {
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
};

export default Element;
