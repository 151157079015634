import React, {
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  InputAdornment,
  InputProps,
  StandardTextFieldProps,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AttachMoney, Delete, LocalShipping } from '@mui/icons-material';
import NumberInput from './NumberInput';
import {
  getCombinedConversionFactors,
  getConversionFactorUnit,
} from '../../../shared/helpers/results.helpers';
import amplitudeLog from '../amplitude';
import { useProductsLookup } from '../store/product';
import { ConversionFactorUnit } from '../../../shared/models/unit.interface';
import { useUpdateProductInVersion } from '../hooks/useProducts';
import { useIsReadonly } from '../hooks/user.hook';
import {
  getConversionFactorValue,
  setConversionFactorValueOnProduct,
  Co2eInputUnits,
  co2eInputUnitToConversionFactorUnit,
  mergeConversionFactors,
} from '../../../shared/helpers/conversion-factors.helpers';
import { IProductElement } from '../../../shared/models/project.interface';
import { getEpdProduct } from '../../../shared/helpers/product_helpers';
import { getGenericProduct } from '../../../shared/helpers/product_helpers';
import { useSelectedVersionProducts } from '../store/ui';
import { formatThousands } from '../../../shared/helpers/math_helpers';
import { omit } from '../../../shared/helpers/object_helpers';

type EditableFactors =
  | Co2eInputUnits
  | Extract<ConversionFactorUnit, 'sek_A1-A3'>;

const MIN_VALUE = 0;
const MAX_VALUES: Record<EditableFactors, number> = {
  co2e_transport: 1000000000,
  co2e_waste: 1,
  co2e_waste_percent: 100,
  'sek_A1-A3': 1000000000,
};

interface IConversionFactorInput
  extends Omit<StandardTextFieldProps, 'onChange' | 'value' | 'variant'>,
    Pick<IProductElement, 'product_id' | 'generic_product_id'> {
  factor: EditableFactors;
  label: string;
}

const ConversionFactorInput: FC<IConversionFactorInput> = ({
  factor,
  label,
  product_id,
  generic_product_id,
}) => {
  const unit = co2eInputUnitToConversionFactorUnit(factor);
  const updateProductInVersion = useUpdateProductInVersion();
  const { classes } = useStyles();
  const readonly = useIsReadonly();

  const products = useSelectedVersionProducts();
  const originalProducts = useProductsLookup();

  const epd = getEpdProduct(products, { product_id, generic_product_id });
  const genericProduct = getGenericProduct(products, {
    product_id,
    generic_product_id,
  });

  const originalGenericProduct = getGenericProduct(originalProducts, {
    product_id,
    generic_product_id,
  });

  const editingProduct = epd ?? genericProduct;

  const combinedConversionFactors = useMemo(
    () => getCombinedConversionFactors(epd, genericProduct),
    [genericProduct, epd],
  );

  const fallbackProduct = epd ? genericProduct : originalGenericProduct;
  const fallbackConversionFactors = useMemo(() => {
    return mergeConversionFactors(
      fallbackProduct.conversion_factors,
      omit(editingProduct.conversion_factors, unit), // We need to exclude current value from factors
    );
  }, [
    editingProduct.conversion_factors,
    fallbackProduct.conversion_factors,
    unit,
  ]);
  const fallbackValue = getConversionFactorValue(
    fallbackConversionFactors,
    factor,
  );

  const [inputValue, setInputValue] = useState<number | string | undefined>();

  const reset = useCallback(() => {
    const epdFallback = epd && !epd.conversion_factors[unit];
    const genericFallback = !epd && !genericProduct.conversion_factors[unit];
    if (epdFallback || genericFallback) {
      setInputValue(undefined);
    } else {
      setInputValue(
        getConversionFactorValue(combinedConversionFactors, factor),
      );
    }
  }, [
    combinedConversionFactors,
    epd,
    factor,
    genericProduct.conversion_factors,
    unit,
  ]);

  const updateProductConversionFactorValue = useCallback(async () => {
    const updatedProduct = setConversionFactorValueOnProduct(
      factor,
      inputValue,
      {
        epd,
        generic: genericProduct,
        originalGeneric: originalGenericProduct,
      },
    );

    if (updatedProduct) {
      await updateProductInVersion(updatedProduct);
      amplitudeLog(`Product ${label} set`);
    }
  }, [
    factor,
    inputValue,
    epd,
    genericProduct,
    originalGenericProduct,
    updateProductInVersion,
    label,
  ]);
  const escapeBlurRef = useRef(false);
  const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    // don't set value if blur was triggered by escape key
    if (escapeBlurRef.current === false) updateProductConversionFactorValue();
    escapeBlurRef.current = false;
  }, [updateProductConversionFactorValue]);

  // Reset input value if inputed product changes
  useEffect(() => {
    reset();
  }, [editingProduct, reset, factor]);

  const handleExpressionKeyUp: KeyboardEventHandler<HTMLDivElement> =
    useCallback(
      (e) => {
        if (e.key === 'Escape') {
          // on escape we want to reset the value and blur the input
          // but we don't want to trigger updateProductConversionFactorValue in the blur event handler
          reset();
          if (e.target instanceof HTMLInputElement) {
            escapeBlurRef.current = true;
            e.target.blur();
          }
        } else if (e.key === 'Enter') {
          updateProductConversionFactorValue();
        }
      },
      [reset, updateProductConversionFactorValue],
    );

  const icon: React.ReactNode = useMemo(() => {
    switch (factor) {
      case 'co2e_transport':
        return <LocalShipping color="disabled" fontSize="small" />;
      case 'sek_A1-A3':
        return <AttachMoney color="disabled" fontSize="small" />;
      case 'co2e_waste':
      case 'co2e_waste_percent':
        return <Delete color="disabled" fontSize="small" />;
      default:
        throw new Error(`Unknown conversion factor: ${String(factor)}`);
    }
  }, [factor]);

  const invalidTransport =
    factor === 'co2e_transport' && !combinedConversionFactors.kg;

  const inputProps: Partial<InputProps> = useMemo(
    () => ({
      startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
      endAdornment: getConversionFactorUnit(factor, genericProduct),
      inputProps: {
        className: classes.quantityAdornment,
        min: MIN_VALUE,
        max: MAX_VALUES[factor],
      },
    }),
    [classes.quantityAdornment, factor, icon, genericProduct],
  );

  return (
    <Box>
      <Typography variant="subtitle2">{label}</Typography>
      <NumberInput
        variant="caption"
        size="small"
        value={inputValue}
        placeholder={formatThousands(fallbackValue)}
        onChange={setInputValue}
        onBlur={handleBlur}
        onKeyUp={handleExpressionKeyUp}
        InputProps={inputProps}
        fullWidth
        disabled={invalidTransport || readonly}
      />
      {invalidTransport && (
        <Typography variant="caption">
          Transport emissions needs product mass to be set
        </Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles()(({ spacing }) => ({
  quantityAdornment: {
    width: '100%',
    display: 'flex',
    textAlign: 'right',
    marginRight: spacing(1),
  },
}));

export default ConversionFactorInput;
