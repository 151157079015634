import { sortBy } from 'lodash';
import genericProducts from '../../../generic_products';
import {
  IElementCategory,
  ElementCategoryID,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyType,
  ElementPropertyName,
  IFactorySelectProperty,
} from '../../../models/element_property.interface';
import { createProductTreeProcessor } from '../processor';
import { moveLifecycleAResults } from '../../../helpers/conversion-factors.helpers';
import { getElementPropertyResolvedCountByNameOrId } from '../../../helpers/element_property_helpers';
import { IElement } from '../../../models/project.interface';
import { Lifecycle } from '../../../models/lifecycles.interface';
import { includesAll } from '../../../helpers/array_helpers';

export const energyProducts = genericProducts.filter(
  ({ categories }) => categories.Boverket?.['Energy and fuel'],
);

export const ENERGY_PROPERTY_PRODUCTS_NAME = 'products';
export const DEFAULT_ENERGY_PRODUCT_NAME = 'Electricity, Swedish mix';
export const DISTRICT_HEATING_PRODUCT_NAME =
  'District heating, Swedish average';

const sorted = sortBy(energyProducts, 'name');

if (
  !includesAll(
    sorted.map(({ name }) => name),
    DEFAULT_ENERGY_PRODUCT_NAME,
    DISTRICT_HEATING_PRODUCT_NAME,
  )
) {
  throw new Error('Default energy product name not fount in products');
}

const selectProperty: IFactorySelectProperty = {
  name: ENERGY_PROPERTY_PRODUCTS_NAME,
  type: ElementPropertyType.Select,
  count: DEFAULT_ENERGY_PRODUCT_NAME,
  options: sorted.map(({ name }) => name),
};

const processor = createProductTreeProcessor({
  levelProperties: [selectProperty],
  productTree: energyProducts.reduce(
    (acc, { id, name }) => ({ ...acc, [name]: id }),
    {},
  ),
});

export const isA5SbefCode = (element: IElement): boolean => {
  const sbef = getElementPropertyResolvedCountByNameOrId(
    element,
    ElementPropertyName.SBEFCode,
  );

  return (
    typeof sbef === 'string' && sbef.includes('A5.') && !sbef.includes('A5.1')
  );
};

/**
 * Move emission in A to B6 unless the sbef code is set to A5
 * @param results
 * @param context
 * @returns
 */
const mapResults: IElementCategory['mapResults'] = (results, context) => {
  const isA5Sbef = isA5SbefCode(context.categoryElement);
  return moveLifecycleAResults(results, isA5Sbef ? Lifecycle.A5 : Lifecycle.B6);
};

const getQuantityPropertyLabel: IElementCategory['getQuantityPropertyLabel'] = (
  property,
  element,
) =>
  property.name === 'energy' && !isA5SbefCode(element)
    ? 'Annual energy'
    : undefined;

export const energy: IElementCategory = {
  ...processor,

  id: ElementCategoryID.Energy,
  name: 'Energy',
  color: 'rgba(255, 197, 2, 1)',
  defaultSelectedQuantity: 'energy',
  excludeMutualProperties: [ElementPropertyName.Lifetime],
  getChildElements: (element) => {
    const elements = processor.getChildElements(element);

    // Make energy annual if it is not a A5 sbef
    if (!isA5SbefCode(element)) {
      return elements.map((el) => ({
        ...el,
        count: 'energy * building_lifetime',
      }));
    }

    return elements;
  },
  mapResults,
  getQuantityProperties: () => ({ energy: {} }),
  getQuantityPropertyLabel,
};
