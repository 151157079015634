import React, { useCallback, useEffect, useRef, useState } from 'react';
import NestedElementList from '../ElementList/NestedElementList';
import { Box, Button, ClickAwayListener } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';
import amplitudeLog from '../../amplitude';
import { useCreateProject, useProjects } from '../../store/project';
import { useUIState } from '../../store/ui';
import { useFolderState } from '../../store/folder';
import { v4 } from 'uuid';
import { getSelectedOrganization } from '../../store/organization';
import {
  ElementKind,
  OneOfElements,
  OneOfListElements,
  OneOfProjectListElements,
} from '../../../../shared/models/project.interface';
import ImportProjectButton from '../../projects/EditProject/Settingspage/ImportProjectButton';
import { useProjectSelectorDrop } from '../../hooks/droppable.hook';
import { setElementExpanded } from '../../hooks/expand-elements.hook';
import {
  getProjectFolderPath,
  isProjectInfo,
} from '../../../../shared/helpers/project-folder.helpers';
import { getTimestamps } from '../../../../shared/helpers/date.helpers';
import { useUserId } from '../../hooks/user.hook';
import { findFreeName } from '../../../../shared/helpers/string_helpers';
import {
  useInitProjectsSortFilter,
  useSortedProjects,
} from '../../hooks/filter-projects.hook';
import { useKeydownListener } from '../../hooks/events.hook';
import EmptyState from '../EmptyState';
import ProjectListHeader from '../ElementList/ListItems/ProjectListHeader';
import SearchProjects from '../filtering/SearchProjects';

interface ClickAwayEventTarget {
  localName?: string;
  className?: string;
  dataset?: { keepProjectSelectorOpen?: boolean };
  parentElement?: ClickAwayEventTarget;
}

const ProjectSelector: React.FC = () => {
  const { classes } = useStyles();

  const onProjectSelectorDrop = useProjectSelectorDrop();
  const createProject = useCreateProject();

  const {
    selectedProjectFolderId,
    appHeaderOffset,
    setShowProjectSelector,
    setAddedElementId,
  } = useUIState(
    'selectedProjectFolderId',
    'setShowProjectSelector',
    'appHeaderOffset',
    'setAddedElementId',
  );

  const { folders, fetchFolders, createFolder } = useFolderState(
    'folders',
    'fetchFolders',
    'createFolder',
  );
  const projectInfos = useProjects();
  const sortedList = useSortedProjects();
  const userId = useUserId();
  const divContainerRef = useRef<HTMLDivElement>(null);

  const [shouldFilter, setShouldFilter] = useState(false);

  const disableFilter = useCallback(() => setShouldFilter(false), []);
  const enableFilter = useCallback(() => setShouldFilter(true), []);

  const expandParentFolders = useCallback(
    (id: string) => {
      const folderIds = getProjectFolderPath(folders, id).map((f) => f.id);
      folderIds.forEach((id) => setElementExpanded(id, true, true));
    },
    [folders],
  );

  const handleNewFolderClick = useCallback(() => {
    const organization = getSelectedOrganization(true);

    const selectedProjectInfoOrFolder = [...folders, ...projectInfos].find(
      ({ id }) => id === selectedProjectFolderId,
    );

    const items = [...folders, ...projectInfos].filter(
      ({ parent_id }) =>
        (!selectedProjectInfoOrFolder && parent_id === null) ||
        parent_id ===
          (isProjectInfo(selectedProjectInfoOrFolder)
            ? selectedProjectInfoOrFolder?.parent_id
            : selectedProjectInfoOrFolder?.id),
    );

    createFolder({
      id: v4(),
      kind: ElementKind.ProjectFolder,
      organizations: [organization],
      location: items.length + 1,

      name: findFreeName(
        folders.map(({ name }) => name),
        'Folder 1',
      ),

      parent_id:
        (isProjectInfo(selectedProjectInfoOrFolder)
          ? selectedProjectInfoOrFolder?.parent_id
          : selectedProjectInfoOrFolder?.id) ?? null,

      ...getTimestamps(),
    }).then(({ parent_id, id }) => {
      setAddedElementId(id);

      // to place at correct location
      onProjectSelectorDrop(
        id,
        selectedProjectInfoOrFolder?.id ?? null,
        isProjectInfo(selectedProjectInfoOrFolder) ? 'below' : 'inside',
      );

      if (parent_id) {
        expandParentFolders(parent_id);
      }
      setElementExpanded(id, true, true);
    });
  }, [
    folders,
    projectInfos,
    createFolder,
    selectedProjectFolderId,
    setAddedElementId,
    onProjectSelectorDrop,
    expandParentFolders,
  ]);

  const handleNewProjectClick = useCallback(async () => {
    const selectedProjectInfoOrFolder = [...folders, ...projectInfos].find(
      ({ id }) => id === selectedProjectFolderId,
    );

    const projectPartial = {
      owner: userId,
      parent_id:
        (isProjectInfo(selectedProjectInfoOrFolder)
          ? selectedProjectInfoOrFolder?.parent_id
          : selectedProjectInfoOrFolder?.id) ?? null,
    };

    amplitudeLog('New Project clicked (in Project Selector)');
    const { parent_id } = await createProject(projectPartial);
    if (parent_id) {
      expandParentFolders(parent_id);
    }
  }, [
    folders,
    projectInfos,
    userId,
    createProject,
    selectedProjectFolderId,
    expandParentFolders,
  ]);

  const handleClickAway: (event: MouseEvent | TouchEvent) => void = useCallback(
    (e) => {
      let currentTarget = e.target as unknown as
        | ClickAwayEventTarget
        | undefined;
      let done = false;

      if (
        currentTarget?.dataset?.keepProjectSelectorOpen ||
        currentTarget?.localName === 'body' ||
        (typeof currentTarget?.parentElement?.className === 'string' &&
          currentTarget?.parentElement?.className?.includes('MuiDialog'))
      ) {
        return;
      }

      if (
        typeof currentTarget?.className === 'string' &&
        currentTarget?.className?.includes('adminOption')
      ) {
        setShowProjectSelector(false);
        return;
      }

      while (!done) {
        if (
          currentTarget?.dataset?.keepProjectSelectorOpen ||
          (currentTarget?.className === 'string' &&
            currentTarget?.className?.includes('MuiPopover'))
        ) {
          done = true;
        } else if (!currentTarget?.parentElement) {
          currentTarget = undefined;
          done = true;
        } else {
          currentTarget = currentTarget?.parentElement;
        }
      }

      if (done && !currentTarget) {
        setShowProjectSelector(false);
      }
    },
    [setShowProjectSelector],
  );

  const closeProjectSelector = useCallback(
    () => setShowProjectSelector(false),
    [setShowProjectSelector],
  );

  useEffect(() => {
    fetchFolders().catch(console.error);
  }, [fetchFolders]);

  useKeydownListener(closeProjectSelector, 'Escape');
  useInitProjectsSortFilter(shouldFilter ? userId : undefined);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        className={classes.container}
        ref={divContainerRef}
        sx={{ marginTop: appHeaderOffset + 'px' }}
      >
        <Box className={classes.header}>
          <Box
            display="flex"
            justifyContent="space-between"
            flex={1}
            padding={4}
          >
            <Box display="flex" gap={4}>
              <Button
                className={classes.filterButton}
                variant={shouldFilter ? 'text' : 'outlined'}
                onClick={disableFilter}
              >
                All projects
              </Button>
              <Button
                className={classes.filterButton}
                variant={shouldFilter ? 'outlined' : 'text'}
                onClick={enableFilter}
              >
                My projects
              </Button>
            </Box>

            <SearchProjects />

            <Box className={classes.projectButtons}>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={handleNewFolderClick}
                startIcon={<AddIcon />}
              >
                <FormattedMessage
                  id="projects_panel.new_folder"
                  defaultMessage="New folder"
                />
              </Button>

              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={handleNewProjectClick}
                startIcon={<AddIcon />}
              >
                <FormattedMessage
                  id="projects_panel.new_project"
                  defaultMessage="New project"
                />
              </Button>

              <ImportProjectButton />
            </Box>
          </Box>
        </Box>

        {sortedList.length > 0 ? (
          <Box className={classes.list}>
            <ProjectListHeader />
            <NestedElementList
              elements={sortedList}
              indentation={0}
              useFilterSortChildren={
                useSortedProjects as (
                  element?: OneOfListElements | undefined,
                ) => OneOfElements<OneOfProjectListElements>[]
              }
            />
          </Box>
        ) : (
          <EmptyState fullHeight heightOffset={0} message="No projects found" />
        )}
      </Box>
    </ClickAwayListener>
  );
};

const useStyles = makeStyles()(({ spacing, palette }) => ({
  container: {
    position: 'absolute',
    inset: 0,
    height: '100vh',
    zIndex: 1000,
    background: '#fff',
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    backgroundColor: palette.background.paper,
    width: '99%',
    zIndex: 2,
  },
  projectButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacing(4),
  },
  filterButton: {
    padding: spacing(2),
  },
  list: {
    paddingTop: 76,
    paddingBottom: 76,
  },
}));

export default ProjectSelector;
