import { IProduct } from '../../models/product.interface';
import { ICoordinate } from '../../models/project.interface';

/**
 * Stockholm, the capital, is often a good reference point for typical Swedish temperatures. It is located in the Mälaren Valley, which tends to have relatively mild summers and cold winters compared to the rest of the country.
 * In July, daytime highs in Stockholm typically range from 19°C to 24°C (66°F to 75°F), while in January, they range from −9°C to 3°C
 */
export const STOCKHOLM_COORDINATE: ICoordinate = {
  lat: 59.332581,
  lng: 18.064924,
};

/**
 * Air density in kg/m³
 */
export const AIR_DENSITY = 1.2; // kg / m³

/**
 * CP_AIR stands for "Specific Heat Capacity of Air" and is measured in kJ/(kg·K).
 * It represents the amount of energy required to raise the temperature of 1 kg of air by 1 Kelvin (or 1°C).
 * The value 1.006 kJ/(kg·K) is the standard value for air at normal atmospheric pressure and room temperature.
 */
export const CP_AIR = 1.006; // kJ/(kg·K)
export const DEFAULT_INDOOR_TEMP = 20; // °C

/**
 * The limit temperature is the temperature at which a building does not need any heating to maintain a comfortable indoor temperature. It is calculated by taking into account the building's heat losses and internal heat sources, such as solar radiation and heat from electrical appliances or people.
 */
export const DEFAULT_LIMIT_TEMP = 16; // °C

/**
 * The temperature at which a building need cooling to maintain a comfortable indoor temperature.
 * It is calculated by taking into account the building's heat losses and internal heat sources, such as solar radiation and heat from electrical appliances or people.
 */
export const TEMP_OUTSIDE_COOLING_HOURS = 25; // °C

export interface ICityClimateData extends ICoordinate {
  city: string;
  temp: number;
  sunHours: number;
  /**
     * "Design outdoor temperature (DUT)" på svenska är "Dimensionerande utetemperatur (DUT)" eller "Dimensionerande vinterutetemperatur (DVUT)".
  Detta är den lägsta utetemperatur som värmesystemet dimensioneras för. Den varierar beroende på var i Sverige fastigheten ligger och byggnadens tidskonstant (hur snabbt byggnaden reagerar på temperaturförändringar).
  Några exempel på DUT/DVUT för olika svenska städer (för en normal byggnad med 1 dygns tidskonstant):
     * Stockholm: cirka -15°C
     * Göteborg: cirka -12°C
     * Malmö: cirka -10°C
     * Kiruna: cirka -28°C
     * Boverket regarding DUT/DVUT: https://www.boverket.se/sv/PBL-kunskapsbanken/regler-om-byggande/boverkets-byggregler/termiskt-klimat/
     * List with DUT/DVUT values: https://www.boverket.se/sv/om-boverket/publicerat-av-boverket/oppna-data/dimensionerande-vinterutetemperatur-dvut-1981-2010/
     */
  dut: number;
}

export type EnergyClass = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';

// K = Kelvin
// R = Thermal resistance (K/W)
// U = Thermal transmittance (W/m²K)
// C = Therman conductance (K/W)

export enum CLIMATE_SHELL_TYPE {
  WALL = 1,
  ROOF = 2,
  FOUNDATION = 3,
}

export const CLIMATE_SHELL_TYPE_NAME = 'climate_shell_type';

export type IThermalProduct = IProduct & {
  /**
   * For repeating quantity, share is the share of the total space a layer occupies.
   */
  share: number;

  mass: number;
};

export interface IThermalLayer {
  name?: string;

  /**
   * Thickness in meters
   */
  thickness: number;

  /**
   * Products included in the layer (and their share of the layer)
   */
  products: IThermalProduct[];

  /**
   * U-value (W/m²K)
   */
  thermalTransmittance: number;

  /**
   * Area in m², area_side for wall, area_top for slab.
   */
  area: number;

  /**
   * Mass per m²
   */
  massPerArea: number;

  /**
   * m2 (J/m²/°C)
   */
  heatCapacity: number;

  heatEnergyPerArea: number;
}

export type HeatPumpAuxilaryEffectRatio = {
  effect: number;
  energy: number;
};

/**
 * For air to air they say that if you cover 70% of the peak effect with the heat pump that is 95% of the energy consumption.
 * Another case is 50% effect results in 90% of the energy consumption.
 */
export const HEAT_PUMP_EFFECT_RATIO: HeatPumpAuxilaryEffectRatio = {
  // effect: 0.7,
  // energy: 0.95,
  effect: 0.5,
  energy: 0.9,
};
