import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  Clear,
  Edit,
  Eject,
  FileCopy,
  MoreHoriz,
  AddCircle,
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ReplayIcon from '@mui/icons-material/Replay';
import { FormattedMessage } from 'react-intl';
import {
  createRecipeFromElement,
  isNodonRecipeID,
  overwriteRecipe,
} from '../../../../../../shared/helpers/recipe_helpers';
import { Recipe } from '../../../../../../shared/models/recipe.interface';
import {
  useOrganizations,
  useSelectedOrganization,
} from '../../../../store/organization';
import CloneRecipesDialog from '../CloneRecipesDialog';
import EditRecipeDialog from '../EditRecipeDialog';
import * as uuid from 'uuid';
import { IElement } from '../../../../../../shared/models/project.interface';
import amplitudeLog from '../../../../amplitude';
import {
  useAllRecipeElementsInProject,
  useApplyRecipe,
  useIsRecipeModified,
  useResetRecipe,
  useUpdateRecipeFromElement,
} from '../../../../hooks/useRecipes';
import { useBooleanState } from '../../../../hooks/hooks';
import UpdateRecipesDialog from '../UpdateRecipesDialog';
import {
  useCreateRecipe,
  useDeleteRecipe,
} from '../../../../store/recipe/recipe.hook';
import { useUpdateElements } from '../../../../store/project';
import { useIsConfirmed } from '../../../../hooks/confirm.hook';
import { usePromiseSnackbar } from '../../../../hooks/snackbar.hook';
import {
  useIsReadonlyElement,
  useUserIsAdmin,
} from '../../../../hooks/user.hook';
import { itemBelongsToTemplateOrganization } from '../../../../../../shared/helpers/organization_helpers';
import { TEMPLATE_ORGANIZATION } from '../../../../../../shared/constants';
import { hasChildren } from '../../../../../../shared/helpers/recursive_element_helpers';

interface RecipeActionMenuProps {
  element: IElement;
  recipe?: Recipe;
}

const RecipeActionMenu: FC<RecipeActionMenuProps> = ({ element, recipe }) => {
  const { classes, cx } = useStyles();
  const { user } = useAuth0();

  const applyRecipe = useApplyRecipe();
  const createRecipe = useCreateRecipe();
  const deleteRecipe = useDeleteRecipe();
  const resetRecipe = useResetRecipe();
  const updateElements = useUpdateElements();
  const updateRecipeFromElement = useUpdateRecipeFromElement();
  const confirm = useIsConfirmed();
  const promiseSnackbar = usePromiseSnackbar();

  const readonly = useIsReadonlyElement(element);
  const isRecipeModified = useIsRecipeModified(element);
  const loggedInUserIsAdmin = useUserIsAdmin();
  const organizations = useOrganizations();
  const elementsWithRecipe = useAllRecipeElementsInProject(recipe);
  const selectedOrganization = useSelectedOrganization();

  const [
    isOpenCloneRecipesDialogOpen,
    showCloneRecipesDialog,
    hideCloneRecipesDialog,
  ] = useBooleanState(false);

  const [updateDialogVisible, showUpdateDialog, hideUpdateDialog] =
    useBooleanState(false);

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [editingRecipe, setEditing] = useState<Recipe | undefined>(undefined);

  const isNodonRecipeProperty = useMemo(
    () => isNodonRecipeID(recipe?.id),
    [recipe?.id],
  );

  const isLibraryRecipe = itemBelongsToTemplateOrganization(recipe);

  const isReadonly = readonly || isNodonRecipeProperty;

  const modificationDisabled =
    selectedOrganization !== TEMPLATE_ORGANIZATION && isLibraryRecipe;

  const elementHasNoChildren = element.elements.length === 0;

  const onMenuClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const resetToRecipe = useCallback(() => {
    closeMenu();
    if (recipe) {
      resetRecipe(element, recipe);
    }
    amplitudeLog('Recipe Reset', {
      RecipeID: recipe?.id,
      ElementID: element.id,
    });
  }, [element, closeMenu, recipe, resetRecipe]);

  const handleRecipeDelete = useCallback(async () => {
    closeMenu();

    const confirmed = await confirm({
      title: 'Delete recipe?',
      description: 'This will delete the recipe. This action cannot be undone.',
      cancellationText: 'Cancel',
      confirmationText: 'Delete',
    });

    if (recipe && confirmed) {
      promiseSnackbar(
        deleteRecipe(recipe.id).then(() => {
          amplitudeLog('Recipe Delete', {
            RecipeID: recipe?.id,
            ElementID: element.id,
          });
          applyRecipe(element, 'detach');
        }),
        {
          successMessage: `Recipe "${recipe.name}" deleted`,
          errorMessage: `Recipe "${recipe.name}" deletion failed`,
        },
      ).catch((err) => console.error(err.name, err));
    }
  }, [
    closeMenu,
    confirm,
    recipe,
    promiseSnackbar,
    deleteRecipe,
    element,
    applyRecipe,
  ]);

  const beginRecipeEdit = useCallback(() => {
    setEditing(recipe);
    closeMenu();
    amplitudeLog('Recipe Edit', {
      RecipeID: recipe?.id,
      ElementID: element.id,
    });
  }, [element.id, closeMenu, recipe]);

  const endRecipeEdit = useCallback(() => {
    setEditing(undefined);
  }, []);

  const handleEditRecipe = useCallback(
    async (recipe: Recipe) => {
      endRecipeEdit();

      // Create new recipe
      if (!recipe.id) {
        const id = uuid.v4();
        const properties = recipe.properties.map((property) => ({
          ...property,
          id: uuid.v4(),
          recipe_id: id,
        }));

        const createdRecipe = await createRecipe({
          ...recipe,
          id,
          properties,
        });

        await applyRecipe(element, createdRecipe, false);
      }
      // Modify existing recipe
      else {
        await updateRecipeFromElement(recipe, element);
      }
    },
    [
      applyRecipe,
      createRecipe,
      element,
      endRecipeEdit,
      updateRecipeFromElement,
    ],
  );

  const handleAddRecipe = useCallback((): void => {
    setEditing(createRecipeFromElement(user?.sub || '', element));
    amplitudeLog('Recipe New', {
      ElementID: element.id,
    });
  }, [element, user?.sub]);

  const handleRecipeUpdate = useCallback(async () => {
    if (!recipe) {
      return;
    }
    /*
    Only show dialog if the recipe is applied to other elemets besides the selected element.
    */
    if (elementsWithRecipe.length > 1) {
      showUpdateDialog();
      return;
    }
    const updatedRecipe = await updateRecipeFromElement(recipe, element);

    await updateElements(overwriteRecipe(element, updatedRecipe));

    closeMenu();
    amplitudeLog('Recipe Save', {
      RecipeID: recipe?.id,
      ElementID: element.id,
    });
  }, [
    recipe,
    elementsWithRecipe.length,
    updateRecipeFromElement,
    element,
    closeMenu,
    showUpdateDialog,
    updateElements,
  ]);

  const handleRecipeClone = useCallback(() => {
    showCloneRecipesDialog();
    closeMenu();
    amplitudeLog('Recipe Clone', {
      RecipeID: recipe?.id,
      ElementID: element.id,
    });
  }, [element.id, closeMenu, recipe?.id, showCloneRecipesDialog]);

  const handleDetach = useCallback(async () => {
    await applyRecipe(element, 'detach');
    closeMenu();
    amplitudeLog('Recipe Detach', {
      RecipeID: recipe?.id,
      ElementID: element.id,
    });
  }, [applyRecipe, element, closeMenu, recipe?.id]);

  const handleClear = useCallback(async () => {
    await applyRecipe(element, 'none');
    closeMenu();
    amplitudeLog('Recipe Clear', {
      RecipeID: recipe?.id,
      ElementID: element.id,
    });
  }, [applyRecipe, element, closeMenu, recipe?.id]);

  const handleSaveRecipeAs = useCallback((): void => {
    // TODO Update at API
    setEditing(createRecipeFromElement(user?.sub || '', element));
    closeMenu();
    amplitudeLog('Recipe Save As', {
      RecipeID: recipe?.id,
      ElementID: element.id,
    });
  }, [element, closeMenu, recipe?.id, user?.sub]);

  useEffect(() => {
    // Close menu if edit dialog opens
    if (editingRecipe) {
      closeMenu();
    }
  }, [editingRecipe, closeMenu]);

  if (!user) {
    return null;
  }

  return (
    <>
      {recipe ? (
        /* KEBAB MENU BTN */
        <Button
          disabled={isReadonly}
          color="secondary"
          onClick={onMenuClick}
          className={classes.menuButton}
        >
          <MoreHoriz fontSize="small" />
        </Button>
      ) : (
        /*
        ADD RECIPE
        */
        hasChildren(element) && (
          <Button
            disabled={isReadonly}
            color="secondary"
            onClick={handleAddRecipe}
            className={classes.menuButton}
          >
            <AddIcon fontSize="small" />
          </Button>
        )
      )}
      {user?.sub && menuAnchor && (
        <Menu
          id="customized-menu"
          anchorEl={menuAnchor}
          keepMounted
          open={true}
          onClose={closeMenu}
        >
          {/* SAVE */}
          {isRecipeModified && (
            <MenuItem
              className={cx(elementHasNoChildren && classes.menuItemDisabled)}
              onClick={elementHasNoChildren ? undefined : handleRecipeUpdate}
              disabled={isReadonly || modificationDisabled}
            >
              <ListItemIcon>
                <SaveIcon color="secondary" fontSize="small" />
              </ListItemIcon>
              <Tooltip
                title={
                  elementHasNoChildren
                    ? 'Cannot save recipe with no children'
                    : ''
                }
                placement="top"
              >
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="element_recipe.save"
                      defaultMessage="Save"
                    />
                  }
                />
              </Tooltip>
            </MenuItem>
          )}

          {/* RESET */}
          {recipe && isRecipeModified && (
            <MenuItem onClick={resetToRecipe}>
              <ListItemIcon>
                <ReplayIcon color="secondary" fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="element_recipe.reset"
                    defaultMessage="Reset"
                  />
                }
              />
            </MenuItem>
          )}

          {/* SAVE AS */}
          <MenuItem
            className={cx(elementHasNoChildren && classes.menuItemDisabled)}
            onClick={elementHasNoChildren ? undefined : handleSaveRecipeAs}
            disabled={isNodonRecipeProperty}
          >
            <ListItemIcon>
              <AddCircle color="secondary" fontSize="small" />
            </ListItemIcon>
            <Tooltip
              title={
                elementHasNoChildren
                  ? 'Cannot save recipe with no children'
                  : ''
              }
              placement="top"
            >
              <ListItemText
                primary={
                  <FormattedMessage
                    id="element_recipe.save_as"
                    defaultMessage="Save as..."
                  />
                }
              />
            </Tooltip>
          </MenuItem>

          {isRecipeModified && <Divider />}

          {/* EDIT */}
          {recipe && (
            <MenuItem
              onClick={beginRecipeEdit}
              disabled={isReadonly || modificationDisabled}
            >
              <ListItemIcon>
                <Edit color="secondary" fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="element_recipe.edit"
                    defaultMessage="Edit..."
                  />
                }
              />
            </MenuItem>
          )}

          {/* DETACH */}
          <MenuItem onClick={handleDetach} disabled={isNodonRecipeProperty}>
            <ListItemIcon>
              <Eject color="secondary" fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  id="element_recipe.detach"
                  defaultMessage="Detach"
                />
              }
            />
          </MenuItem>

          {/* CLEAR */}
          <MenuItem onClick={handleClear}>
            <ListItemIcon>
              <Clear color="secondary" fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  id="element_recipe.clear"
                  defaultMessage="Clear"
                />
              }
            />
          </MenuItem>

          {/* DELETE */}
          <MenuItem
            onClick={handleRecipeDelete}
            disabled={isReadonly || modificationDisabled}
          >
            <ListItemIcon>
              <DeleteForeverIcon color="secondary" fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  id="element_recipe.delete"
                  defaultMessage="Delete"
                />
              }
            />
          </MenuItem>
          {loggedInUserIsAdmin && (
            /* CLONE */
            <MenuItem onClick={handleRecipeClone}>
              <ListItemIcon>
                <FileCopy color="secondary" fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="element_recipe.clone"
                    defaultMessage="Clone recipes"
                  />
                }
              />
            </MenuItem>
          )}
        </Menu>
      )}
      {editingRecipe && (
        <EditRecipeDialog
          element={element}
          editingRecipe={editingRecipe}
          selectedRecipe={recipe}
          elementCategoryId={element.category_id}
          onClose={endRecipeEdit}
          onConfirm={handleEditRecipe}
        />
      )}
      {isOpenCloneRecipesDialogOpen && (
        <CloneRecipesDialog
          element={element}
          open={true}
          organizations={organizations}
          onClose={hideCloneRecipesDialog}
        />
      )}
      {recipe && updateDialogVisible && (
        <UpdateRecipesDialog
          element={element}
          recipe={recipe}
          visible={true}
          hideDialog={hideUpdateDialog}
        />
      )}
    </>
  );
};

const useStyles = makeStyles()(({ spacing }) => ({
  menuButton: {
    minWidth: 0,
    padding: spacing(2),
  },
  menuItemDisabled: {
    opacity: 0.38,
    cursor: 'inherit',
    focus: 'inherit',
    animation: 'none',
    '&:hover': {
      background: 'inherit',
    },
  },
}));

export default RecipeActionMenu;
