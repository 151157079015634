import * as amplitude from '@amplitude/analytics-browser';

import { getBuildingGFA } from '../../shared/helpers/expression_variables_helpers';
import { getProject } from './store/project';

const { hostname } = window.location;

if (hostname !== 'localhost') {
  amplitude.init('bb165f5fa646cab846717cc4874e993a');
}

// Gather other properties to automatically send to amplitude as event properties
const getMetaProperties = (): Record<string, string | number> => {
  const metaProperties: Record<string, string | number> = {};

  const currentProject = getProject();
  if (currentProject) metaProperties.ProjectId = currentProject.id;

  const building = currentProject?.buildings[0];
  if (building) metaProperties.GFA = Math.round(getBuildingGFA(building.meta));

  return metaProperties;
};

/**
 * Log an event to amplitude.
 * Automatically adds meta properties (ProjectId and GFA) to the event properties.
 * Meta properties are overridden by event properties with the same key.
 * Meta properties are only added if the project is loaded.
 *
 * @param eventType - string identifier for the event, e.g. 'Organization Select', 'Session Start'
 * @param eventProperties - metadata for the event, e.g. { organization: 'Nodon AB' }
 */
const amplitudeLog = (
  // string identifier for the event, e.g. 'Organization Select', 'Session Start'
  eventType: string,
  // metadata for the event, e.g. { organization: 'Nodon AB' }
  eventProperties?: Record<string, unknown>,
): void => {
  const metaProperties = getMetaProperties();

  const finalProperties = { ...metaProperties, ...eventProperties };

  if (hostname === 'localhost') {
    return;
  }
  if (hostname === 'test.nodon.se') {
    console.info(
      '•',
      eventType,
      eventProperties ? JSON.stringify(finalProperties) : '',
    );
    return;
  }

  amplitude.track(eventType, finalProperties);
};

export default amplitudeLog;
