import React, { useMemo } from 'react';
import { IElement } from '../../../../../shared/models/project.interface';
import { Box, Typography } from '@mui/material';
import ElementProperties from './Property/ElementProperties';
import ElementPropertiesAddButton from './Property/ElementPropertiesAddButton';
import { useElementPropertiesUtils } from '../../../hooks/element-properties.hook';
import { useIsReadonlyElement } from '../../../hooks/user.hook';

interface ElementPropertyPanelProps {
  element: IElement;
}

const ElementPropertyPanel: React.FC<ElementPropertyPanelProps> = ({
  element,
}) => {
  const readonly = useIsReadonlyElement(element);
  const { addProperty, customProperties, recipeProperties } =
    useElementPropertiesUtils(element);

  const elementProperties = useMemo(
    () => [...customProperties, ...recipeProperties],
    [customProperties, recipeProperties],
  );

  return (
    <>
      <Box
        pt={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle2">Properties</Typography>

        {!readonly && (
          <ElementPropertiesAddButton onAddProperty={addProperty} />
        )}
      </Box>

      <ElementProperties element={element} properties={elementProperties} />
    </>
  );
};

export default ElementPropertyPanel;
