import { IElement, OneOfPropertyElements } from '../models/project.interface';
import * as uuid from 'uuid';
import { omitUndefined } from './object_helpers';
import { createExpression } from './expression_factory_helpers';
import {
  CreatedElementProperty,
  ElementPropertyType,
  ElementPropertyTypeMap,
  ElementPropertySource,
  ElementPropertySourceID,
  FactoryPropertyInputTypeMap,
  IElementExpressionProperty,
  IElementProperty,
  IElementPropertyFactoryOption,
  IElementPropertyOption,
  IElementPropertySourceIdProperties,
  IElementSelectProperty,
  IElementSwitchProperty,
  IFactoryExpressionProperty,
  IFactoryProperty,
  IFactorySelectProperty,
  IFactorySwitchProperty,
} from '../models/element_property.interface';
import { createId, RegenerateIds, shouldRegenerateIds } from './id.helpers';
import { formatThousands } from './math_helpers';
import { capitalize, snakeCase } from 'lodash';
import {
  getElementProperties,
  getElementPropertyType,
  getSelectPropertyResolvedCount,
  getSelectPropertyOptionValue,
  isElementMutualProperty,
  hasCount,
  getSelectPropertyOptions,
} from './element_property_helpers';
import { isUppercase, findFreeName } from './string_helpers';
import { validateElementProperty } from '../validation/element-property.validation';
import { resolveAutoRecipeId } from './recipe_helpers';
import { isDefined, isEmptyArray, isOneOf } from './array_helpers';
import { OptionalReadonly } from '../models/type_helpers.interface';
import { mutualProperties } from '../templates/categories/categories-properties.model';
import { IElementCategory } from '../models/element_categories.interface';

/**
 * Create a new property of a specific type.
 * Can also be used to shallow clone a property.
 * @param defaults A partial with overides of the default values. Need to contain kind
 * @returns
 */
export function addElementProperties<
  T extends IFactoryProperty,
  E extends OneOfPropertyElements,
>(element: E, ...propertyDefaults: T[]): E {
  // Make sure to clone properties
  const properties = [...getElementProperties(element)];

  if (!propertyDefaults?.length) {
    return element;
  }
  propertyDefaults.forEach((defaults) =>
    properties.push(createElementProperty(defaults, properties)),
  );
  return {
    ...element,
    properties,
  };
}

/**
 * Get properties that should be added to all elements
 * @param parent
 * @param properties Properties that already exists on the element
 * @param inheritors Name of properties that should inherit default count from parent
 * @returns
 */
export const addMutualProperties = (
  properties: IFactoryProperty[] | undefined,
  category: IElementCategory | undefined,
): IFactoryProperty[] => {
  if (!category || !properties) {
    return properties ?? [];
  }

  // Properties that should not be added (existing properties and properties that should be excluded by category)
  const exclude = [
    ...(category.excludeMutualProperties ?? []),
    ...properties.map((p) => p.name),
  ];

  const propertiesToAdd = mutualProperties.filter(
    (property) => !isOneOf(exclude, property.name),
  );

  // No properties to add, don't mutate properties
  if (propertiesToAdd.length === 0) {
    return properties;
  }

  return [...propertiesToAdd, ...properties];
};

export const removeMutualProperties = (
  properties: IElementProperty[],
  filterReusedContent = true,
): IElementProperty[] => {
  return properties.filter(
    (property) => !isElementMutualProperty(property, filterReusedContent),
  );
};

/**
 * Create a new property of a specific type.
 * Can also be used to shallow clone a property.
 * @param defaults A partial with overides of the default values. Need to contain kind
 * @returns
 */
export function createElementProperty<
  T extends IFactoryProperty = IFactoryExpressionProperty,
>(
  defaults: T = {} as T,
  existingProperties: IElementProperty[] = [],
  regenerateIds?: boolean,
): CreatedElementProperty<T['type']> {
  return createElementPropertyOfInputType(
    defaults.type,
    defaults,
    existingProperties,
    regenerateIds,
  ) as CreatedElementProperty<T['type']>;
}

export const createElementProperties = (
  factoryProperties: IFactoryProperty[] = [],
  regenerateIds?: RegenerateIds,
): IElementProperty[] => {
  const regenerateBool = shouldRegenerateIds(regenerateIds);
  return factoryProperties.reduce(
    (properties, factoryProperty) => [
      ...properties,
      createElementProperty(factoryProperty, properties, regenerateBool),
    ],
    [] as IElementProperty[],
  );
};

/**
 * Create a new ElementProperty with recipe_id or category_id set
 * @param element
 * @param source
 * @param sourceId
 * @returns
 */
export const createElementPropertyOfSource = <
  T extends IFactoryProperty = IFactoryExpressionProperty,
>(
  element: IElement,
  source?: ElementPropertySource,
  sourceId?: ElementPropertySourceID,
  defaults: T = {} as T,
  regenerateIds?: boolean,
): CreatedElementProperty<T['type']> => {
  return createElementProperty(
    {
      defaults,
      ...createSourceIdProperty(source, sourceId),
    },
    element.properties,
    regenerateIds,
  ) as CreatedElementProperty<T['type']>;
};

/**
 * Create a new property of a specific type.
 * Can also be used to shallow clone a property.
 * @param type Type of property which should be created, default to expression
 * @param defaults A partial with overides of the default values
 * @param existingProperties Optional existingProperties used to generate unique name for property
 * @returns
 */
export function createElementPropertyOfInputType<
  T extends keyof ElementPropertyTypeMap = ElementPropertyType.Expression,
>(
  type: T = ElementPropertyType.Expression as T,
  defaults: Partial<FactoryPropertyInputTypeMap[T]> = {},
  existingProperties?: IElementProperty[],
  regenerateIds?: boolean,
): CreatedElementProperty<T> {
  switch (type) {
    case ElementPropertyType.Expression: {
      return createElementExpressionProperty(
        defaults as IFactoryExpressionProperty,
        existingProperties,
        regenerateIds,
      ) as CreatedElementProperty<T>;
    }
    case ElementPropertyType.Select: {
      return createElementSelectProperty(
        defaults as IFactorySelectProperty,
        existingProperties,
        regenerateIds,
      ) as CreatedElementProperty<T>;
    }
    case ElementPropertyType.Switch: {
      return createElementSwitchProperty(
        defaults as IFactorySwitchProperty,
        existingProperties,
        regenerateIds,
      ) as CreatedElementProperty<T>;
    }

    default: {
      throw new Error(`Can't create element of unknown type ${type as string}`);
    }
  }
}

/**
 * Create a new ElementProperty
 * @param defaults Default values for the property
 * @param element Optional element generate unique name for property
 * @param existingProperties Optional existingProperties used to generate unique name for property
 * @returns
 */
const createElementExpressionProperty = (
  defaults: IFactoryExpressionProperty = {},
  existingProperties?: IElementProperty[],
  regenerateIds = true,
): IElementExpressionProperty => {
  // If passing options we're trying to create a select property (happens a lot in unit test so keep this check)
  if ('options' in defaults) {
    throw new Error('Options are not allowed on expression properties');
  }

  const defaultCount = getCountFromDefaults(defaults, 0);
  const count =
    defaultCount !== undefined ? createExpression(defaultCount) : undefined;
  const fallbackCount =
    defaults.fallbackCount !== undefined || defaults.inheritFallback
      ? createExpression(defaults.fallbackCount)
      : undefined;

  return validateElementProperty({
    unit: 'kg',
    ...omitUndefined({ ...defaults, count, fallbackCount }),
    id: createId(defaults, regenerateIds),
    name: getPropertyName(defaults, existingProperties),
    type: ElementPropertyType.Expression,
  });
};

/**
 * Create a new ElementProperty
 * @param defaults Default values for the property
 * @param existingProperties Optional existingProperties used to generate unique name for property
 * @returns
 */
const createElementSelectProperty = (
  defaults: IFactorySelectProperty = { type: ElementPropertyType.Select },
  existingProperties?: IElementProperty[],
  regenerateIds = true,
): IElementSelectProperty => {
  // If value can be inherited, don't set a count. Else get count from defaults or options
  const count = getCountFromDefaults(
    defaults,
    getSelectPropertyResolvedCount(defaults),
  );
  const defaultOptions = defaults.options ?? [];

  // Fallback to count if no options are provided to at least provide one value
  const options = Array.isArray(defaultOptions)
    ? createPropertySelectOptions(
        isEmptyArray(defaultOptions) ? [count] : defaultOptions,
      )
    : defaultOptions;

  const optionList = getSelectPropertyOptions({ ...defaults, options });

  const optionValue = optionList?.[0]
    ? getSelectPropertyOptionValue(optionList?.[0])
    : undefined;

  const fallbackCount =
    defaults.fallbackCount !== undefined || defaults.inheritFallback
      ? (defaults.fallbackCount ?? optionValue)
      : undefined;

  return validateElementProperty({
    ...omitUndefined({ ...defaults, count, fallbackCount }),
    type: ElementPropertyType.Select,
    name: getPropertyName(defaults, existingProperties),
    id: createId(defaults, regenerateIds),
    options,
  });
};

/**
 * Create a new ElementProperty
 * @param defaults Default values for the property
 * @param existingProperties Optional existingProperties used to generate unique name for property
 * @returns
 */
const createElementSwitchProperty = (
  defaults: Partial<IFactorySwitchProperty> = {},
  existingProperties?: IElementProperty[],
  regenerateIds = true,
): IElementSwitchProperty => {
  const count = toSwitchCount(getCountFromDefaults(defaults, false));

  return validateElementProperty({
    ...omitUndefined({ ...defaults, count }),
    type: ElementPropertyType.Switch,
    name: getPropertyName(defaults, existingProperties),
    id: createId(defaults, regenerateIds),
  });
};

export const toSwitchCount = (
  count?: boolean | 'true' | 'false',
): boolean | undefined => {
  if (typeof count === 'boolean') {
    return count;
  }
  if (count === 'true' || count === 'false') {
    return count === 'true';
  }
  return undefined;
};

/**
 * Get the count from the defaults
 * @param defaults
 * @returns
 */
const getCountFromDefaults = <T extends Partial<IFactoryProperty>>(
  defaults: T,
  defaultValue: T['count'],
): T['count'] | undefined => {
  if (hasCount(defaults)) {
    return defaults.count;
  }
  // If inheritFallback or fallbackCount is provided, we can have undefined count
  if (defaults.inheritFallback || defaults.fallbackCount !== undefined) {
    return undefined;
  }
  return defaultValue;
};

/**
 * Create a new ElementProperty
 * @param defaults Default values for the property
 * @param element Optional element generate unique name for property
 * @returns
 */
export const getPropertyName = (
  { name = 'property' }: Pick<IFactoryProperty, 'name'> = {},
  existingProperties: IElementProperty[] = [],
): string => {
  name = name.trim().replace(/\s+/g, '_');
  const names = existingProperties?.map((property) => property.name);
  return findFreeName(names, name, {
    delimiter: '',
    startIndex: 1,
  });
};

/**
 * Create a new ElementProperty with recipe_id or category_id set
 * @param element
 * @param source
 * @param sourceId
 * @returns
 */
export const createSourceIdProperty = (
  source?: ElementPropertySource,
  sourceId?: ElementPropertySourceID,
): IElementPropertySourceIdProperties =>
  source && sourceId !== undefined
    ? {
        [source]:
          source === ElementPropertySource.Recipe
            ? resolveAutoRecipeId(sourceId)
            : sourceId,
      }
    : {};

/**
 * Create a valid options array from list of "stripped" options
 * @param options
 * @returns
 */
export const createPropertySelectOptions = (
  options: OptionalReadonly<(IElementPropertyFactoryOption | undefined)[]>,
): IElementPropertyOption[] =>
  Object.values(options) // Why is TS complaining if not wrapping in values?
    .filter(isDefined)
    .map((option) => {
      const value = getSelectPropertyOptionValue(option);
      const label =
        (typeof option === 'object' && option.label) || labelFromValue(value);
      return { label, value };
    });

/**
 * Apply a new id and [type]: typeId to property
 * @param property
 * @param source
 * @param sourceId
 * @returns
 */
export const applyIdAndSourceToProperty = <T extends IElementProperty>(
  property: Omit<T, 'id'>,
  source?: ElementPropertySource,
  sourceId?: ElementPropertySourceID,
): T => {
  return {
    ...property,
    id: uuid.v4(),
    ...createSourceIdProperty(source, sourceId),
  } as T;
};

/**
 * Create a nicer formatted label from a value
 * @param value
 * @returns
 */
export const labelFromValue = (
  value?: string | number | boolean,
  format = true,
): string => {
  if (typeof value === 'number') {
    return formatThousands(value);
  } else if (typeof value === 'boolean') {
    return value ? 'On' : 'Off';
  } else if (!value) {
    return 'None';
  }

  if (!format) {
    return value;
  }

  // If the value is all uppercase, or contains spaces, return it as-is since it's most likely already well formatted
  if (isUppercase(value) || value.indexOf(' ') > -1) {
    return value;
  }

  return capitalize(snakeCase(value).replace(/[-_.\s]+/gi, ' '));
};

export const isFactoryExpressionProperty = (
  prop: IFactoryProperty | undefined,
): prop is IFactoryExpressionProperty =>
  !!prop && getElementPropertyType(prop) === ElementPropertyType.Expression;
