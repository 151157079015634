import React, { useCallback, useMemo } from 'react';
import { ButtonProps, PopoverOrigin, TypographyProps } from '@mui/material';
import { OneOfPropertyElements } from '../../../../../../shared/models/project.interface';
import {
  ElementSelectPropertyCountType,
  IElementSelectProperty,
} from '../../../../../../shared/models/element_property.interface';
import { useSelectProperty } from '../../../../hooks/element-properties.hook';
import {
  getCount,
  getSelectPropertyOptions,
} from '../../../../../../shared/helpers/element_property_helpers';
import { ArrowDropDown } from '@mui/icons-material';
import PropertyMenu from '../../../menus/TextMenu/PropertyMenu';

interface ElementPropertySelectProps {
  property: IElementSelectProperty;
  element: OneOfPropertyElements;
}

const ElementPropertySelect: React.FC<ElementPropertySelectProps> = ({
  property,
  element,
}) => {
  const selectProperty = useSelectProperty(element);
  const options = useMemo(() => getSelectPropertyOptions(property), [property]);
  const count = getCount(property);
  const selectedOption = options.find((option) => option.value === count);

  const handleSelect = useCallback(
    (value: ElementSelectPropertyCountType) => {
      selectProperty(value, property);
    },
    [selectProperty, property],
  );

  return (
    <PropertyMenu
      options={options}
      selectedValue={count}
      selectedLabel={selectedOption?.label ?? count.toString()}
      isFallback={!property.count && !!property.fallbackCount}
      buttonLabelProps={buttonLabelProps}
      buttonProps={buttonProps}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onSelect={handleSelect}
    />
  );
};

const buttonLabelProps: TypographyProps = {
  textTransform: 'capitalize',
  fontWeight: 400,
  fontSize: 12,
};
const buttonProps: ButtonProps = { endIcon: <ArrowDropDown /> };
const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' };
const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'right' };

export default ElementPropertySelect;
