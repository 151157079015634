import React, { useMemo } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface VisibilityIconProps extends ButtonProps {
  visible?: boolean;
  onClick: (e: React.MouseEvent) => void | undefined;
}

const VisibilityButton: React.FC<VisibilityIconProps> = ({
  visible,
  onClick,
  disabled,
  ...props
}) => {
  const buttonStyles = useMemo(() => {
    return {
      minWidth: 40,
      height: 40,
      opacity: 0.5,
      '&:hover': {
        opacity: 1,
      },
      ...props.sx,
    };
  }, [props.sx]);

  return (
    <Button sx={buttonStyles} onClick={onClick} disabled={disabled} {...props}>
      {!visible ? (
        <VisibilityOff sx={{ fontSize: 18, color: 'black' }} />
      ) : (
        <Visibility sx={{ fontSize: 18 }} color="action" />
      )}
    </Button>
  );
};

export default VisibilityButton;
