import React, { useCallback } from 'react';
import VisibilityButton from '../../Buttons/VisibilityButton';
import { IElement } from '../../../../../../shared/models/project.interface';
import { useUpdateElements } from '../../../../store/project';
import { useIsReadonly } from '../../../../hooks/user.hook';

interface ElementVisibilityToggleProps {
  element: IElement;
}

const ElementVisibilityToggle: React.FC<ElementVisibilityToggleProps> = ({
  element,
}) => {
  const updateElements = useUpdateElements();
  const readonly = useIsReadonly();
  const handleClick = useCallback(() => {
    if (readonly) return;
    updateElements({
      ...element,
      isDeactivated: !element.isDeactivated,
    });
  }, [element, updateElements, readonly]);

  if (readonly && !element.isDeactivated) {
    // if in readonly & element is active, no need to show the button as the user cannot interact with it
    return null;
  }
  return (
    <VisibilityButton
      visible={!element.isDeactivated}
      disabled={readonly}
      onClick={handleClick}
    />
  );
};

export default ElementVisibilityToggle;
