import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { Results } from '../../../shared/models/unit.interface';
import { formatValue, FormatUnit } from '../../../shared/helpers/math_helpers';
import { isDefined } from '../../../shared/helpers/array_helpers';
import { makeStyles } from 'tss-react/mui';
import { getConversionFactorValue } from '../../../shared/helpers/conversion-factors.helpers';
import { useProjectCostIsDisabled } from '../store/project';
import {
  Lifecycle,
  lifecycles,
} from '../../../shared/models/lifecycles.interface';
import {
  getCO2eUnitFromLifecycle,
  getCostUnitFromLifecycle,
} from '../../../shared/helpers/lifecycles.helpers';
interface IResultsTooltipProps {
  results: Results;
  children: React.ReactElement;
  perUnit?: FormatUnit;
}

export const ResultsTooltip: React.FC<IResultsTooltipProps> = ({
  results,
  children,
  perUnit,
}) => {
  const table = useMemo(
    () => <TooltipTable results={results} perUnit={perUnit} />,
    [results, perUnit],
  );
  const slotProps = useMemo<TooltipProps['slotProps']>(
    () => ({ tooltip: { sx: { maxWidth: 'none' } } }),
    [],
  );

  return (
    <Tooltip title={table} slotProps={slotProps}>
      <div style={containerStyle}>{children}</div>
    </Tooltip>
  );
};

const TooltipTable: React.FC<{
  results: Results;
  perUnit?: FormatUnit;
}> = ({ results, perUnit }) => {
  const { classes } = useStyles();
  const disableCost = useProjectCostIsDisabled();
  const co2eTotal = getConversionFactorValue(results, 'co2e');
  const sekTotal = getConversionFactorValue(results, 'sek');
  const settings: IRowSettings = {
    disableCost,
    perUnit,
  };

  const rows: React.ReactNode[] = lifecycles
    .map((lifecycle) => {
      const costKey = getCostUnitFromLifecycle(lifecycle);
      const co2eKey = getCO2eUnitFromLifecycle(lifecycle);
      return getRow(lifecycle, results[co2eKey], results[costKey], settings);
    })
    .filter(isDefined);

  rows.unshift(getRow('Total', co2eTotal, sekTotal, settings));

  // Remove empty rows
  const filteredRows = rows.filter(isDefined);

  if (filteredRows.length !== 0) {
    return (
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow key="tooltip-row-header">
            <TableCell padding="none"></TableCell>
            <TableCell align="center">
              {perUnit === 'GFA' ? 'kgCO2e/m² GFA' : `kgCO2e / ${perUnit}`}
            </TableCell>
            {!disableCost && (
              <TableCell align="center">
                {perUnit === 'GFA' ? 'kSEK/m² GFA' : `kSEK / ${perUnit}`}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{filteredRows}</TableBody>
      </Table>
    );
  }
};

const containerStyle: React.CSSProperties = {
  width: '100%',
};

const useStyles = makeStyles()(({ spacing }) => ({
  table: {
    width: '100%',
    color: 'white',
    [`& .${tableCellClasses.root}`]: {
      borderBottom: 'none',
      color: 'white',
      paddingTop: spacing(0.25),
      paddingBottom: spacing(0.25),
      whiteSpace: 'nowrap',
    },
  },
}));

interface IRowSettings {
  disableCost?: boolean;
  perUnit?: FormatUnit;
}

const getRow = (
  label: Lifecycle | 'Total',
  co2: number = 0,
  cost: number = 0,
  { disableCost, perUnit }: IRowSettings,
): undefined | React.ReactNode => {
  if (!co2 && !cost && label !== 'Total') return null;
  return (
    <TableRow key={`tooltip-row-${label}`}>
      <TableCell align="right" padding="none">
        {label}
      </TableCell>
      <TableCell align="right" sx={{ fontWeight: 500 }}>
        {formatValue(co2, 'co2e_A1-A3', {
          perUnit,
          showUnit: false,
        })}
      </TableCell>
      {!disableCost && (
        <TableCell align="right" sx={{ fontWeight: 500 }}>
          {formatValue(cost, 'kSEK', {
            perUnit,
            showUnit: false,
          })}
        </TableCell>
      )}
    </TableRow>
  );
};
