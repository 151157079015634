import {
  Checkbox,
  Divider,
  ListItemButton,
  Typography,
  TypographyProps,
} from '@mui/material';
import React, { CSSProperties, useCallback, useEffect, useRef } from 'react';
import { EllipsisText } from '../../EllipsisText';
import { MENU_ITEM_HEIGHT } from '../../../../../shared/constants';
import { getSBEFDividerItems } from '../../../../../shared/helpers/sbef_helpers';
import { ConcreteType } from '../../../../../shared/templates/categories/concrete/concrete.model';
import { BoardType } from '../../../../../shared/templates/categories/gypsum/gypsum.model';
import { InsulationMaterial } from '../../../../../shared/templates/categories/insulation/insulation.model';
import { WoodType } from '../../../../../shared/templates/categories/wood/wood.model';
import { otherNodonProduct } from '../../../../../shared/templates/categories/other-product';
import { formatThousands } from '../../../../../shared/helpers/math_helpers';
import { useObjectMemo } from '../../../hooks/object.hook';

interface SelectMenuItemProps {
  /** These styles are needed if virtualization is enabled */
  style?: CSSProperties;
  value: string | number;
  label?: string;
  selected?: boolean;
  showTooltip?: boolean;
  indentAfterFirstWord?: boolean;
  disableDivider?: boolean;
  showCheckbox?: boolean;
  onClick: (value: string | number) => void;
}

const SelectMenuItem: React.FC<SelectMenuItemProps> = ({
  value,
  label: labelInput,
  style,
  selected,
  showTooltip,
  indentAfterFirstWord,
  disableDivider,
  onClick,
  showCheckbox,
}) => {
  const label =
    labelInput ?? (typeof value === 'number' ? formatThousands(value) : value);

  const [first, ...rest] = indentAfterFirstWord ? label.split(' ') : [];

  const ref = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  const mergeStyle = useObjectMemo({
    ...style,
    zIndex: 1,
  });

  useEffect(() => {
    if (ref.current && selected) {
      ref.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  }, [ref, selected]);

  return (
    <>
      {!disableDivider && <DividerItem label={label} style={style} />}
      <ListItemButton
        ref={ref}
        style={mergeStyle}
        onClick={handleClick}
        selected={!showCheckbox ? selected : false}
      >
        {showCheckbox && <Checkbox checked={selected} />}
        <Typography {...listItemTextStyle}>
          <EllipsisText showTooltip={showTooltip}>
            {first || label}
          </EllipsisText>
        </Typography>

        {rest.length > 0 && (
          <Typography {...listItemTextStyle}>
            <EllipsisText showTooltip={showTooltip}>
              {rest.join(' ')}
            </EllipsisText>
          </Typography>
        )}
      </ListItemButton>
    </>
  );
};

interface DividerItemProps {
  /** These styles are needed if virtualization is enabled */
  style?: CSSProperties;
  label: string;
}

const DividerItem = ({ style, label }: DividerItemProps) => {
  const isNodonProduct = otherNodonProduct && label === otherNodonProduct.name;

  const isDividerItem = dividerItems.includes(label);

  if (!isNodonProduct && !isDividerItem) {
    return null;
  }
  if (!style) {
    return <Divider />;
  }
  return (
    <Divider
      style={{
        ...style,
        zIndex: 0,
        top: isNodonProduct ? style.top : Number(style.top) - MENU_ITEM_HEIGHT,
      }}
    />
  );
};

const dividerItems: string[] = [
  ConcreteType.Other,
  ConcreteType.BalconyAndStairs,
  ConcreteType.Columns,
  BoardType.CelluloseFibre,
  InsulationMaterial.EPS,
  WoodType.OSB,
  ...getSBEFDividerItems(),
];

const listItemTextStyle: TypographyProps = {
  minWidth: 30,
  variant: 'body2',
  display: 'flex',
  alignItems: 'center',
} as const;

export default SelectMenuItem;
