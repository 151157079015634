import { ICityClimateData } from '../energy.models';

/**
 * List of cities with their normal year temperature (median temperature).
 * Source: https://www.lth.se/fileadmin/ees/Publikationer/2006/5091.pdf (p.26)
 * DUT: https://www.boverket.se/sv/om-boverket/publicerat-av-boverket/oppna-data/dimensionerande-vinterutetemperatur-dvut-1981-2010/
 */
export const cityClimateDataList: ICityClimateData[] = [
  {
    city: 'Malmö',
    lat: 55.61,
    lng: 13.07,
    temp: 8,
    sunHours: 1700,
    dut: -8.7,
  },
  {
    city: 'Växjö',
    lat: 56.84,
    lng: 14.82,
    temp: 6.5,
    sunHours: 1650,
    dut: -13.4,
  },
  {
    city: 'Kalmar',
    lat: 56.73,
    lng: 16.29,
    temp: 7,
    sunHours: 1800,
    dut: -11.5,
  },
  {
    city: 'Göteborg',
    lat: 57.72,
    lng: 11.99,
    temp: 7.9,
    sunHours: 1600,
    dut: -11.2,
  },
  {
    city: 'Karlstad',
    lat: 59.38,
    lng: 13.45,
    temp: 5.9,
    sunHours: 1550,
    dut: -17.9,
  },
  {
    city: 'Örebro',
    lat: 59.28,
    lng: 15.16,
    temp: 5.9,
    sunHours: 1600,
    dut: -16.4,
  },
  {
    city: 'Stockholm',
    lat: 59.34,
    lng: 18.05,
    temp: 6.6,
    sunHours: 1800,
    dut: -14.4,
  },
  {
    city: 'Östersund',
    lat: 63.17,
    lng: 14.68,
    temp: 2.7,
    sunHours: 1500,
    dut: -22.9,
  },
  {
    city: 'Umeå',
    lat: 63.83,
    lng: 20.29,
    temp: 3.4,
    sunHours: 1600,
    dut: -22.1,
  },
  {
    city: 'Luleå',
    lat: 65.54,
    lng: 22.12,
    temp: 3,
    sunHours: 1650,
    dut: -25.7,
  },
  {
    city: 'Kiruna',
    lat: 67.83,
    lng: 20.34,
    temp: -1.2,
    sunHours: 1500,
    dut: -28.5,
  },
]; //
