import { getElementPropertyResolvedCountByNameOrId } from './element_property_helpers';
import {
  ElementSelectPropertyCountType,
  ElementSwitchPropertyCountType,
} from '../models/element_property.interface';
import { OneOfElements } from '../models/project.interface';
import {
  ArrayOrSingle,
  OptionallyRequired,
} from '../models/type_helpers.interface';
import { asArray, isOneOf } from './array_helpers';
import { isNonArrayObject, omitUndefined } from './object_helpers';
import { required } from './function_helpers';

/**
 * Map node to simplify dropdown selections
 */
type IConditionalMapNode<Value, PropertyName extends string = string> = {
  /**
   * Forbidden properties from IConditionalFilterNode.
   * Used for TS type narrowing
   */
  conditions?: never;
  nodes?: never;

  /**
   * Property name or id
   */
  property: PropertyName;

  /**
   * Map of property counts to new nested nodes
   */
  map: {
    [propertyCount in ElementSelectPropertyCountType]?: ArrayOrSingle<
      IConditionalNode<Value, PropertyName>
    >;
  };
  /**
   * Default node to return if the property count is not found in the map
   */
  defaults?: ArrayOrSingle<IConditionalNode<Value, PropertyName>>;
};

/**
 * Helper to get original type excluding conditions or map nodes.
 */
type IValueNode<Value> = Value extends
  | { conditions: never }
  | { property: never; map: never }
  ? never
  : Value;

/**
 * Each property name can have a single or multiple expected values (compared as OR)
 */
type IConditions<PropertyName extends string = string> = {
  /**
   * Key is the property name or id. Value should be the expected value or multiple expected values (compared as OR)
   */
  [propertyNameOrId in PropertyName]?: ArrayOrSingle<
    ElementSelectPropertyCountType | ElementSwitchPropertyCountType
  >;
};

export interface IConditionalFilterNode<
  Value,
  PropertyName extends string = string,
> {
  /**
   * Forbidden properties from IConditionalMapNode.
   * Used for TS type narrowing
   */
  property?: never;
  map?: never;
  defaults?: never;

  /**
   * Conditions that need to be met for the property to be visible.
   * Keys are property names and values what they are expected to be to allow properties to be visible
   *
   */
  conditions: IConditions<PropertyName>;

  /**
   * Array of nodes to filter OR an additional level of conditions
   */
  nodes: IConditionalNode<Value, PropertyName>[];
}

/**
 * Any kind of node/value in the tree structure
 */
export type IConditionalNode<Value, PropertyName extends string = string> =
  | IValueNode<Value>
  | IConditionalMapNode<Value, PropertyName>
  | IConditionalFilterNode<Value, PropertyName>;

/**
 * Go through a tree of conditional nodes recursively and
 * return the nodes should be visible as a flat array of nodes.
 * Should keep the order of nodes in the tree top to bottom (ignoring depth)
 * @param element - The element to check match conditions from (via properties)
 * @param nodes - The nodes to filter
 * @returns The resolved properties
 */
export const filterConditionalNodes = <
  Value,
  PropertyName extends string = string,
>(
  element: OneOfElements,
  ...nodes: IConditionalNode<Value, PropertyName>[]
): Value[] => {
  return nodes.flatMap((node) => {
    // If it is a conditional tree, continue recursively
    if (isConditionalFilterNode(node)) {
      return matchedConditions(element, node.conditions)
        ? filterConditionalNodes(element, ...node.nodes) // if conditions are met, continue recursively
        : []; // Stop here if conditions are not met
    }

    // If it is a conditional map, get the nodes from the map
    if (isConditionalMapNode(node)) {
      const { property, map, defaults } = node;
      const count = getElementPropertyResolvedCountByNameOrId(
        element,
        property,
      );
      // Allow use to use map with boolean values (then key is 'true' or 'false')
      const key = typeof count === 'boolean' ? String(count) : count;
      const nodes =
        key !== undefined && map[key] !== undefined ? map[key] : defaults;
      return nodes ? filterConditionalNodes(element, ...asArray(nodes)) : [];
    }
    // Property is not a conditional tree or map, so it should be returned as it is (should be leaf node)
    return node;
  });
};

/**
 * Get a single value from conditional nodes.
 * Use this when you expect a single value to be returned
 * @param element - The element to check match conditions from (via properties)
 * @param nodes - The nodes to filter
 * @param throwIfNotFound - Whether to throw an error if no value is found
 * @returns The first matching value
 */
export const getValueFromConditionalNodes = <
  Value,
  PropertyName extends string = string,
  Throw extends boolean = true,
>(
  element: OneOfElements,
  nodes: IConditionalNode<Value, PropertyName>[],
  throwIfNotFound: Throw = true as Throw,
): OptionallyRequired<Value, Throw> => {
  return required(
    filterConditionalNodes(element, ...nodes)[0],
    throwIfNotFound ? 'No item was filtered from the conditional tree' : false,
  );
};

/**
 * Check if the property is a conditional property tree
 * @param node - The property to check
 * @returns True if the property is a conditional property tree, false otherwise
 */
const isConditionalFilterNode = <Value, PropertyName extends string = string>(
  node: IConditionalNode<Value, PropertyName>,
): node is IConditionalFilterNode<Value, PropertyName> => {
  return (
    isNonArrayObject(node) &&
    'conditions' in node &&
    isNonArrayObject(node.conditions)
  );
};

const isConditionalMapNode = <Value, PropertyName extends string = string>(
  node: IConditionalNode<Value, PropertyName>,
): node is IConditionalMapNode<Value, PropertyName> => {
  return (
    isNonArrayObject(node) &&
    'property' in node &&
    'map' in node &&
    isNonArrayObject(node.map)
  );
};

/**
 * Check if the conditions are met for the property to be visible.
 * @param element - The element to check the conditions for.
 * @param conditions - The conditions to check.
 * @returns True if the conditions are met, false otherwise.
 */
export const matchedConditions = (
  element: OneOfElements,
  conditions: IConditions,
): boolean => {
  return Object.entries(omitUndefined(conditions)).every(
    ([propertyName, expectedValue]) => {
      // TODO, support quantities?
      const count = getElementPropertyResolvedCountByNameOrId(
        element,
        propertyName,
      );
      return isOneOf(asArray(expectedValue), count);
    },
  );
};
