import React, { Fragment, useCallback, useMemo } from 'react';
import { ButtonProps, Divider, Typography } from '@mui/material';
import { createLocalStorageStore } from '../helpers/local-storage.helpers';
import {
  Lifecycle,
  lifecycles,
} from '../../../shared/models/lifecycles.interface';
import TextMenu from './menus/TextMenu/TextMenu';
import SelectMenuItem from './menus/ListItems/SelectMenuItem';
import { SelectListChildren } from './menus/menu.model';
import { getLifecycleLabel } from '../../../shared/helpers/lifecycles.helpers';
import { KeyboardArrowDown } from '@mui/icons-material';

const ALL_LIFECYCLES = 'All';
type LifecycleItem = typeof ALL_LIFECYCLES | Lifecycle;
const ITEMS: LifecycleItem[] = [ALL_LIFECYCLES, ...lifecycles];

/**
 * Use the selected lifecycles from local storage
 */
const { useStore, set: setLifecycles } = createLocalStorageStore<Lifecycle[]>(
  'visible_lifecycles',
  [Lifecycle.A1A3, Lifecycle.A4, Lifecycle.A5], // Default to A1-A5
);

export const useSelectedLifecycles = useStore;

export const SelectLifecycles: React.FC<{
  size?: ButtonProps['size'];
  labelPrefix?: string;
}> = ({ labelPrefix = '', size = 'small' }) => {
  const buttonProps = useMemo(
    () => ({ size, endIcon: <KeyboardArrowDown /> }),
    [size],
  );
  const selectedLifecycles = useSelectedLifecycles();
  const selectLifecycle = useSelectLifecycle();

  const isSelectedLifecycle = useCallback(
    (value: LifecycleItem) => {
      if (value === ALL_LIFECYCLES) {
        return selectedLifecycles.length === lifecycles.length;
      }
      return selectedLifecycles.includes(value);
    },
    [selectedLifecycles],
  );

  const renderChildren = useCallback<SelectListChildren<LifecycleItem>>(
    (item, style) => {
      return (
        <Fragment key={`lifecycle-${item}`}>
          <SelectMenuItem
            style={style}
            showCheckbox={true}
            selected={isSelectedLifecycle(item)}
            value={item}
            disableDivider={item !== ALL_LIFECYCLES}
            onClick={() => selectLifecycle(item)}
          />
          {item === ALL_LIFECYCLES && <Divider />}
        </Fragment>
      );
    },
    [isSelectedLifecycle, selectLifecycle],
  );

  return (
    <TextMenu
      items={ITEMS}
      tooltip="Set which lifecycles should be included in the results"
      labelPrefix={labelPrefix}
      label={
        <Typography
          component="span"
          fontSize="inherit"
          fontWeight="bold"
          lineHeight="inherit"
        >
          {getLifecycleLabel(selectedLifecycles)}
        </Typography>
      }
      menuWidth="small"
      buttonProps={buttonProps}
    >
      {renderChildren}
    </TextMenu>
  );
};

const useSelectLifecycle = (): ((value: LifecycleItem) => void) => {
  const selectedLifecycles = useSelectedLifecycles();
  return useCallback(
    (value: LifecycleItem) => {
      if (!ITEMS.includes(value)) {
        throw new Error(`Invalid lifecycle selection: ${value}`);
      }
      let newValues: Lifecycle[] = [];
      if (value === ALL_LIFECYCLES) {
        newValues =
          lifecycles.length === selectedLifecycles.length ? [] : lifecycles;
      } else if (selectedLifecycles.includes(value)) {
        newValues = selectedLifecycles.filter((item) => item !== value);
      } else {
        newValues = [...selectedLifecycles, value];
      }

      // If new values are empty, default to A1-A3, it makes no sense to show none.
      setLifecycles(newValues.length === 0 ? [Lifecycle.A1A3] : newValues);
    },
    [selectedLifecycles],
  );
};
