/* eslint-disable @typescript-eslint/no-unsafe-call */

import { getNodeValue } from '../utils/get-node-value.helper';
import { calculateElectricPeakEffect } from '../../energy/heating-energy.helpers';

export function getElectricPeakEffect(
  args: any[],
  math: any,
  scope: any,
): number {
  const annualHeatRequirement = getNodeValue(args[0], math, scope);
  const location = getNodeValue(args[1], math, scope);
  return calculateElectricPeakEffect(annualHeatRequirement, {
    location,
  });
}

getElectricPeakEffect.rawArgs = true;
