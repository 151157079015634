import React, { useCallback, useMemo } from 'react';
import { Recipe } from '../../../../../../../shared/models/recipe.interface';
import { IElement } from '../../../../../../../shared/models/project.interface';
import RecipeListItem from '../../../../ElementList/ListItems/RecipeListItem';
import { SelectListChildren } from '../../../../menus/menu.model';
import { itemBelongsToTemplateOrganization } from '../../../../../../../shared/helpers/organization_helpers';
import { RecipeSelectorTab } from './RecipeSelectorTabs';
import { ElementPropertyName } from '../../../../../../../shared/models/element_property.interface';
import { useUser } from '../../../../../hooks/user.hook';
import { useLastSelectedRecipe } from '../../../../../store/recipe/recipe.hook';
import SelectList from '../../../../SelectList';
import { getSBEFCode } from '../../../../../../../shared/helpers/sbef_helpers';
import { useObjectMemo } from '../../../../../hooks/object.hook';
import { EmptyStateProps } from '../../../../EmptyState';

interface RecipeSelectorListProps {
  element: IElement;
  searchString: string;
  sbefCode: string;
  selectedTab: RecipeSelectorTab | undefined;
  onClose: () => void;
}

const RecipeSelectorList: React.FC<RecipeSelectorListProps> = ({
  element,
  searchString,
  sbefCode,
  selectedTab,
  onClose,
}) => {
  const user = useUser();
  const { recipes: lastSelectedRecipes } = useLastSelectedRecipe();

  const filteredRecipes = useMemo(() => {
    const filteredBySBEF = lastSelectedRecipes.filter(getSBEFRecipe(sbefCode));

    switch (selectedTab) {
      case 'owner':
        return filteredBySBEF.filter((recipe) => recipe.owner === user?.id);
      case 'team':
        return filteredBySBEF.filter(
          (recipe) => !itemBelongsToTemplateOrganization(recipe),
        );
      case 'public':
        return filteredBySBEF.filter(itemBelongsToTemplateOrganization);
      default:
        return filteredBySBEF;
    }
  }, [lastSelectedRecipes, sbefCode, selectedTab, user?.id]);

  const emptyStateProps = useObjectMemo<EmptyStateProps>({
    message: 'No recipes found',
    fullHeight: true,
  });

  const recipeRenderer = useCallback<SelectListChildren<Recipe>>(
    (item, style) => (
      <RecipeListItem
        style={style}
        recipe={item}
        element={element}
        onSelect={onClose}
      />
    ),
    [element, onClose],
  );

  return (
    <SelectList
      enableVirtualization
      searchString={searchString}
      items={filteredRecipes}
      emptyStateProps={emptyStateProps}
    >
      {recipeRenderer}
    </SelectList>
  );
};

const getSBEFRecipe = (sbefValue: string) => (recipe: Recipe) => {
  if (sbefValue === '') {
    return recipe;
  }
  const sbefCode = getSBEFCode(sbefValue);
  const recipeSBEF =
    recipe.category_property_value_record[ElementPropertyName.SBEFCode];

  if (sbefCode && recipeSBEF && typeof recipeSBEF === 'string') {
    return recipeSBEF.startsWith(sbefCode);
  }
  return false;
};

export default RecipeSelectorList;
