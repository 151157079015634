import React, {
  FC,
  useCallback,
  useMemo,
  FocusEvent,
  BaseSyntheticEvent,
  useEffect,
} from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import * as Yup from 'yup';
import { Project } from '../../../shared/models/project.interface';
import SettingsForm from './SettingsForm';
import amplitudeLog from '../amplitude';
import NodonTextField from './NodonTextField';
import { useIsReadonly } from '../hooks/user.hook';
import { useProjectState } from '../store/project';
import { omit } from '../../../shared/helpers/object_helpers';
import { useUIState } from '../store/ui';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { ProjectDetailsPayloadData } from '../store/project/project-state.model';
import FormNumberInput from './FormNumberInput';

export interface ProjectDetailsFormData
  extends Omit<ProjectDetailsPayloadData, 'completed_at'> {
  completed_at: DateTime<boolean> | null;
}

interface FormRenderProps<T extends keyof ProjectDetailsFormData> {
  field: ControllerRenderProps<ProjectDetailsFormData, T>;
}

interface ProjectDetailsEditorProps {
  project: Project;
}

const ProjectDetailsEditor: FC<ProjectDetailsEditorProps> = ({ project }) => {
  const { updateProjectDetails } = useProjectState('updateProjectDetails');
  const { setShowProjectDetailsEditor } = useUIState(
    'setShowProjectDetailsEditor',
  );

  const isReadonly = useIsReadonly();

  const schema = Yup.object({
    name: Yup.string().trim().required('Name is required'),
  });

  const defaultValues: ProjectDetailsFormData = useMemo(
    () => ({
      name: project.name,
      description: project.description ?? '',
      completed_at: project.completed_at
        ? DateTime.fromISO(project.completed_at)
        : null,
      target: project.target,
    }),
    [project],
  );

  const { control, handleSubmit, formState, reset } =
    useForm<ProjectDetailsFormData>({
      defaultValues,
      resolver: yupResolver(schema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

  const { errors } = formState;

  const submit = useCallback(
    (event: BaseSyntheticEvent<Record<string, unknown>, any, any>) => {
      handleSubmit(async ({ name, description, completed_at, target }) => {
        const completionDate = completed_at?.toString() ?? null;

        await updateProjectDetails({
          projectId: project.id,
          data: {
            name,
            description,
            target: target ? Number(target) : null,
            completed_at: completionDate,
          },
        });

        if (name !== project.name) {
          setShowProjectDetailsEditor(false);
          amplitudeLog('Project Name Set', {
            ProjectName: name,
          });
        }
        if (description !== project.description) {
          amplitudeLog('Project Description Set', {
            ProjectDescription: description,
          });
        }
        if (target !== project.target) {
          amplitudeLog('Project Target Set', {
            ProjectTarget: target,
          });
        }
        if (completionDate !== project.completed_at) {
          amplitudeLog('Project Completion Date Set', {
            ProjectCompletionDate: completed_at,
          });
        }
      })(event);
    },
    [handleSubmit, project, updateProjectDetails, setShowProjectDetailsEditor],
  );

  const onBlur = useCallback((e: FocusEvent<HTMLInputElement>): void => {
    e.currentTarget.blur();
  }, []);

  useEffect(() => {
    if (project) {
      reset(defaultValues);
    }
  }, [reset, defaultValues, project]);

  return (
    <Box>
      <SettingsForm formState={formState} submit={submit}>
        <Controller
          control={control}
          name="name"
          render={({ field }: FormRenderProps<'name'>) => (
            <NodonTextField
              {...omit(field, 'ref')}
              inputRef={field.ref}
              label="Name"
              size="small"
              fullWidth
              required
              autoFocus
              error={!!errors.name}
              helperText={errors.name?.message}
              disabled={isReadonly}
              sx={formFieldStyles}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field }: FormRenderProps<'description'>) => (
            <NodonTextField
              {...omit(field, 'ref')}
              inputRef={field.ref}
              label="Description"
              multiline
              minRows={4}
              error={!!errors.description}
              helperText={errors.description?.message}
              disabled={isReadonly}
              sx={formFieldStyles}
            />
          )}
        />
        <Controller
          control={control}
          name="completed_at"
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label="Completion date"
              displayWeekNumber
              disabled={isReadonly}
              sx={formFieldStyles}
              slotProps={{ field: { clearable: true } }}
            />
          )}
        />
        <Controller
          control={control}
          name="target"
          render={({ field }) => {
            return (
              <FormNumberInput
                {...omit(field, 'ref')}
                inputRef={field.ref}
                label="Target"
                unit="kgCO2e/m² GFA"
                value={field.value ?? undefined}
                disabled={isReadonly}
                onChange={(value) => {
                  field.onChange(value === undefined ? null : value);
                }}
              />
            );
          }}
        />
      </SettingsForm>
    </Box>
  );
};

const formFieldStyles = { paddingBottom: 4 } as const;

export default ProjectDetailsEditor;
