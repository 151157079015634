import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  SxProps,
  Tooltip,
} from '@mui/material';
import React, { useCallback } from 'react';

import { SortByAlternativeKey } from '../../../../../shared/helpers/tree.helpers';
import {
  setSortProjectsBy,
  SortProjectsOptions,
  useSortProjectsBy,
  useToggleSortProjectsDirection,
} from '../../../hooks/filter-projects.hook';
import {
  setProjectCostIsDisabled,
  useProjectCostIsDisabled,
} from '../../../store/project';
import { costEmissionIcons } from '../../Icon';
import MultiLineTooltip from '../../MultiLineTooltip';
import { SelectLifecycles } from '../../SelectLifecycles';
import { SortSelectOptions } from '../../sorting/SortSelect';
import {
  CONTENT_CELL_WIDTH,
  LIST_SPACING,
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
} from '../list.constants';
import { Row } from '../Row';
import { RowCell } from '../RowCell';

const ProjectListHeader = () => {
  const costIsDisabled = useProjectCostIsDisabled();

  const toggleCost = useCallback(() => {
    setProjectCostIsDisabled(!costIsDisabled);
  }, [costIsDisabled]);

  return (
    <Row padding={2} spacing={LIST_SPACING.PROJECT_LIST}>
      <RowCell
        width={ROOT_CELL_WIDTH.CONTENT_PROJECT_LIST}
        responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.CONTENT_PROJECT_LIST}
      >
        <Row spacing={LIST_SPACING.DEFAULT}>
          {/* Space for lock icon */}
          <RowCell width={ROOT_CELL_WIDTH.ICON} />

          {/* Space for expand button */}
          <RowCell width={ROOT_CELL_WIDTH.ICON} />

          <RowCell
            responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.CONTENT_PROJECT_LIST}
          >
            <SortButton sortBy="name" />
          </RowCell>

          <RowCell width={CONTENT_CELL_WIDTH.DATE}>
            <SortButton sortBy="updated_at" />
          </RowCell>

          <RowCell width={CONTENT_CELL_WIDTH.UNIT} align="right">
            <SortButton
              sortBy="gfa"
              tooltip="Gross floor area"
              sx={{ justifyContent: 'flex-end' }}
            />
          </RowCell>
        </Row>
      </RowCell>

      <RowCell width={ROOT_CELL_WIDTH.BAR} responsiveWidth={1}>
        <Box display="flex" alignItems="center" flexDirection="row" gap={0}>
          <SortButton disabled sortBy="co2" />
          <SelectLifecycles size="medium" />
        </Box>
      </RowCell>

      <RowCell width={CONTENT_CELL_WIDTH.UNIT} align="right">
        <MultiLineTooltip rows={['Emissions per GFA', 'kgCO2e / m2']}>
          {costEmissionIcons.kgCO2e}
        </MultiLineTooltip>
      </RowCell>
      <RowCell width={CONTENT_CELL_WIDTH.UNIT} align="right">
        <MultiLineTooltip rows={['Cost per GFA', 'kSEK / m2']}>
          <IconButton
            size="large"
            onClick={toggleCost}
            sx={{ opacity: costIsDisabled ? 0.5 : 1 }}
          >
            {costEmissionIcons.kSEK}
          </IconButton>
        </MultiLineTooltip>
      </RowCell>

      <RowCell width={CONTENT_CELL_WIDTH.DATE}>
        <SortButton sortBy="completed_at" />
      </RowCell>

      <RowCell width={CONTENT_CELL_WIDTH.OWNER + CONTENT_CELL_WIDTH.ICON}>
        <RowCell width={CONTENT_CELL_WIDTH.OWNER}>
          <SortButton sortBy="owner" />
        </RowCell>
      </RowCell>
    </Row>
  );
};

interface SortButtonProps extends ButtonProps {
  sortBy: SortByAlternativeKey<SortProjectsOptions>;
  tooltip?: string;
}

const labels: SortSelectOptions<SortProjectsOptions> = {
  name: 'Name',
  gfa: 'Area',
  updated_at: 'Updated',
  completed_at: 'Completion',
  owner: 'Owner',
  co2: 'Lifecycle',
  target: 'Target',
};

const SortButton = ({ sortBy, tooltip, ...props }: SortButtonProps) => {
  const setSortDirection = useToggleSortProjectsDirection();
  const sortProjectsBy = useSortProjectsBy();

  /* <SelectLifecycles /> */
  const handleClick = useCallback(() => {
    setSortProjectsBy(sortBy);
    setSortDirection();
  }, [setSortDirection, sortBy]);

  return (
    sortBy &&
    labels[sortBy] && (
      <Tooltip title={tooltip}>
        <Button
          fullWidth
          variant="text"
          color={sortBy === sortProjectsBy ? 'secondary' : 'neutral'}
          sx={buttonStyle}
          onClick={handleClick}
          {...props}
        >
          {labels[sortBy]}
        </Button>
      </Tooltip>
    )
  );
};

const buttonStyle: SxProps = {
  justifyContent: 'flex-start',
} as const;

export default ProjectListHeader;
