import React, { useCallback, useMemo } from 'react';
import { ButtonProps } from '@mui/material';
import { NodonTheme } from '../style';
import {
  ElementSelectPropertyCountType,
  IElementPropertyOption,
} from '../../../shared/models/element_property.interface';
import PropertyMenu from './menus/TextMenu/PropertyMenu';
import { getSBEFCode } from '../../../shared/helpers/sbef_helpers';

interface SBEFLabelProps {
  value: string;
  menuItems?: IElementPropertyOption[];
  hidden?: boolean;
  onSelect?: (label: string) => void;
}

const SBEFLabel: React.FC<SBEFLabelProps> = ({
  value,
  menuItems = [],
  hidden = false,
  onSelect,
}) => {
  const handleSelect = useCallback(
    (value: ElementSelectPropertyCountType) => {
      if (onSelect && typeof value === 'string') {
        onSelect(value);
      }
    },
    [onSelect],
  );

  const buttonProps = useMemo<ButtonProps>(() => {
    return {
      sx: {
        opacity: hidden ? 0 : 1,
        background: NodonTheme.palette.neutral.lightest,
        padding: '2px 4px',
        marginLeft: 2,
      },
    };
  }, [hidden]);

  return (
    <PropertyMenu
      buttonProps={buttonProps}
      selectedValue={value}
      selectedLabel={getSBEFCode(value)}
      options={menuItems}
      onSelect={handleSelect}
    />
  );
};

export default SBEFLabel;
