import { IConditionalNode } from '../../../../helpers/conditional-nodes.helpers';
import { required } from '../../../../helpers/function_helpers';
import {
  EnergyProductsIds,
  HeatingType,
  HeatingPropertyName,
  energyProductsIdToNameMap,
} from '../heating.model';

/**
 * Tree to select energy product depending on heating type
 */
export const conditionalEnergyProductId: IConditionalNode<
  EnergyProductsIds,
  HeatingType | HeatingPropertyName
>[] = [
  {
    property: HeatingPropertyName.Type,
    map: {
      // Heating product for heat pumps depending on heat source
      [HeatingType.HeatPump]: EnergyProductsIds.Electricity,

      // Heating product for boiles depending on fuel type
      [HeatingType.Boiler]: {
        property: HeatingPropertyName.FuelType,
        map: {
          [required(energyProductsIdToNameMap[EnergyProductsIds.FuelOil1])]:
            EnergyProductsIds.FuelOil1,
          [required(energyProductsIdToNameMap[EnergyProductsIds.FuelOil2])]:
            EnergyProductsIds.FuelOil2,
          [required(energyProductsIdToNameMap[EnergyProductsIds.WoodPellets])]:
            EnergyProductsIds.WoodPellets,
          [required(energyProductsIdToNameMap[EnergyProductsIds.Biogas])]:
            EnergyProductsIds.Biogas,
        },
      },
      [HeatingType.DistrictHeating]: EnergyProductsIds.DistrictHeating,
      [HeatingType.DirectElectricHeating]: EnergyProductsIds.Electricity,
    },
  },
];
