import { Box, Button, ButtonProps, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import SelectMenu, { SelectMenuProps } from '../SelectMenu';
import { SelectListChildren } from '../menu.model';
import { EllipsisText } from '../../EllipsisText';

const TextMenu = <T,>({
  items,
  anchor,
  labelPrefix,
  label,
  tooltip,
  disabled,
  menuWidth,
  buttonProps,
  buttonLabelProps,
  emptyStateProps,
  children,
  onOpen,
  onClose,
  onSearch,
  ...props
}: SelectMenuProps<T>) => {
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState<Element | undefined>();

  const isDisabled = disabled || items.length === 0;

  const openMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (isDisabled) return;
      // Handle outside
      if (onOpen) {
        onOpen(e.currentTarget);
      } else {
        setAnchorEl(e.currentTarget);
      }
    },
    [onOpen, isDisabled],
  );

  const closeMenu = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      setAnchorEl(undefined);
    }
  }, [onClose]);

  const renderChildren = useCallback<SelectListChildren<T>>(
    (item, style, index) => children(item, style, index),
    [children],
  );

  useEffect(() => {
    setAnchorEl(anchor);
  }, [anchor]);

  const buttonClasses = useMemo<ButtonProps['classes']>(
    () => ({
      root: classes.button,
      endIcon: classes.endIcon,
    }),
    [classes.button, classes.endIcon],
  );

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={tooltip}>
        <Button
          size="small"
          // overrideable props before buttonProps spread
          {...buttonProps}
          classes={buttonClasses}
          variant="text"
          color="inherit"
          disabled={isDisabled}
          onClick={openMenu}
        >
          <EllipsisText {...buttonLabelProps}>
            {labelPrefix && <span>{labelPrefix} </span>}
            {label}
          </EllipsisText>
        </Button>
      </Tooltip>

      <SelectMenu
        {...props}
        anchor={anchorEl}
        items={items}
        width={menuWidth}
        enableSearch={items.length > 25}
        emptyStateProps={emptyStateProps}
        onClose={closeMenu}
        onSearch={onSearch}
      >
        {renderChildren}
      </SelectMenu>
    </Box>
  );
};

const useStyles = makeStyles()(({ spacing, palette }) => ({
  button: {
    minWidth: 0,
  },
  endIcon: {
    marginLeft: spacing(1),
    color: palette.text.secondary,
  },
  itemButton: {
    fontSize: 12,
  },
}));

export default TextMenu;
