import { genericProductsLookup } from '../../../generic_products';
import { getEntries, mapObject, pick } from '../../../helpers/object_helpers';
import { IFactoryProductElement } from '../../../models/factory-element.interface';
import { ElementKind } from '../../../models/project.interface';

export enum HeatingPropertyName {
  Type = 'heating_type',

  /**
   * The Coefficient of Performance (COP) is a measure of efficiency for heating and cooling systems, particularly heat pumps, refrigerators, and air conditioners. It is defined as the ratio of useful heating or cooling provided to the work (energy) required.
   * A heat pump with a COP of 3 produces 3 units of heat (or removes heat for cooling) for every 1 unit of power consumed)
   */
  COP = 'cop',

  /**
   * The heat source is the source of heat for the heating system like, air, geothermal, water, exhaust air, hybrid.
   */
  HeatSource = 'heat_source',

  /**
   * The maximum effect is the maximum amount of heat that can be produced by the heating system (excluding).
   */
  // MaxEffect = 'max_effect',

  /**
   * The auxiliary heating effect needed to cover the peak heating demand during the coldest days of the year.
   */
  HeatPumpEffectPercent = 'heat_pump_effect',

  /**
   * The peak effect is the required heat effect of the heating system to cover the peak heating demand during the coldest days of the year.
   */
  PeakEffect = 'peak_effect',

  /**
   * The distribution system is the system used to distribute the heat to the building like, water based, air based.
   */
  DistributionSystem = 'distribution_system',

  /**
   * The fuel type is the type of fuel used to produce the heat.
   */
  FuelType = 'fuel_type',
}

export enum HeatingType {
  /**
   * District heating (fjärrvärme)
   */
  DistrictHeating = 'district_heating',
  /**
   * Heat pump (värmepump)
   */
  HeatPump = 'heat_pump',

  /**
   * Boiler (värmepanna)
   */
  Boiler = 'boiler',

  /**
   * Furnace (ugn)
   */
  // Furnace = 'furnace',

  /**
   * Direct electric heating (direktverkande elvärme)
   */
  DirectElectricHeating = 'direct_electric_heating',
}

//   [HeatingType.DistrictHeating]: DISTRICT_HEATING_PRODUCT,
//   [HeatingType.HeatPump]: ELECTRICITY_PRODUCT,
//   [HeatingType.Boiler]: ELECTRICITY_PRODUCT,
//   [HeatingType.DirectElectricHeating]: ELECTRICITY_PRODUCT,
// };

export enum HeatSourceOptions {
  Geothermal = 'geothermal',
  Water = 'water',
  ExhaustAir = 'exhaust_air',
  Air = 'air',
  // Hybrid = 'hybrid',
}

export const HEAT_PUMP_COP_DEFAULT_NAME = 'heat_pump_cop_default';
export const PEAK_EFFECT_NAME = 'peak_effect';

export const heatPumpDefaultCop: Record<HeatSourceOptions, number> = {
  [HeatSourceOptions.Air]: 3.4,
  [HeatSourceOptions.Geothermal]: 4.5,
  [HeatSourceOptions.Water]: 4.5,
  [HeatSourceOptions.ExhaustAir]: 3.4,
  // [HeatSourceOptions.Hybrid]: 3.4,
};

export enum DistributionSystemOptions {
  // AirBased = 'air_based',
  WaterBased = 'water_based',
}

export enum HeaterProducts {
  AirExchanger = 'co2data_fi_7000000426',
  /**
   * Fuel boiler < 20 kW
   */
  FuelBoiler = 'ökobaudat_0c44c3ec-2984-4985-995c-90a4881505a0',

  /**
   * Pellet boiler < 20 kW
   **/
  PelletsBoiler = 'ökobaudat_0e03a1c1-0aa9-4e94-bbc5-653d967b0d8d',

  /**
   * District heating transfer station. Average weight 1kg / kW
   */
  DistrictHeating = 'ökobaudat_dcd5e23a-9bec-40b6-b07c-1642fe696a2e',

  /**
   * Air to air heat pump 50kg / pcs. Probably a small home unit.
   */
  HeatPumpAirToAir = 'co2data_fi_7000000442',

  /**
   * Electric heat pump (air-water) 14 kW
   */
  HeatPumpAirToWater = 'ökobaudat_4a08f220-1c52-453c-bf8f-f209586e96c8',

  /**
   * Electric heat pump (geothermal collector) 70 kW. Heat pump from okobaudat:
   */
  HeatPumpGeothermal = 'ökobaudat_fbb56e56-29d6-408e-8941-fc37ed48fb11',

  /**
   * Electric heat pump (water-water) 70 kW. Heat pump from okobaudat:
   */
  HeatPumpWater = 'ökobaudat_a70d347a-239c-4e45-a8dc-e9e574dbf898',

  // TODO: FIND BETTER PRODUCT (made up now)
  DirectElectricHeating = 'nodon_direct_electric_heater',

  /**
   * Gas condensing boiler 20-120 kW (upright unit)
   */
  BiogasBoiler = 'ökobaudat_0fa37281-b976-458b-880e-46268ca7a294',
}

export enum EnergyProductsIds {
  DistrictHeating = 'boverket_sv-SE_6000000014',
  Electricity = 'boverket_sv-SE_6000000008',
  FuelOil1 = 'boverket_sv-SE_6100000004',
  FuelOil2 = 'boverket_sv-SE_6100000005',
  WoodPellets = 'boverket_sv-SE_6100000001',
  Biogas = 'boverket_sv-SE_6100000003',
}

export const boilerFuelProductsIds = [
  EnergyProductsIds.FuelOil1,
  EnergyProductsIds.FuelOil2,
  EnergyProductsIds.WoodPellets,
  EnergyProductsIds.Biogas,
] as const;

export const energyProductLoopup = pick(
  genericProductsLookup,
  ...Object.values(EnergyProductsIds),
);

export const energyProductsIdToNameMap = mapObject(
  energyProductLoopup,
  ({ name }) => name,
);
export const energyProductsNameToIdMap = Object.fromEntries(
  getEntries(energyProductLoopup).map(([id, product]) => [product.name, id]),
);

export const boilerFuelProductLoopup = pick(
  energyProductLoopup,
  ...boilerFuelProductsIds,
);

export const boilerFuelProductsNameToIdMap = Object.fromEntries(
  getEntries(boilerFuelProductLoopup).map(([id, product]) => [
    product.name,
    id,
  ]),
);

export const DESIGN_OUTDOOR_TEMP_VARIABLE_NAME = 'design_outdoor_temp';
export const HEAT_PUMP_ENERGY_VARIABLE_NAME = 'heat_pump_energy';
export const AUXILIARY_ENERGY_VARIABLE_NAME = 'auxiliary_energy';
export const PEAK_EFFECT_EXPRESSION = `getElectricPeakEffect(energy, building_position)`;
const TOTAL_HEATING_CONSUMPTION = 'energy * building_lifetime';
const HEATER_MASS_EXPRESSION = `50 + ${PEAK_EFFECT_NAME} * 10`; // Sort of works on our generic products
const HEAT_PUMP_MASS_EXPRESSION = `(${HEATER_MASS_EXPRESSION}) * ${HeatingPropertyName.HeatPumpEffectPercent}`;
const AUX_HEATER_MASS_EXPRESSION = `(${HEATER_MASS_EXPRESSION}) * (1- ${HeatingPropertyName.HeatPumpEffectPercent})`;
const HEAT_PUMP_ENERGY_EXPRESSION = `${HEAT_PUMP_ENERGY_VARIABLE_NAME} * building_lifetime`;
const AUXILIARY_ENERGY_EXPRESSION = `${AUXILIARY_ENERGY_VARIABLE_NAME} * building_lifetime`;

export const HeatingProductsExpressions: Record<HeaterProducts, string> = {
  [HeaterProducts.FuelBoiler]: HEATER_MASS_EXPRESSION,
  [HeaterProducts.PelletsBoiler]: HEATER_MASS_EXPRESSION,
  [HeaterProducts.BiogasBoiler]: HEATER_MASS_EXPRESSION,
  [HeaterProducts.DistrictHeating]: `30 + ${PEAK_EFFECT_NAME}`,
  [HeaterProducts.HeatPumpAirToAir]: HEAT_PUMP_MASS_EXPRESSION,
  [HeaterProducts.HeatPumpAirToWater]: HEAT_PUMP_MASS_EXPRESSION,
  [HeaterProducts.HeatPumpGeothermal]: HEAT_PUMP_MASS_EXPRESSION,
  [HeaterProducts.HeatPumpWater]: HEAT_PUMP_MASS_EXPRESSION,
  [HeaterProducts.AirExchanger]: HEATER_MASS_EXPRESSION,
  [HeaterProducts.DirectElectricHeating]: HEATER_MASS_EXPRESSION,
};

export const HeatingEnergyExpressions: Record<HeaterProducts, string> = {
  [HeaterProducts.FuelBoiler]: TOTAL_HEATING_CONSUMPTION,
  [HeaterProducts.PelletsBoiler]: TOTAL_HEATING_CONSUMPTION,
  [HeaterProducts.DistrictHeating]: TOTAL_HEATING_CONSUMPTION,
  [HeaterProducts.BiogasBoiler]: TOTAL_HEATING_CONSUMPTION,
  [HeaterProducts.HeatPumpAirToAir]: HEAT_PUMP_ENERGY_EXPRESSION,
  [HeaterProducts.HeatPumpAirToWater]: HEAT_PUMP_ENERGY_EXPRESSION,
  [HeaterProducts.HeatPumpGeothermal]: HEAT_PUMP_ENERGY_EXPRESSION,
  [HeaterProducts.HeatPumpWater]: HEAT_PUMP_ENERGY_EXPRESSION,
  [HeaterProducts.AirExchanger]: TOTAL_HEATING_CONSUMPTION,
  [HeaterProducts.DirectElectricHeating]: TOTAL_HEATING_CONSUMPTION,
};

export const AUX_HEATER_PRODUCT: IFactoryProductElement = {
  kind: ElementKind.Product,
  product_id: HeaterProducts.DirectElectricHeating,
  name: 'Auxiliary heater',
  count: AUX_HEATER_MASS_EXPRESSION,
  unit: 'kg',
};

export const AUX_ENERGY_PRODUCT: IFactoryProductElement = {
  kind: ElementKind.Product,
  product_id: EnergyProductsIds.Electricity,
  count: AUXILIARY_ENERGY_EXPRESSION,
  unit: 'kWh',
  name: 'Auxiliary energy',
};
