import { mapValues, capitalize } from 'lodash';
import { WorkSheet, utils, CellObject } from 'xlsx';
import { getConversionFactorValue } from '../../../shared/helpers/conversion-factors.helpers';
import { getElementName } from '../../../shared/helpers/element_helpers';
import { roundToDecimals } from '../../../shared/helpers/math_helpers';
import { getKeys } from '../../../shared/helpers/object_helpers';
import {
  getOriginalProductId,
  isBoverketProductId,
  isNodonProductId,
  getProductCategories,
  getGenericProduct,
} from '../../../shared/helpers/product_helpers';
import {
  getPathToElement,
  isProductElement,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  indentString,
  findFreeName,
} from '../../../shared/helpers/string_helpers';
import {
  IBuildingVersion,
  OneOfElementListElements,
} from '../../../shared/models/project.interface';
import { ResultsRecord } from '../../../shared/models/unit.interface';
import {
  getCount,
  getUnit,
} from '../../../shared/helpers/element_property_helpers';
import { getSBEFCountValue } from '../../../shared/helpers/sbef_helpers';
import { getProductDescription } from '../../../shared/helpers/product-element.helpers';
import { ProductSources } from '../../../shared/models/product.interface';

/**
 * Get versions with unique and clean sheet names
 * @param versions
 * @returns
 */
export const getVersionsWithUniqueNames = (
  versions: IBuildingVersion[],
): IBuildingVersion[] => {
  const versionsWithUniqueNames: IBuildingVersion[] = [];

  versions.forEach((version) => {
    const cleanSheetName = getCleanSheetName(version.name);
    if (
      versionsWithUniqueNames.length &&
      versionsWithUniqueNames.some(({ name }) => name === cleanSheetName)
    ) {
      versionsWithUniqueNames.push({
        ...version,
        name: findFreeName(
          versionsWithUniqueNames.map((uv) => uv.name),
          cleanSheetName,
        ),
      });
      return;
    }
    versionsWithUniqueNames.push({
      ...version,
      name: getCleanSheetName(version.name),
    });
  });

  return versionsWithUniqueNames;
};

export const versionToSheet = (
  version: IBuildingVersion,
  quantityRecord: ResultsRecord,
  elements: OneOfElementListElements[],
): WorkSheet => {
  const rows = elements.map((element) => {
    const factors = quantityRecord[element.id];

    if (!factors) {
      console.info(
        'Could not find conversion factors (hint: it or its parent might be deactivated) for element',
        element,
      );
    }

    const roundedValues = mapValues(factors, (value) =>
      typeof value === 'number' ? round(value) : value,
    );
    const depth = getPathToElement(version, element).length - 1;
    const sbef = getSBEFCountValue(element);

    const elementValues = {
      'SBEF name': indentString(sbef, depth),
      'Element name': getElementName(element, version.products),

      Quantity: getCount(element, false)?.resolved,
      'Quantity unit': getUnit(element, false),

      'Total SEK': getConversionFactorValue(roundedValues, 'sek'),
      'Total kg CO2e': getConversionFactorValue(roundedValues, 'co2e'),
      'SEK A1-A3': getConversionFactorValue(roundedValues, 'sek_A1-A3'),
      'kg CO2e A1-A3': getConversionFactorValue(roundedValues, 'co2e_A1-A3'),
      'SEK A4': getConversionFactorValue(roundedValues, 'sek_A4'),
      'kg CO2e A4': getConversionFactorValue(roundedValues, 'co2e_A4'),
      'SEK A5': getConversionFactorValue(roundedValues, 'sek_A5'),
      'kg CO2e A5': getConversionFactorValue(roundedValues, 'co2e_A5'),
      'SEK B2': getConversionFactorValue(roundedValues, 'sek_B2'),
      'kg CO2e B2': getConversionFactorValue(roundedValues, 'co2e_B2'),
      'SEK B4': getConversionFactorValue(roundedValues, 'sek_B4'),
      'kg CO2e B4': getConversionFactorValue(roundedValues, 'co2e_B4'),
      'SEK B6': getConversionFactorValue(roundedValues, 'sek_B6'),
      'kg CO2e B6': getConversionFactorValue(roundedValues, 'co2e_B6'),

      'Mass (kg)': getConversionFactorValue(roundedValues, 'kg'),
      'EPD description': getProductDescription(element, version),

      Source: '',
      'Source ID': '',
      'Source URL': '',

      'Category Boverket': '',
      'Category BK04': '',
    };

    const product =
      isProductElement(element) && version.products[element.product_id];

    if (product) {
      const id = getOriginalProductId(product);
      const isBoverket = isBoverketProductId(id);
      const isNodon = isNodonProductId(id);

      const genericProduct = product.generic_id
        ? getGenericProduct(version, product.generic_id)
        : undefined;

      const source =
        product.source === ProductSources.Custom
          ? 'EPD'
          : capitalize(product.source);

      elementValues['Source'] = source;
      elementValues['Source ID'] = id;

      elementValues['Category Boverket'] = getProductCategories(
        genericProduct ?? product,
        'Boverket',
      ).join(', ');
      elementValues['Category BK04'] = getProductCategories(
        genericProduct ?? product,
        'BK04',
      ).join(', ');

      if (isBoverket || isNodon) {
        elementValues['Source URL'] =
          `https://www.boverket.se/sv/klimatdeklaration/klimatdatabas/klimatdatabas/Search/?version=3&climatedatabasequery=${id}`;
      }
    }

    return elementValues;
  });
  const sheet = utils.json_to_sheet(rows, { cellStyles: true });
  const columns = getKeys(rows[0]);
  const sourceUrlIndex = columns.indexOf('Source URL');

  // Make hyperlinks of source urls
  rows.forEach((row, index) => {
    const cell = getCell(sheet, sourceUrlIndex, index);
    utils.cell_set_hyperlink(
      cell,
      row['Source URL'],
      'Länk till Boverkets databas',
    );
  });

  return sheet;
};

const round = (value: number): number => roundToDecimals(value, 2);

/**
 * Sheet name can be max 31 characters and not have certain characters like ? and /
 * @param version
 * @returns
 */
const getCleanSheetName = (name: string) =>
  name
    .replace(/[^\w .,:åäö]/gi, '')
    .toLowerCase()
    .substring(0, 28); // 28 to make room for version number if name already exists

const getCell = (sheet: WorkSheet, col: number, row: number): CellObject => {
  const cell = sheet[getCellName(col, row)];
  if (!cell) {
    throw new Error('Cell not found');
  }
  return cell as CellObject;
};

const getCellName = (col: number, row: number, hasHeader = true): string => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return `${letters[col]}${row + (hasHeader ? 2 : 1)}`;
};
