import {
  ButtonProps,
  ListItemButtonProps,
  PopoverProps,
  SvgIconProps,
  TypographyProps,
} from '@mui/material';
import { IProjectInfo } from '../../../../shared/models/project.interface';
import { IProjectFolder } from '../../../../shared/models/folder.interface';

export enum SelectMenuWidhts {
  small = 200,
  medium = 300,
  large = 600,
}

export type SelectMenuWidth = keyof typeof SelectMenuWidhts;

export type SelectListChildren<T> = (
  item: T,
  style?: React.CSSProperties,
  index?: number,
) => React.ReactNode;

type PopoverPropsSubset = Omit<
  PopoverProps,
  'children' | 'open' | 'onClose' | 'onSelect' | 'id'
>;

export interface NodonMenuProps extends PopoverPropsSubset {
  menuWidth?: SelectMenuWidth | number;
  buttonProps?: ButtonProps;
  buttonLabelProps?: TypographyProps;
  label?: React.ReactNode;
  labelPrefix?: string;
  tooltip?: string;
  disabled?: boolean;
  anchor?: Element;
  onOpen?: (anchor?: Element) => void;
  onClose?: () => void;
  onEdit?: () => void;
  onSearch?: (searchTerm: string) => void;
}

export interface NodonMenuItemProps extends ListItemButtonProps {
  id: string;
  label: string;
  icon?: JSX.Element;
  /**
   * Pass boolean to show checkbox (undefined will not show checkbox)
   */
  checked?: boolean;
  checkboxColor?: string;
  tooltip?: string;
  items?: NodonMenuItemProps[];
  onClick?: (...args: any[]) => Promise<any> | void | undefined;
}

export interface KebabButtonProps extends ButtonProps {
  svgProps?: SvgIconProps;
  verticalIcon?: boolean;
}

export interface KebabMenuProps extends NodonMenuProps {
  hidden?: boolean;
  buttonProps?: Partial<KebabButtonProps>;
}

export interface ProjectListItemMenuProps extends KebabMenuProps {
  item: IProjectInfo | IProjectFolder;
}

export type ProjectMenuItemId =
  | 'archive'
  | 'delete'
  | 'duplicate'
  | 'export'
  | 'export_spreadsheet'
  | 'import'
  | 'lock'
  | 'edit'
  | 'rename'
  | 'template'
  | 'unarchive'
  | 'unlock'
  | 'update_products';

export interface ProjectMenuProps extends NodonMenuItemProps {
  id: ProjectMenuItemId;
  /**
   * Place item at index or after another menu item
   */
  placement?: number | ProjectMenuItemId;
}
