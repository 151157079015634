import {
  ElementCategoryID,
  type IElementCategory,
} from '../../../models/element_categories.interface';
import { isEnvironment } from '../../../helpers/environment.helpers';
import { ElementKind } from '../../../models/project.interface';
import { getValueFromConditionalNodes } from '../../../helpers/conditional-nodes.helpers';
import { moveLifecycleAResults } from '../../../helpers/conversion-factors.helpers';
import { Lifecycle } from '../../../models/lifecycles.interface';
import {
  HeatingPropertyName,
  heatPumpDefaultCop,
  HEAT_PUMP_COP_DEFAULT_NAME,
  HeatingEnergyExpressions,
  HeatingProductsExpressions,
  HeaterProducts,
  DESIGN_OUTDOOR_TEMP_VARIABLE_NAME,
  HEAT_PUMP_ENERGY_VARIABLE_NAME,
  AUXILIARY_ENERGY_VARIABLE_NAME,
  HeatingType,
  AUX_HEATER_PRODUCT,
  AUX_ENERGY_PRODUCT,
} from './heating.model';
import { getDesignOutdoorTemp } from '../../../helpers/energy/temperature.helpers';
import { getHeatPumpEnergyConsumption } from '../../../helpers/energy/heating-energy.helpers';
import { toNumber } from '../../../helpers/math_helpers';
import { conditionalHeaterProductId } from './conditions/heating-system-product-id';
import { conditionalEnergyProductId } from './conditions/energy-product-id';
import { heatingProperties } from './heating-properties';
import { isOneOf } from '../../../helpers/array_helpers';
import { required } from '../../../helpers/function_helpers';
import { getElementPropertyResolvedCountByNameOrId } from '../../../helpers/element_property_helpers';

// Show "Annual energy requirement" as Energy property name instead
const getQuantityPropertyLabel: IElementCategory['getQuantityPropertyLabel'] = (
  property,
) => {
  if (property.name === 'energy') {
    return 'Annual energy requirement';
  }
};

/**
 * Add properties that are resolved before trying to resolve properties
 * @param variables
 * @returns
 */
const mapExpressionVariablesBefore: IElementCategory['mapExpressionVariablesAfter'] =
  (variables) => ({
    ...variables,
    [HEAT_PUMP_COP_DEFAULT_NAME]: heatPumpDefaultCop,
    [DESIGN_OUTDOOR_TEMP_VARIABLE_NAME]: getDesignOutdoorTemp(
      variables.building_position,
    ),
  });

/**
 * Add properties that are resolved after trying to resolve properties
 * @param variables
 * @returns
 */
const mapExpressionVariablesAfter: IElementCategory['mapExpressionVariablesAfter'] =
  (variables) => {
    const energy = toNumber(variables['energy']);
    const heatPumpEffect = toNumber(
      variables[HeatingPropertyName.HeatPumpEffectPercent],
    );
    const cop = toNumber(variables[HeatingPropertyName.COP]);

    const { heatPumpEnergy, auxiliaryEnergy } = getHeatPumpEnergyConsumption(
      energy,
      heatPumpEffect,
      cop,
    );

    return {
      ...variables,
      [HEAT_PUMP_ENERGY_VARIABLE_NAME]: heatPumpEnergy,
      [AUXILIARY_ENERGY_VARIABLE_NAME]: auxiliaryEnergy,
    };
  };

const mapResults: IElementCategory['mapResults'] = (results, context) => {
  // We have two products so only move the energy results
  if (context.productElement.unit === 'kWh') {
    return moveLifecycleAResults(results, Lifecycle.B6);
  }
  return results;
};

export const heating: IElementCategory = {
  id: ElementCategoryID.Heating,
  name: 'Heating',
  disabled: isEnvironment('production'),
  properties: heatingProperties,
  getQuantityProperties: () => ({ energy: {} }),
  getQuantityPropertyLabel,
  mapResults,
  mapExpressionVariablesBefore,
  mapExpressionVariablesAfter,
  getChildElements: (element) => {
    // Heat pumps should get additional aux heating
    const hasAuxiliaryHeating =
      getElementPropertyResolvedCountByNameOrId(
        element,
        HeatingPropertyName.Type,
      ) === HeatingType.HeatPump;
    const heaterProductId = getValueFromConditionalNodes(
      element,
      conditionalHeaterProductId,
    );
    const energyProductId = getValueFromConditionalNodes(
      element,
      conditionalEnergyProductId,
    );

    return [
      // Heat producer
      {
        kind: ElementKind.Product,
        product_id: heaterProductId,
        count: HeatingProductsExpressions[heaterProductId],
        unit: 'kg',
      },
      // Energy consumption
      {
        kind: ElementKind.Product,
        product_id: energyProductId,
        count: required(
          HeatingEnergyExpressions[validateHeaterProductId(heaterProductId)],
        ),
        unit: 'kWh',
      },
      // (optional) Auxiliary heating
      ...(hasAuxiliaryHeating ? [AUX_HEATER_PRODUCT, AUX_ENERGY_PRODUCT] : []),
    ];
  },
};

const validateHeaterProductId = (id?: string): HeaterProducts => {
  if (!isOneOf(Object.values(HeaterProducts), id)) {
    throw new Error(`Invalid heater product id: ${id}`);
  }
  return id as HeaterProducts;
};
