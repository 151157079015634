import React, { ReactNode, FC } from 'react';
import { useEllipsesStyles } from '../style/ellipsis';
import { Tooltip, Typography, TypographyProps } from '@mui/material';

interface EllipsisTextProps extends TypographyProps {
  children?: ReactNode;
  showTooltip?: boolean;
  tooltipTitle?: string;
  isDeactivated?: boolean;
}

export const EllipsisText: FC<EllipsisTextProps> = ({
  children,
  showTooltip = false,
  tooltipTitle,
  ...typographyProps
}) => {
  const { classes } = useEllipsesStyles();
  return (
    <Tooltip
      title={tooltipTitle ?? children}
      disableHoverListener={!showTooltip && !tooltipTitle}
      disableInteractive
    >
      <Typography
        component="span"
        fontSize="inherit"
        color="inherit"
        fontWeight="inherit"
        lineHeight="inherit"
        className={classes.ellipsis}
        {...typographyProps}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
