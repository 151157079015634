import { Lifecycle, lifecycles } from '../models/lifecycles.interface';
import { CO2eUnit, CostUnit, Results } from '../models/unit.interface';
import { validateConversionFactorUnit } from '../validation/conversion-factor.validation';
import { isDefined, isOneOf } from './array_helpers';
import { omit } from './object_helpers';

const COST_PREFIX = 'sek_';
const CO2E_PREFIX = 'co2e_';

/**
 * Check if the value is a valid lifecycle.
 * @param value - The value to check.
 * @returns True if the value is a valid lifecycle, false otherwise.
 */
export const isLifecycle = (value: unknown): value is Lifecycle => {
  return isOneOf(lifecycles, value);
};

/**
 * Throw error if lifecycle is not valid.
 * @param lifecycle
 * @returns
 */
export const validateLifecycle = (lifecycle: unknown): Lifecycle => {
  if (!isLifecycle(lifecycle)) {
    throw new Error(`Invalid lifecycle: ${String(lifecycle)}`);
  }
  return lifecycle;
};

type LifecycleObject = { stage: string; phase: number };
const singleRegexp = /^([ABCD])(\d+)$/;
const rangeRegexp = /^([ABCD])(\d+)-([ABCD])(\d+)$/;

const parseSingleLifecycle = (lifecycle: string): LifecycleObject | null => {
  const [, stage, phase] = singleRegexp.exec(lifecycle) ?? [];
  if (stage !== undefined && phase !== undefined) {
    return { stage, phase: Number(phase) };
  }
  return null;
};
const parseLifecycle = (lifecycle: string): LifecycleObject[] | [null] => {
  const lifeCycleObj = parseSingleLifecycle(lifecycle);
  if (lifeCycleObj) return [lifeCycleObj];
  if (rangeRegexp.test(lifecycle)) {
    const [start, end] = lifecycle.split('-').map(parseSingleLifecycle);
    if (start === null || end === null)
      throw new Error(`Invalid lifecycle range: ${lifecycle}`);
    if (start && end && start.stage === end.stage && start.phase < end.phase) {
      const expanded: LifecycleObject[] = [];
      for (let i = start.phase; i <= end.phase; i++) {
        expanded.push({ stage: start.stage, phase: i });
      }
      return expanded;
    }
  }
  return [null];
};

const abbreviateLifecycles = (lifecycles: (Lifecycle | string)[]): string => {
  const cyclesMap = Array.from(lifecycles)
    .sort()
    .reduce<Record<string, number[]>>((accumulator, lifecycle) => {
      parseLifecycle(lifecycle)
        .filter(isDefined)
        .forEach(({ stage, phase }) => {
          // The stage arrays are sparse, leaving gaps between ranges
          accumulator[stage] = accumulator[stage] ?? [];
          accumulator[stage][phase] = phase;
        });
      return accumulator;
    }, {});

  return Object.entries(cyclesMap)
    .map(([stage, phases]) => {
      const output: string[] = [];

      let start: number | undefined = undefined;
      let end: number | undefined = undefined;

      for (let i = 0; i < phases.length; i++) {
        const nextVal = phases[i + 1];

        if (start === undefined) {
          // Didn't find first value yet, set start and end to next
          end = start = nextVal;
        } else if (nextVal) {
          // Next value exists, continue walking
          end = nextVal;
        } else if (nextVal === undefined) {
          // Gap found, add to output and flush
          const startStr = `${stage}${start}`;
          const endStr = `${stage}${end}`;
          // Single value, push alone
          if (start === end) output.push(startStr);
          // Range, push dash separated
          else output.push(`${startStr}-${endStr}`);

          // Reset for next range
          start = end = undefined;
        }
      }
      return output;
    })
    .flat()
    .join(', ');
};

export const getLifecycleLabel = (
  lifecycles: (Lifecycle | string)[],
): string => {
  lifecycles.forEach(validateLifecycle);
  if (lifecycles.length === 0) {
    return 'No lifecycles selected';
  }
  return abbreviateLifecycles(lifecycles);
};

/**
 * Get the cost unit (sek_A1-A3 etc) from the lifecycle.
 * @param lifecycle - The lifecycle to get the cost unit from.
 * @returns The cost unit.
 */
export const getCostUnitFromLifecycle = (lifecycle: Lifecycle): CostUnit =>
  validateConversionFactorUnit(
    COST_PREFIX + validateLifecycle(lifecycle),
  ) as CostUnit;

/**
 * Get the CO2e unit (co2e_A1-A3 etc) from the lifecycle.
 * @param lifecycle - The lifecycle to get the CO2e unit from.
 * @returns The CO2e unit.
 */
export const getCO2eUnitFromLifecycle = (lifecycle: Lifecycle): CO2eUnit =>
  validateConversionFactorUnit(
    CO2E_PREFIX + validateLifecycle(lifecycle),
  ) as CO2eUnit;

export const omitLifecyclesFromResults = (
  results: Results,
  excludeLifecycles: Lifecycle[],
): Results => {
  const costUnits = excludeLifecycles.map(getCostUnitFromLifecycle);
  const co2eUnits = excludeLifecycles.map(getCO2eUnitFromLifecycle);

  return omit(results, ...costUnits, ...co2eUnits);
};

export const filterResultsByLifecycles = (
  results: Results,
  includeLifecycles: Lifecycle[],
): Results => {
  const excludeLifecycles = lifecycles.filter(
    (lifecycle) => !includeLifecycles.includes(lifecycle),
  );
  return omitLifecyclesFromResults(results, excludeLifecycles);
};
