import { isElementCategoryID } from '../helpers/element_category_helpers';
import { isCloseTo } from '../helpers/math_helpers';
import { getMissingProperties } from '../helpers/object_helpers';
import {
  getProductSourceFromId,
  isCustomProductId,
  isBoverketProductId,
  isOekobaudatProductId,
  isNodonProductId,
  getOriginalProductId,
  isCo2DataFiProductId,
} from '../helpers/product_helpers';
import { makeSentence } from '../helpers/string_helpers';
import { isConversionFactorQuantityUnit } from '../helpers/unit_helpers';
import {
  IProduct,
  IFactoryProduct,
  REQUIRED_PRODUCT_PROPERTIES,
  CUSTOM_ID_PREFIX,
  ProductSources,
  ProductID,
} from '../models/product.interface';
import { throwValidationErrors, ValidationTypes } from './validation.helpers';

/**
 * Validate product
 * @param product
 * @param verbose
 * @returns string for error or true for valid
 */
export const isValidProduct = (
  product: IProduct | IFactoryProduct | undefined,
): ValidationTypes => {
  if (!product) {
    return 'Product is not defined';
  }

  const missingProperties = getMissingProperties(
    product,
    ...REQUIRED_PRODUCT_PROPERTIES,
  );

  if (missingProperties.length) {
    return (
      'Product is missing properties: ' + makeSentence(...missingProperties)
    );
  }
  const {
    id,
    name,
    source,
    categories,
    organizations,
    unit,
    generic_id,
    category_property_value_record = {},
    conversion_factors,
    category_id,
  } = product as IProduct;
  const idSource = getProductSourceFromId(id);

  if (generic_id) {
    if (!isCustomProductId(id)) {
      return 'Product has a generic_id but is not a custom product';
    }
    const validId = isValidGenericProductId(generic_id);
    if (validId !== true) {
      return validId;
    }
  }

  if (Object.keys(category_property_value_record).length && !category_id) {
    return 'Product has category_property_value_record but no category_id';
  }

  if (category_id && !isElementCategoryID(category_id)) {
    return 'Product has invalid category_id';
  }

  if (!name) {
    return 'Product has no name';
  }

  if (idSource !== source) {
    return 'Product id and source is not matching';
  }

  if (!isConversionFactorQuantityUnit(unit)) {
    return 'Product has invalid unit ' + unit;
  }

  if (!isCloseTo(conversion_factors[unit], 1)) {
    return 'Selected unit is not 1 in conversion factors';
  }

  // Only for custom products
  if (source === ProductSources.Custom) {
    if (!organizations?.length) {
      return 'Custom product has no organizations';
    }
    if (categories.Custom !== true) {
      return 'Custom product has no custom category';
    }
  }

  // const validConversionFactors = isValidConversionFactors(conversion_factors);

  // if (validConversionFactors !== true) {
  //   return validConversionFactors;
  // }

  return isValidProductId(id);
};

/**
 * Validate product id
 * @param id
 * @returns true if valid, otherwise an error message
 */
const isValidProductId = (id: unknown): ValidationTypes => {
  if (typeof id !== 'string') {
    return 'Product id is not a string';
  }
  if (
    !isOekobaudatProductId(id) &&
    !isBoverketProductId(id) &&
    !isCustomProductId(id) &&
    !isNodonProductId(id) &&
    !isCo2DataFiProductId(id)
  ) {
    return 'Invalid product id prefix';
  }
  const suffix = getOriginalProductId(id).replace(CUSTOM_ID_PREFIX, '');
  if (suffix.length < 7) {
    return 'Invalid product id suffix';
  }
  return true;
};

export const validateProduct = (
  product: IProduct | IFactoryProduct | undefined,
  onError?: (error: string) => void,
): IProduct => {
  throwValidationErrors(
    isValidProduct(product),
    'ProductValidationError',
    onError,
  );
  return product as IProduct;
};

export const validateGenericProductId = (
  id: string | number | undefined,
): ProductID => {
  throwValidationErrors(isValidGenericProductId(id), 'ProductValidationError');
  return id as ProductID;
};

export const isValidGenericProductId = (
  id: string | number | undefined,
): ValidationTypes => {
  if (isValidProductId(id) !== true || isCustomProductId(id as string)) {
    return 'Invalid generic product id';
  }

  return true;
};
