import { ICoordinate } from '../../models/project.interface';
import {
  DEFAULT_INDOOR_TEMP,
  HeatPumpAuxilaryEffectRatio,
  HEAT_PUMP_EFFECT_RATIO,
  STOCKHOLM_COORDINATE,
} from './energy.models';
import {
  getDegreeHoursFromCoordinate,
  getDesignOutdoorTemp,
  getNormalYearTemp,
} from './temperature.helpers';
import { clamp } from '../math_helpers';

/**
 * Coefficient of Performance (COP) is the ratio of the heat output of the heat pump to the electrical input.
 * The higher the COP, the more efficient the heat pump.
 */
export const HEAT_PUMP_DEFAULT_COP = 3.5;

/**
 * The COP typically decreases by about 30-40% at design temperature (when it's as cold as possible).
 */
export const HEAT_ENERGY_DEFAULT_COP_DEGRADATION = 0.65;

export const HEAT_ENERGY_DEFAULT_ELECTRIC_PEAK_EFFECT = 0.5;
export const HEAT_ENERGY_DEFAULT_MAX_EFFECT = 12;

interface ICalculateElectricEffectOptions {
  /**
   * The location of the building.
   * Default is Stockholm.
   */
  location?: ICoordinate;

  /**
   * The indoor temperature in °C.
   */
  indoorTemp?: number;

  /**
   * Which temperature (°C) to use to dimension the heat pump effect (kW).
   * Default is the normal year temperature for given location.
   */
  dimensioningTemp?: number;

  /**
   * The Coefficient of Performance (COP) of the heat pump.
   * 3 means that for every 1 kW of electricity, the heat pump can produce 3 kW of heat.
   * 1 means that the heat pump is using 100% direct electric heating.
   */
  cop?: number; // Coefficient of Performance

  /**
   * The ratio of the heat pump effect to the auxiliary heating effect.
   * Default is 0.7.
   */
  effectRatio?: HeatPumpAuxilaryEffectRatio;
}

/**
 * Calculate the electric effect (in kW) required to heat a building with a given yearly heat consumption.
 * Pass cop = 1 to get the heating effect.
 * @param annualHeatRequirement - The annual heat requirement in kWh/year
 * @param options - The options for the calculation
 * @returns The electric effect in kW
 */
export const calculateElectricEffect = (
  annualHeatRequirement: number,
  {
    location = STOCKHOLM_COORDINATE,
    dimensioningTemp = getNormalYearTemp(location),
    indoorTemp = DEFAULT_INDOOR_TEMP,
    cop = 1, // Note passing 1 will result in heatingEffect = electricEffect
  }: ICalculateElectricEffectOptions = {},
): number => {
  // "hours x tempDiff" based on coordinates (currently uses a lot of template values...)
  const degreeHours = getDegreeHoursFromCoordinate(location);

  // Calculate temperature difference
  const deltaT = indoorTemp - dimensioningTemp;

  // How many hours per year should heating be active based on the dimensioning temperature
  const hours = degreeHours / deltaT;

  // the heating effect (in kW) that a building needs to handle outdoor temperature.
  const thermalEffect = annualHeatRequirement / hours;

  // Divide with Coefficient of Performance (COP), basically effiency to get the electric effect
  // A heat pump with a COP of 3.5 will produce 3.5 kW of thermalEffect for every 1 kW of electric effect.
  return thermalEffect / cop;
};

/**
 * Calculate the electric peak effect (in kW) for a heat pump.
 * Electric peak effect is the maximum electrical input (in kW) that a heat pump requires during the coldest design day of the year.
 * @param annualHeatRequirement - The annual heat requirement in kWh/year
 * @param options - The options for the calculation
 * @returns The electric peak effect in kW
 */
export const calculateElectricPeakEffect = (
  annualHeatRequirement: number,
  {
    location = STOCKHOLM_COORDINATE,
    indoorTemp = DEFAULT_INDOOR_TEMP,
    cop = 1,
  }: Omit<ICalculateElectricEffectOptions, 'dimensioningTemp'> = {},
): number =>
  calculateElectricEffect(annualHeatRequirement, {
    location,
    dimensioningTemp: getDesignOutdoorTemp(location),
    indoorTemp,
    cop,
  });

/**
 * Calculate the total energy consumption of a heating system.
 * @param annualHeatRequirement - The annual heat requirement in kWh/year
 * @param maxEffect - The maximum effect (kW) of the heat pump (excluding auxiliary heating)
 * @param auxiliaryEffect - The auxiliary effect (kW) of the heating system (excluding max effect)
 * @param cop - The coefficient of performance of the heating system
 */
export const getHeatPumpEnergyConsumption = (
  annualHeatRequirement: number,
  heatPumpEffectPercentage: number,
  cop: number,
): { heatPumpEnergy: number; auxiliaryEnergy: number } => {
  const auxEnergyPercentage = calculateAuxilaryEnergyCorrelation(
    1 - heatPumpEffectPercentage,
  );
  const auxEnergy = annualHeatRequirement * auxEnergyPercentage;

  return {
    heatPumpEnergy: (annualHeatRequirement - auxEnergy) / cop,
    auxiliaryEnergy: auxEnergy,
  };
};

/**
 * They say that if you cover 70% of the peak effect with the heat pump that is 95% of the energy consumption.
 * @param auxiliaryEffectPercentage - The percentage of the auxiliary effect to the total effect
 * @param effectRatio - The ratio of the heat pump effect to the auxiliary heating effect
 * @returns The percentage of the auxiliary energy to the total energy consumption
 */
export const calculateAuxilaryEnergyCorrelation = (
  auxiliaryEffectPercentage: number,
  { effect, energy }: HeatPumpAuxilaryEffectRatio = HEAT_PUMP_EFFECT_RATIO,
): number => {
  const percent = clamp(auxiliaryEffectPercentage, 0, 1);
  const auxEffectLimit = 1 - effect;
  const auxEnergyLimit = 1 - energy;
  if (percent <= 0) return 0;
  if (percent <= auxEffectLimit) {
    // Linear interpolation between (0,0) and (0.3, 0.05)
    return (auxEnergyLimit / auxEffectLimit) * percent;
  }
  // Exponential curve between (0.3, 0.05) and (1, 1)
  return (
    auxEnergyLimit + energy * Math.pow((percent - auxEffectLimit) / effect, 2)
  );
};
