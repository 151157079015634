import { uniq } from 'lodash';
import { IProduct } from './product.interface';
import { IElement } from './project.interface';
import { PartialRecord } from './type_helpers.interface';

export enum FactorUnitLabels {
  co2e = 'kgCO2e',
  cost = 'kSEK',
  perGFA = 'per GFA',
}

export type EmissionCostLabel = 'kgCO2e' | 'kSEK';

/**
 * Quantity units (no co2/cost units) available in the conversion factors.
 * DO NOT STORE ANY OTHER UNITS IN THE CONVERSION FACTORS THAT ARE NOT THESE OR CO2/COST.
 */
export const conversionFactorQuantityUnits = [
  'm',
  'm²',
  'm³',
  'kg',
  'pcs',
  'kWh',
  'kg/m³',
  'kg/m²',
  'hr',
] as const;

/**
 * Unit that is a ratio of other units.
 */
const ratioUnits = ['kg/m³', 'kg/m²', '%'] as const;

/**
 * Geometric units that are used in the UI.
 */
const geometricUnits = ['°'] as const;

/**
 * Thermal transmittance / Värmegenomgångskoefficienten: u = W/(m2⋅K)
 */
const thermalTransmittanceUnits = ['W/m²K'] as const;

/**
 * Ventilation-related units
 */
const ventilationUnits = [
  'm³/s', // Airflow
  'kW/m³s', // Specific Fan Power (SFP)
  'l/m²s', // Supply Air Flow per GFA, Exhaust Air Flow per GFA
] as const;
export type VentilationUnit = (typeof ventilationUnits)[number];

const thermalConductivityUnits = ['W/mK', 'u'] as const;

/**
 * Units that is calculated from another unit.
 * 100mm = 0.1m³ (per m²)
 */
export const calculatedUnits = ['kWh'] as const;
export type CalculatedUnit = (typeof calculatedUnits)[number];

/**
 * Cost units that are available in ConversionFactors
 */
export const costUnitsA = ['sek_A1-A3', 'sek_A4', 'sek_A5'] as const;
export const costUnitsB = ['sek_B2', 'sek_B4', 'sek_B6'] as const;

export const costUnits = [...costUnitsA, ...costUnitsB] as const;
export type CostUnitA = (typeof costUnitsA)[number];
export type CostUnitB = (typeof costUnitsB)[number];
export type CostUnit = (typeof costUnits)[number];

export type UnitA = CostUnitA | CO2eUnitA;
export type UnitB = CostUnitB | CO2eUnitB;

export const energyUnits = ['MJ', 'kWh'] as const;
export type EnergyUnit = (typeof energyUnits)[number];

export const effectUnits = ['W', 'kW'] as const;
export type EffectUnit = (typeof effectUnits)[number];

/**
 * CO2 values that are available in ConversionFactors for lifecycle stage A
 */
export const co2UnitsA = [
  'co2e_A1-A3', // Production
  'co2e_A4', // Transport
  'co2e_A5', // Waste
  // 'co2e_A', // Total for A phase
] as const;

/**
 * CO2 values that are available in ConversionFactors for lifecycle stage B
 */
export const co2UnitsB = [
  'co2e_B2', // Maintenance
  'co2e_B4', // Lifetime
  'co2e_B6', // Operational energy use
] as const;

export const co2Units = [...co2UnitsA, ...co2UnitsB] as const;

export type CO2eUnitA = (typeof co2UnitsA)[number];
export type CO2eUnitB = (typeof co2UnitsB)[number];
export type CO2eUnit = (typeof co2Units)[number];

export const unitsA = [...co2UnitsA, ...costUnitsA] as const;
export const unitsB = [...co2UnitsB, ...costUnitsB] as const;

/**
 * Pressure units
 */
const pressureUnits = ['Pa', 'kPa', 'bar'] as const;

/**
 * Time units
 */
const timeUnits = ['s', 'min', 'hr', 'd', 'year', 'hrs/year'] as const;

/**
 * Velocity units
 */
const velocityUnits = ['m/s', 'km/h'] as const;

/**
 * Temperature units
 */
const temperatureUnits = ['°C', 'K', '°F'] as const;

/**
 * Volume flow units
 */
const volumeFlowUnits = ['m³/s', 'l/s', 'm³/h'] as const;

/**
 * Sound units
 */
const soundUnits = ['dB', 'dBA'] as const;

/**
 * Light units
 */
const lightUnits = ['lm', 'lx'] as const;

export type PressureUnit = (typeof pressureUnits)[number];
export type TimeUnit = (typeof timeUnits)[number];
export type VelocityUnit = (typeof velocityUnits)[number];
export type TemperatureUnit = (typeof temperatureUnits)[number];
export type VolumeFlowUnit = (typeof volumeFlowUnits)[number];
export type SoundUnit = (typeof soundUnits)[number];
export type LightUnit = (typeof lightUnits)[number];

/**
 * Units that can be used in the conversion factors.
 * NO OTHER UNITS ARE ALLOWED.
 */
export const conversionFactorUnits = [
  ...conversionFactorQuantityUnits,
  ...co2Units,
  ...costUnits,
] as const;

/**
 * All available units. Some units are connected to the building lifecycle.
 * Read more here: https://oneclicklca.zendesk.com/hc/en-us/articles/360015064999-Life-Cycle-Stages
 */
export const quantityUnits = [
  'none', // empty unit that's hidden in the UI
  ...conversionFactorQuantityUnits,
  ...energyUnits,
  ...effectUnits,
  ...calculatedUnits,
  ...costUnits,
  ...co2Units,
  ...ratioUnits,
  ...geometricUnits,
  ...thermalConductivityUnits,
  ...thermalTransmittanceUnits,
  ...temperatureUnits,
  ...ventilationUnits,
  ...timeUnits,
] as const;

/**
 * Units available in ConversionFactors
 */
export type ConversionFactorUnit = (typeof conversionFactorUnits)[number];

/**
 * Units that can be selected as unit for a IProduct.
 * These units are not CO2e or cost units.
 */
export type ConversionFactorQuantityUnit =
  (typeof conversionFactorQuantityUnits)[number];

/**
 * Unit enum including CO2e and cost units
 */
export type QuantityUnit = (typeof quantityUnits)[number];

export const selectableQuantityUnits = uniq([
  ...conversionFactorQuantityUnits,
  ...energyUnits,
  ...ratioUnits,
] as const);

/**
 * Units selectable in the dropdowns. TODO: Support more units than just the ConversionFactorUnit
 */
export type SelectableQuantityUnit = (typeof selectableQuantityUnits)[number];

/**
 * A record containing any kind of factors
 */
export type ConversionFactors = PartialRecord<ConversionFactorUnit, number>;

export const conversionFactorGroupKeys = [
  'A',
  'B',
  'co2e',
  'co2e_A',
  'co2e_B',
  'sek',
  'sek_A',
  'sek_B',
] as const;

export type ConversionFactorGroupKey =
  (typeof conversionFactorGroupKeys)[number];

/**
 * Results is conversionFactors with enforced co2e values.
 * Typically used to sum up all co2e values in children.
 * Life cycle stages according to EN 15978:
 * A1-A3: Product stage (raw material supply, transport, manufacturing)
 * A4: Transport to construction site
 * A5: Construction/installation
 * B1: Use (emissions from installed products)
 * B2: Maintenance
 * B3: Repair
 * B4: Replacement
 * B5: Refurbishment
 * B6: Operational energy use
 * B7: Operational water use
 * C1: Deconstruction/demolition
 * C2: Transport to waste processing
 * C3: Waste processing
 * C4: Disposal
 * D: Benefits and loads beyond system boundary (reuse, recovery, recycling)
 */
export type Results = ConversionFactors & Partial<Record<CO2eUnit, number>>; // Currently no difference between conversion factors and results
/**
 * Record of quantities for each element or product
 */
export type ResultsRecord = Record<IElement['id'], Results>;

export type PartialConversionFactorQuantityRecord = Record<
  string,
  Partial<Results>
>;

/**
 * Empty ConversionFactors with zero values.
 * Don't used this unless you have a good reason since now undefined values are allowed (and preferred)
 */
export const emptyConversionFactors: Readonly<Results> = Object.freeze({
  'co2e_A1-A3': 0,
  co2e_A4: 0,
  co2e_A5: 0,
});

/**
 * Helper type to not having to pass products around to all functions requiring only conversion_factors
 */
export type ProductOrConversionFactors =
  | Pick<IProduct, 'conversion_factors'>
  | ConversionFactors;
