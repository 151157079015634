import React, { useMemo } from 'react';
import { IElement } from '../../../../../shared/models/project.interface';
import { Box, Divider } from '@mui/material';
import ElementProperties from './Property/ElementProperties';
import { useQuantityProperties } from '../../../hooks/quantity-properties.hook';
import { ElementCategoryID } from '../../../../../shared/models/element_categories.interface';
import {
  getElementQuantityResolvedCountByName,
  isElementQuantityRepeatingChildName,
} from '../../../../../shared/helpers/element_quantity_helpers';
import {
  IconQuantityName,
  iconQuantityNames,
} from '../../../../../shared/models/element_quantities.interface';
import { dividerStyles } from '../../../style/constants';
import { sortInOrderOf } from '../../../../../shared/helpers/array_helpers';

interface ElementQuantitiesProps {
  element: IElement;
}

const ElementQuantities: React.FC<ElementQuantitiesProps> = ({ element }) => {
  const quantityProps = useQuantityProperties(element);

  const iconProperties = useAlwaysVisibleQuantities(element);

  const otherProperties = useMemo(() => {
    const properties = quantityProps.filter(
      ({ name }) => !iconQuantityNames.includes(name as IconQuantityName),
    );
    const showRepeatingItems = getElementQuantityResolvedCountByName(
      element,
      'repeating_items',
    );

    const quantities = showRepeatingItems
      ? properties.filter(
          ({ name }) =>
            !['repeating_item_count', 'repeating_direction'].includes(name), // hide these for now
        )
      : properties.filter(
          ({ name }) => !isElementQuantityRepeatingChildName(name),
        );

    if (element.category_id === ElementCategoryID.Labour) {
      return quantities.filter((quantity) => quantity.name === 'time');
    }
    if (element.category_id === ElementCategoryID.Energy) {
      return quantities.filter((quantity) => quantity.name === 'energy');
    }
    return quantities.filter((quantity) => !['time'].includes(quantity.name));
  }, [element, quantityProps]);

  if ([...iconProperties, ...otherProperties].length === 0) {
    return null;
  }

  return (
    <Box pt={0} pb={2}>
      <Divider sx={dividerStyles} />
      <ElementProperties
        element={element}
        properties={iconProperties}
        display="flex"
        flexWrap="wrap"
        pt={1}
        pl={2}
        pr={2}
      />
      <ElementProperties element={element} properties={otherProperties} />
    </Box>
  );
};

export const useAlwaysVisibleQuantities = (element: IElement) => {
  const quantityProps = useQuantityProperties(element);
  return useMemo(
    () =>
      quantityProps
        .filter(
          ({ name }) =>
            element.category_id !== ElementCategoryID.Labour &&
            iconQuantityNames.includes(name as IconQuantityName),
        )
        .sort((a, b) => sortInOrderOf(iconQuantityNames, a, b, 'name')),
    [element.category_id, quantityProps],
  );
};

export default ElementQuantities;
