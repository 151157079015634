import { OneOfChildFactoryElements } from './factory-element.interface';
import {
  ElementPropertyName,
  IElementSelectProperty,
  IElementSwitchProperty,
  IFactoryProperty,
} from './element_property.interface';
import {
  ElementQuantityExpressionName,
  FactoryQuantityRecord,
  IElementQuantityProperty,
  IFactoryQuantityProperty,
} from './element_quantities.interface';
import { IElement, IProductElement, Project } from './project.interface';
import {
  ArrayOrSingle,
  Replace,
  RequireProperties,
} from './type_helpers.interface';
import { Recipe } from './recipe.interface';
import { Results } from './unit.interface';
import { ElementPath } from '../helpers/recursive_element_helpers';
import { ExpressionVariables } from '../helpers/expression_variables_helpers';
import { ProductID } from './product.interface';
import { Orientation } from './orientation.interface';
import { IConditionalNode } from '../helpers/conditional-nodes.helpers';
/**
 * Unique ids for element categories.
 * Note: new ids might be added, NEVER CHANGED!
 */
export enum ElementCategoryID {
  /**
   * Empty, not selected
   */
  None = '',

  // Product categories
  Concrete = '22',
  Wood = '46',
  Insulation = '47',
  Metal = '48',
  Gypsum = '49',
  Ceramics = '50',
  WindowsDoors = '53',
  Labour = '60',
  Energy = '61',
  AirHandlingUnit = '62',
  Heating = '63',
  OtherProduct = 'other-product',

  // System categories
  Column = '13',
  Beam = '12',
  Wall = '51',
  Slab = '52',
  Maintenance = '64',
  Other = 'other',

  // Main categories
  ROOF = '54',
  FACADES = '55',
  FOUNDATION = '56',
  FLOORS = '57',
  INTERNAL_WALLS = '58',
  INSTALLATIONS = '59',
  MAIN_OTHER = 'main-other',
}

/**
 * Main categories in order of appearance in the UI
 */
export const mainCategoryIds = [
  ElementCategoryID.FACADES,
  ElementCategoryID.ROOF,
  ElementCategoryID.INTERNAL_WALLS,
  ElementCategoryID.FLOORS,
  ElementCategoryID.FOUNDATION,
  ElementCategoryID.INSTALLATIONS,
  ElementCategoryID.MAIN_OTHER,
] as const;

export type MainCategoryId = (typeof mainCategoryIds)[number];
export type IMainCategoryElement = Replace<
  IElement,
  { category_id: MainCategoryId }
>;

export const systemCategoryIds = [
  ElementCategoryID.Column,
  ElementCategoryID.Beam,
  ElementCategoryID.Wall,
  ElementCategoryID.Slab,
  ElementCategoryID.Other,
] as const;

export type SystemCategoryId = (typeof systemCategoryIds)[number];

export const productCategoryIds = [
  ElementCategoryID.Concrete,
  ElementCategoryID.Wood,
  ElementCategoryID.Insulation,
  ElementCategoryID.Metal,
  ElementCategoryID.Gypsum,
  ElementCategoryID.Ceramics,
  ElementCategoryID.WindowsDoors,
  ElementCategoryID.Labour,
  ElementCategoryID.OtherProduct,
  ElementCategoryID.Energy,
] as const;

export type ProductCategoryId = (typeof productCategoryIds)[number];

export const serviceCategoryIds = [
  ElementCategoryID.Heating,
  ElementCategoryID.Maintenance,
  ElementCategoryID.AirHandlingUnit,
] as const;

export type ServiceCategoryId = (typeof serviceCategoryIds)[number];

export type IElementCategoryQuantityPropertiesFn = (
  element?: IElement,
) => FactoryQuantityRecord | IFactoryQuantityProperty[];

export type IElementCategoryElementPropertiesFn = (
  element: IElement,
) => IConditionalNode<IFactoryProperty>[];

export type IElementCategoryElementsFn = (
  element: IElement,
) => ArrayOrSingle<OneOfChildFactoryElements | ProductID>;

export type IElementCategoryPropertySelectionByProductIdFn = (
  productId: ProductID,
) => {
  [key: string]:
    | IElementSelectProperty['count']
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    | IElementSwitchProperty['count'];
};

export interface PropertiesOptions {
  [key: string]: {
    property: IFactoryProperty;
    propertyName: string;
    propertyType?: string;
  };
}

export interface IElementCategoryMapResultsContext {
  path: ElementPath;
  project: Project;
  productElement: IProductElement;
  categoryElement: IElement;
}

export interface IElementCategory {
  id: ElementCategoryID;
  parent_id?: ElementCategoryID;
  name: string;

  /**
   * Optional list of properties to add to elements using this category
   */
  properties?: IConditionalNode<IFactoryProperty>[];

  /**
   * Pass true to hide category from the list
   */
  disabled?: boolean;

  /**
   * (Optional) Which quantity should be selected by default.
   */
  defaultSelectedQuantity?: ElementQuantityExpressionName;

  /**
   * Display color for the advanced product that belongs to this category
   */
  color?: string;

  /**
   * Optional orientation for the category to help to map which dimension is most relevant for the category
   */
  orientation?: Orientation;

  /**
   * Optional list of mutual properties that should not be added to elements using this category
   */
  excludeMutualProperties?: ElementPropertyName[];

  /**
   * Optional list of product IDs that are available for this category
   */
  availableProductIds?: string[];

  /**
   * Make the name of the category element in cursive in element list
   */
  cursiveName?: boolean;

  /**
   * Optional function to make category control chilren of the element
   */
  getChildElements?: IElementCategoryElementsFn;

  /**
   * Optional function to make category control element properties.
   * Will override properties property
   */
  getElementProperties?: IElementCategoryElementPropertiesFn;

  /**
   * Optional function to make category control quantity properties
   */
  getQuantityProperties?: IElementCategoryQuantityPropertiesFn;

  /**
   * Tell werther the recipe should be listed in the category or not
   * @param recipe
   * @param element
   */
  recipeFilter?: (recipe: Recipe, element: IElement) => boolean;

  /**
   * Optional function to modify the expression variables AFTER resolving the element properties of an element
   */
  mapExpressionVariablesAfter?: (
    variables: ExpressionVariables,
  ) => ExpressionVariables;

  /**
   * Optional function to modify the expression variables BEFORE resolving the element properties of an element
   */
  mapExpressionVariablesBefore?: (
    variables: ExpressionVariables,
  ) => ExpressionVariables;

  /**
   * Optional function to modify the results calculation
   */
  mapResults?: (
    results: Results,
    context: IElementCategoryMapResultsContext,
  ) => Results;

  /**
   * Get the selection required to get a certain product from the product tree.
   */
  getElementPropertySelectionByProductId?: IElementCategoryPropertySelectionByProductIdFn;

  /**
   * Get a dynamic label for a quantity property.
   * Used for Energy to get "Annual energy" instead of "Energy"
   * If undefined is returned, the default label will be used
   */
  getQuantityPropertyLabel?: (
    property: IElementQuantityProperty,
    element: IElement,
  ) => string | undefined;
}

export type IElementCategoryWithCalculatedProperties = RequireProperties<
  IElementCategory,
  'getElementProperties'
>;
export type IElementCategoryWithQuantityProperties = RequireProperties<
  IElementCategory,
  'getQuantityProperties'
>;

export type IElementCategoryWithAutoElements = RequireProperties<
  IElementCategory,
  'getChildElements'
>;

export type ElementCategoryConversionFactorRecord = Partial<
  Record<ElementCategoryID, Results>
>;

export type MainElementCategoryConversionFactorRecord = Partial<
  Record<ElementCategoryID, ElementCategoryConversionFactorRecord>
>;
