import { escapeRegExp, isObject } from 'lodash';
import { roundToDecimals } from './math_helpers';
import { isDefined } from './array_helpers';

/**
 * Take a value and return it as a percentage string
 * @param value
 * @param decimals
 * @returns
 */
export const toPercent = (value: number, decimals = 0): string => {
  return `${roundToDecimals(value * 100, decimals)}%`;
};

/**
 * Add suffix to string if not already present.
 * @param str
 * @param suffix
 * @returns
 */
export const addSuffix = (str: string | number, suffix: string): string =>
  typeof str === 'string' && str.endsWith(suffix) ? str : str + suffix;

const lengthUnits = [
  'px',
  'em',
  'rem',
  '%',
  'vh',
  'vw',
  'vmin',
  'vmax',
  'fr',
] as const;
type LengthUnit = (typeof lengthUnits)[number];

/**
 * Filter out special characters and spaces and replace åäö with a and o and turn string to lowercase.
 * Note: Only to use to compare names, not to display to user
 * @param name
 * @returns
 */
export const nameMatchTrim = (name = ''): string => {
  return name
    .toLowerCase()
    .trim()
    .replaceAll(/\s+/gi, '')
    .replaceAll(/[åä]/gi, 'a')
    .replaceAll(/ö/gi, 'o')
    .replaceAll(/[^a-z0-9]/gi, '');
};

/**
 * Simplest possible search filter. Returns true if search is empty or item contains search parts.
 * Mutiple search parts are separated by space.
 * @param item
 * @param search
 * @returns
 */
export const searchFilter = (item: string, search?: string): boolean => {
  search = nameMatchTrim(search);
  if (!search) {
    return true;
  }
  return search.split(' ').every((part) => nameMatchTrim(item).includes(part));
};

/**
 * Check if names are matching ignoring case, spaces and special characters
 * @param name1
 * @param name2
 * @returns
 */
export const isNameMatch = (name1?: string, name2?: string): boolean => {
  return nameMatchTrim(name1) === nameMatchTrim(name2);
};

export const addUnit = (
  value: string | number = 0,
  defaultUnit: LengthUnit = 'px',
): string => {
  if (
    typeof value === 'string' &&
    lengthUnits.some((unit) => value.endsWith(unit))
  ) {
    return value;
  }
  return `${value}${defaultUnit}`;
};

export const indentString = (
  str: string,
  depth: number,
  indentationStr = '    ',
): string => indentationStr.repeat(depth) + str;

export const isUppercase = (str: string): boolean => {
  return str === str.toUpperCase();
};

/**
 * Make a sentence from a list of names
 * @example makeSentence('a', 'b', 'c') => 'a, b and c'
 * @param names
 * @returns
 */
export const makeSentence = (...names: (string | undefined)[]): string => {
  const definedNames: string[] = names.filter((name): name is string => !!name);
  const firstName = definedNames[0];

  if (definedNames.length === 0) {
    return '';
  }

  if (firstName && definedNames.length === 1) {
    return firstName;
  }

  const last = definedNames.pop() as string;
  const sentence = `${definedNames.join(', ')} and ${last}`;

  return sentence;
};

export const countOccurrences = (
  text: string,
  search: string,
  ignoreCase = false,
): number => {
  if (!search) {
    return 0;
  }
  const regExp = new RegExp(
    escapeRegExp(search),
    'g' + (ignoreCase ? 'i' : ''),
  );

  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  return (text.match(regExp) || []).length;
};

export const inputStringToNumber = (input?: string | number): number => {
  if (!input) {
    return 0;
  }
  if (typeof input === 'number') {
    return input;
  }

  // Replace comma with dot if only one comma and no dot to handle strings like '1,234,567.89'
  if (
    countOccurrences(input, ',') === 1 &&
    countOccurrences(input, '.') === 0
  ) {
    input = input.replace(',', '.');
  }

  return Number(input.replace(/[^0-9.-]+/g, ''));
};

export const convertName = (name: string): string => {
  return name.toLowerCase().replaceAll(' ', '_');
};

interface IFindFreeNameOptions {
  /**
   * Delimiter to use between name and number
   * @default ' '
   */
  delimiter?: string;

  /**
   * Start number to use if no numbers are found
   * @default 2
   */
  startIndex?: number;

  /**
   * If true, the index will not be incremented if that exact index does not exist.
   * Else the last available index + 1 will be used as suffix
   * @default true
   */
  keepIndexIfAvailable?: boolean;
}

/**
 * Naming: if proposed name is exist, return new name
 * new name = proposed name + version number increased by one.
 * (version number = max last number in the same existing name).
 * @param names
 * @param name
 * @param options
 */
export const findFreeName = <T = any>(
  namesList:
    | (string | undefined)[]
    | (T & { name: string | undefined }[])
    | undefined,
  name: string,
  options: IFindFreeNameOptions = {},
): string => {
  const {
    delimiter = ' ',
    startIndex = 2,
    keepIndexIfAvailable = true,
  } = options;
  name = name.trim();
  const names = (namesList ?? [])
    .map((item) => (isObject(item) && 'name' in item ? item.name : item))
    .filter(isDefined)
    .map((item) => item.toLowerCase().trim());

  if (!names.length) {
    return name;
  }

  const lastNumRegEx = /\d+$/i;
  const lastNumPosition: number = name.search(lastNumRegEx);
  const word =
    lastNumPosition !== -1 ? name.slice(0, lastNumPosition).trim() : name;
  const currentIndex =
    lastNumPosition !== -1 ? +name.slice(lastNumPosition).trim() : 0;

  const sameNamesRegEx = new RegExp(`^${word}(${delimiter})* *[0-9]*$`, 'i');

  const matchingNames = names.filter(
    (item) => item?.search(sameNamesRegEx) !== -1,
  );

  // If no names matched, just return the original name
  if (!matchingNames.length) {
    return name;
  }

  // Get all trailing numbers in belong to the same name
  const matchingNumbers = matchingNames
    .map((item) => {
      const match = lastNumRegEx.exec(item) || [0];
      return +match[0] || 0;
    })
    .filter(isFinite);

  // If current index doesn't exist, we should return the original name if keepIndexIfAvailable is true
  if (keepIndexIfAvailable && !matchingNumbers.includes(currentIndex)) {
    return name;
  }

  // The largest number found (or -1 if none found)
  const largestNumber = matchingNumbers.length
    ? Math.max(...matchingNumbers)
    : -1;

  // If there are any matching numbers, add the next number to the name, otherwise return the original name
  return matchingNumbers.length
    ? addSuffix(word, delimiter) + Math.max(largestNumber + 1, startIndex)
    : name;
};

export const replaceCharAt = (
  str: string,
  index: number,
  replacement: string,
): string => {
  if (index < 0 || index >= str.length) {
    throw new Error('Index out of bounds');
  }
  return (
    str.substring(0, index) +
    replacement +
    str.substring(index + replacement.length)
  );
};
