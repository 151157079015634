import React, { useCallback, useEffect } from 'react';
import { OneOfPropertyElements } from '../../../../../../shared/models/project.interface';
import ExpressionInput from '../../../ExpressionInput';
import {
  isQuantityUnit,
  selectableUnitHarmonizer,
} from '../../../../../../shared/helpers/unit_helpers';
import {
  useElementExpressionVariablesById,
  useSolveExpressionError,
} from '../../../../hooks/useElementExpressionVariables';
import { IElementExpressionProperty } from '../../../../../../shared/models/element_property.interface';
import { useUIState } from '../../../../store/ui';
import {
  useElementPropertiesUtils,
  useCircularError,
} from '../../../../hooks/element-properties.hook';
import {
  getCount,
  isElementPropertySourceCategory,
  isElementPropertySourceNone,
  isFullyEditableProperty,
} from '../../../../../../shared/helpers/element_property_helpers';
import { isElementQuantityProperty } from '../../../../../../shared/helpers/element_quantity_helpers';
import { useUpdateQuantity } from '../../../../hooks/quantity-properties.hook';
import { omit } from '../../../../../../shared/helpers/object_helpers';
import { IExpressionInputPanelOutput } from '../../../ExpressionInputPanel';
import { useIsReadonlyElement } from '../../../../hooks/user.hook';
import { isElement } from '../../../../../../shared/helpers/recursive_element_helpers';

interface ElementPropertyExpressionProps {
  property: IElementExpressionProperty;
  element: OneOfPropertyElements;
  onErrorMessageChange?: (message: string | undefined) => void;
}

const ElementPropertyExpression: React.FC<ElementPropertyExpressionProps> = ({
  property,
  element,
  onErrorMessageChange,
}) => {
  const { id, name, count, fallbackCount, min, max, category_id } = property;

  const { setSelectedElementPropertyId } = useUIState(
    'setSelectedElementPropertyId',
  );
  const { updateProperty } = useElementPropertiesUtils(element);

  const updateQuantity = useUpdateQuantity();

  const readonly = useIsReadonlyElement(element);
  const expression = getCount(property).expression;
  const isEditableProperty = isFullyEditableProperty(property);
  // Get all variables that might be used in the expression (exluding self)
  const variables = useElementExpressionVariablesById(
    element.id,
    name,
    category_id,
  );

  const circularError = useCircularError(element, property, expression);
  const solveError = useSolveExpressionError(expression, {
    variables,
    min,
    max,
  })?.message;

  const expressionError = circularError ?? solveError;

  useEffect(() => {
    onErrorMessageChange?.(expressionError);
  }, [expressionError, onErrorMessageChange]);

  const update = useCallback(
    async (modified: Partial<IElementExpressionProperty>): Promise<void> => {
      if (isElement(element) && isElementQuantityProperty(property)) {
        await updateQuantity(element, [{ ...modified, name: property.name }]);
      } else {
        await updateProperty({ ...property, ...modified });
      }
      setSelectedElementPropertyId(undefined);
    },
    [
      property,
      updateQuantity,
      element,
      updateProperty,
      setSelectedElementPropertyId,
    ],
  );

  const onExpressionInputChange = useCallback(
    async ({ expressionValue: count, unit }: IExpressionInputPanelOutput) => {
      if (!count && !fallbackCount) {
        throw new Error(
          'Cant set property count to undefined when there is no fallbackCount',
        );
      }
      const changes = omit(
        { count, unit },
        // Omit changes of unit to undefined or invalid units
        !isQuantityUnit(unit) ? 'unit' : undefined,
      );
      await update(changes);
    },
    [fallbackCount, update],
  );

  // Category properties might have other units than the ones selectable in the unit dropdown
  const unit = isElementPropertySourceCategory(property)
    ? property.unit
    : selectableUnitHarmonizer(property.unit);

  return (
    <ExpressionInput
      disabled={readonly || property.readonly}
      id={id}
      min={min}
      max={max}
      errorMessage={expressionError}
      expressionValue={count}
      fallbackExpressionValue={fallbackCount}
      disableExpressionInputPanelUnits={!isEditableProperty}
      propertyId={property.id}
      variables={property.resolveBeforeProjectVariables ? undefined : variables}
      unit={isElementPropertySourceNone(property) ? 'none' : unit}
      onSave={onExpressionInputChange}
    />
  );
};

export default ElementPropertyExpression;
