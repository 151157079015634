/**
 * Table of degree hours per year. Record<limitTemp, Record<normalYearTemp, degreeHours>>
 * Source: https://www.lth.se/fileadmin/ees/Publikationer/2006/5091.pdf (p.26 / pdf page 34)
 */
export const degreeHourTable = Object.freeze({
  25: {
    [-2]: 238900,
    [-1]: 229400,
    0: 220300,
    1: 211200,
    2: 202000,
    3: 192900,
    4: 184000,
    5: 174900,
    6: 165600,
    7: 156800,
    8: 147300,
  },
  24: {
    [-2]: 230100,
    [-1]: 220600,
    0: 211600,
    1: 202500,
    2: 192300,
    3: 184200,
    4: 175300,
    5: 166300,
    6: 157000,
    7: 148300,
    8: 138700,
  },
  23: {
    [-2]: 221400,
    [-1]: 211900,
    8: 130300,
    0: 202900,
    1: 193800,
    2: 184600,
    3: 175600,
    4: 166700,
    5: 157700,
    6: 148500,
    7: 139800,
  },
  22: {
    [-2]: 212750,
    [-1]: 203200,
    0: 194300,
    1: 185200,
    2: 176000,
    3: 167000,
    4: 158200,
    5: 149200,
    6: 140000,
    7: 131300,
    8: 121900,
  },
  21: {
    [-2]: 204100,
    [-1]: 194600,
    0: 185700,
    1: 176600,
    2: 167500,
    3: 158600,
    4: 149700,
    5: 140800,
    6: 131600,
    7: 123000,
    8: 113600,
  },
  20: {
    [-2]: 195500,
    [-1]: 186100,
    0: 177200,
    1: 168100,
    2: 159000,
    3: 150100,
    4: 141300,
    5: 132400,
    6: 123300,
    7: 114800,
    8: 105500,
  },
  19: {
    [-2]: 187000,
    [-1]: 177600,
    0: 168700,
    1: 159700,
    2: 150600,
    3: 141800,
    4: 133000,
    5: 124200,
    6: 115200,
    7: 106700,
    8: 97600,
  },
  18: {
    [-2]: 178500,
    [-1]: 169200,
    0: 160300,
    1: 151300,
    2: 142300,
    3: 133600,
    4: 124900,
    5: 116100,
    6: 107200,
    7: 98900,
    8: 90000,
  },
  17: {
    [-2]: 170100,
    [-1]: 160800,
    0: 152000,
    1: 143100,
    2: 134100,
    3: 125400,
    4: 116800,
    5: 108200,
    6: 99500,
    7: 91400,
    8: 82700,
  },
  16: {
    [-2]: 161700,
    [-1]: 152500,
    0: 143800,
    1: 135000,
    2: 126100,
    3: 117500,
    4: 109000,
    5: 100500,
    6: 92000,
    7: 84200,
    8: 75700,
  },
  15: {
    [-2]: 153500,
    [-1]: 144300,
    0: 135700,
    1: 127000,
    2: 118200,
    3: 109700,
    4: 101400,
    5: 93200,
    6: 84900,
    7: 77200,
    8: 69000,
  },
  14: {
    [-2]: 145400,
    [-1]: 136300,
    0: 127700,
    1: 119200,
    2: 110500,
    3: 102300,
    4: 94100,
    5: 86100,
    6: 78000,
    7: 70600,
    8: 62700,
  },
  13: {
    Tg: 13,
    [-2]: 137400,
    [-1]: 128400,
    0: 120000,
    1: 111500,
    2: 103100,
    3: 95000,
    4: 87100,
    5: 79300,
    6: 71500,
    7: 64300,
    8: 56600,
  },
  12: {
    [-2]: 129600,
    [-1]: 120800,
    0: 112400,
    1: 104200,
    2: 96000,
    3: 88000,
    4: 80300,
    5: 72700,
    6: 65200,
    7: 58200,
    8: 50900,
  },
  11: {
    [-2]: 121900,
    [-1]: 113300,
    0: 105100,
    1: 97000,
    2: 89000,
    3: 81400,
    4: 73900,
    5: 66500,
    6: 59300,
    7: 52500,
    8: 45400,
  },
  10: {
    [-2]: 114500,
    [-1]: 106000,
    0: 98000,
    1: 90100,
    2: 82400,
    3: 74900,
    4: 67700,
    5: 60600,
    6: 53600,
    7: 47100,
    8: 40300,
  },
  9: {
    [-2]: 107200,
    [-1]: 99000,
    0: 91200,
    1: 83500,
    2: 76000,
    3: 68800,
    4: 61800,
    5: 54900,
    6: 48200,
    7: 42000,
    8: 35500,
  },
  8: {
    [-2]: 100200,
    [-1]: 92200,
    0: 84600,
    1: 77200,
    2: 69900,
    3: 62900,
    4: 56200,
    5: 49600,
    6: 43200,
    7: 37100,
    8: 31100,
  },
  7: {
    [-2]: 93500,
    [-1]: 85800,
    0: 78300,
    1: 71100,
    2: 64100,
    3: 57400,
    4: 50800,
    5: 44500,
    6: 38400,
    7: 32600,
    8: 26900,
  },
  6: {
    [-2]: 87000,
    [-1]: 79500,
    0: 72300,
    1: 65300,
    2: 58500,
    3: 52000,
    4: 45800,
    5: 39700,
    6: 33900,
    7: 28400,
    8: 23000,
  },
  5: {
    [-2]: 80750,
    [-1]: 73500,
    0: 66500,
    1: 59700,
    2: 53200,
    3: 47000,
    4: 41000,
    5: 35200,
    6: 29700,
    7: 24500,
    8: 19500,
  },
});
