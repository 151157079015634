import { getElementName } from '../../../shared/helpers/element_helpers';
import {
  flattenElements,
  isProductListCategoryGroup,
  isProductListGroup,
  isProductListItem,
  isElement,
  isProductElement,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  IProductListGroup,
  OneOfSelectableProductListElements,
  ProductID,
  ProductRecord,
} from '../../../shared/models/product.interface';
import {
  OneOfProductListChildren,
  IElement,
} from '../../../shared/models/project.interface';
import { getLast, isDefined } from '../../../shared/helpers/array_helpers';
import { isCustomProductId } from '../../../shared/helpers/product_helpers';

export const PRODUCT_LIST_PATH_MAX_LENGTH = 60;
export const PRODUCT_ID_SEPARATOR = '__';

export const getProductListEPDNames = (
  element: IProductListGroup,
  productLookup: ProductRecord,
) => {
  const name = getElementName(element, productLookup);
  if (!element.generic_product_id) {
    return [name];
  }
  const genericName = productLookup[element.generic_product_id]?.name;
  return [name, genericName];
};

export const getProductListElementName = (
  element: OneOfProductListChildren,
  options: {
    disableTruncation?: boolean;
    productLookup?: ProductRecord;
  } = {},
) => {
  if (isProductListCategoryGroup(element) || isProductListGroup(element)) {
    return getElementName(element, options.productLookup);
  }

  return getFormatedPath(
    element.path.filter(isElement).map((e) => getElementName(e as IElement)),
    options.disableTruncation,
  );
};

const getFormatedPath = (
  names: string[] = [],
  disableTruncation = false,
  separator = ' / ',
): string => {
  const first = names[0];
  const last = names[names.length - 1];
  const middle = ' / ... / ';

  const path = names.join(separator);

  if (
    !disableTruncation &&
    path.length > PRODUCT_LIST_PATH_MAX_LENGTH &&
    names.length > 2
  ) {
    return first + middle + last;
  }
  return path;
};

export const getProductElements = (
  element: OneOfSelectableProductListElements,
) => {
  const productId = element.product_id ?? element.id;

  if (isProductListGroup(element)) {
    const grandParents = element.elements
      .flatMap((e) => getLast(e.path))
      .filter(isDefined);

    const productElements = flattenElements(...grandParents)
      .filter(isProductElement)
      .filter((e) => e.product_id === productId);

    return productElements;
  }

  if (isProductListItem(element)) {
    const grandParent = getLast(element.path);

    const productElements = grandParent?.elements
      .filter(isProductElement)
      .filter((e) => e.product_id === productId);

    return productElements ?? [];
  }
  return [];
};

/**
 * Get the product id for the product list element.
 * If the product is an epd / custom product, the id will be prefixed with the epd id.
 * Else the id will be the generic product id.
 *
 * Examples:
 *
 * id = 'custom_123', gid = 'boverket_456' -> 'custom_123__boverket_456'
 *
 * id = 'abc_123', gid = 'boverket_456' -> 'boverket_456'
 *
 * id = 'boverket_123', gid = undefined -> 'boverket_123'
 */
export const getProductListElementId = (
  id: ProductID,
  gid: ProductID | undefined,
) => {
  const epdId = isCustomProductId(id) ? id : undefined;
  const genericId = gid ?? id;

  return epdId && epdId !== genericId
    ? epdId + PRODUCT_ID_SEPARATOR + genericId
    : genericId;
};
